import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";

import { clearJwt, getJwt, setJwt } from "./jwt";

import { GLOBAL_CONSTANTS, LOGIN, WHOM_AM_I_URL, maintenancePath } from "../constants";
import { IErrorResponse, RequestMethod, SentryLevels } from "../models";
import { getTracingId } from "../utils/getUniqueValue";
const BASE_URL = GLOBAL_CONSTANTS.BACKEND_API;

const skipCodes = [404, 422];
const infoCodes = [401, 403];
const maintenanceCodes = [503];

const extractJson = (res: Response) => {
	return res
		.text()
		.then(text => {
			try {
				return JSON.parse(text);
			} catch {
				return text;
			}
		})
		.catch(() => new Promise(resolve => resolve(null)));
};

const getData = (res: Response, emptyResponse: boolean) => {
	return emptyResponse ? new Promise(resolve => resolve(null)) : extractJson(res);
};

const parseStatus = <T>(res: Response, emptyResponse: boolean): Promise<T> => {
	return new Promise((resolve, reject) => {
		if (res.ok) {
			const { headers } = res;
			const data: Promise<T | null> = getData(res, emptyResponse);
			// Authorization token is set after successful login in authSlice
			const shouldSetAuthHeader = !res.url.includes(LOGIN);
			if (headers && shouldSetAuthHeader) {
				const headerEntries = headers.entries();
				let authHeader: string | null = null;
				for (const pair of headerEntries) {
					if (pair[0] === "authorization") {
						authHeader = pair[1] as string;
					}
				}
				if (typeof authHeader === "string" && authHeader.length) {
					const authHeaderData = authHeader.split(" ");
					if (authHeaderData[0] === "Bearer") {
						setJwt(authHeaderData[1], "", authHeaderData[2]);
					}
				}
			}
			data.then(r => resolve(r as T));
		} else {
			if (res.status === 401) {
				clearJwt();
				if (!res.url.includes(WHOM_AM_I_URL)) {
					window.location.pathname = "/signIn";
				}
				reject({ code: res.status, response: { message: "Unauthorized" } });
			}

			const data = getData(res, emptyResponse);
			data.then(response => reject({ code: res.status, response }));
		}
	});
};

export const request = async function <T>(
	url: string,
	method: RequestMethod = "GET",
	body?: Record<string, unknown>,
	options?: RequestInit,
	skipToken = false,
	emptyResponse = false,
	additionalHeader?: Record<string, unknown>
): Promise<T | null | undefined> {
	const reqOptions: RequestInit = {
		method,
		headers: requestHeaders(skipToken, additionalHeader),
		body: method !== "GET" ? (body instanceof File ? body : JSON.stringify(body)) : null,
		credentials: "include", // we need this to update cookies on token refresh
		...options
	};

	// If provided url is valid full url, like https://google.com we use it,
	// instead of appending it to BASE_URL
	let useBaseUrl = true;
	try {
		const validUrl = new URL(url);
		if (validUrl) useBaseUrl = false;
	} catch {}
	const finalUrl = `${useBaseUrl ? BASE_URL : ""}${url}`;
	try {
		const res = await fetch(`${finalUrl}${finalUrl.match(/\?/) ? "&" : "?"}_=${new Date().valueOf()}`, reqOptions);
		return await parseStatus(res, emptyResponse);
	} catch (e) {
		const errorObject = e as Error & IErrorResponse;
		errorObject.response.name = errorObject.code;
		const errMessage = errorObject.response?.message || errorObject.message || "Something went wrong...";
		if (errMessage === "Unauthorized") {
			clearJwt();
			window.location.pathname = "/signIn";
		}
		const code = (e as Error & { code: number })!.code;
		if (maintenanceCodes.some(c => c === code)) {
			const path = window.location.pathname;
			if (path.includes(maintenancePath)) return;
			const search = window.location.search;
			const params = new URLSearchParams({
				message: errMessage,
				redirectUrl: path + search
			});
			window.location.href = `${maintenancePath}?${params.toString()}`;
		}

		if (!errorObject && !skipCodes.some(c => c === code)) {
			let level: SentryLevels = "error";
			if (infoCodes.some(c => c === code)) {
				level = "info";
			}
			Sentry.captureException(new Error(errMessage), {
				level,
				extra: {
					message: errMessage,
					status: (e as Error & { code: string })!.code,
					url: finalUrl,
					requestOpts: reqOptions
				},
				tags: { type: "api" }
			});
		}
		throw errorObject.response;
	}
};

export const requestHeaders = (skipToken = false, additionalHeader = {}): Headers => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Accept", "application/json");
	headers.append("tracing-number", getTracingId());

	if (!isEmpty(additionalHeader)) {
		for (const key in additionalHeader) {
			headers.append(`${key}`, `${additionalHeader[key]}`);
		}
	}
	if (!skipToken) {
		const token = getJwt();
		headers.append("Authorization", `Bearer ${token}`);
	}

	return headers;
};
