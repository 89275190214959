import { ChangeEvent, Dispatch } from "react";

import { FilledInputProps, Theme } from "@material-ui/core";

import { ValidateFormAction } from "../../utils/form/form.model";
import { SetImageUrlGenericApiService, SetStateValue, UploadFile } from "../../utils/stateUtils";

// fake functions just to use the types
function chipsBottomAction(): void {}

function defaultValueGetter<ValueType>(): ValueType {
	return {} as ValueType;
}

function onBlur(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
	console.log(e);
}

function onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
	console.log(e);
}

function onClose(value: unknown): void {
	console.log(value);
}

function onKeyPress(e: React.KeyboardEvent<HTMLDivElement> | undefined): void {
	console.log(e);
}

export interface CheckInputValidityOptions {
	markAsDirty?: boolean;
}

export enum CustomInputType {
	Checkbox = "checkbox",
	CheckboxArray = "checkboxArray",
	Chips = "chips",
	Email = "email",
	File = "file",
	Text = "text",
	Select = "select",
	Multiline = "multiline",
	Number = "email",
	Password = "password",
	Phone = "phone",
	Radio = "radio",
	Editor = "editor",
	Switch = "switch",
	AutoComplete = "autoComplete",
	AutoCompleteChips = "autoCompleteChips"
}

export enum CustomInputValidationType {
	IsArray = "isArray", // eslint-disable-line no-unused-vars
	IsInteger = "isInteger", // eslint-disable-line no-unused-vars
	IsNumber = "isNumber", // eslint-disable-line no-unused-vars
	Max = "max", // eslint-disable-line no-unused-vars
	MaxLength = "maxLength", // eslint-disable-line no-unused-vars
	Min = "min", // eslint-disable-line no-unused-vars
	MinLength = "minLength", // eslint-disable-line no-unused-vars
	Required = "required" // eslint-disable-line no-unused-vars
}

export interface CustomInputModelValidations {
	[CustomInputValidationType.IsArray]?: boolean;
	[CustomInputValidationType.IsInteger]?: boolean;
	[CustomInputValidationType.IsNumber]?: boolean;
	[CustomInputValidationType.Max]?: number;
	[CustomInputValidationType.MaxLength]?: number;
	[CustomInputValidationType.Min]?: number;
	[CustomInputValidationType.MinLength]?: number;
	[CustomInputValidationType.Required]?: boolean;
}

export interface CustomInputInnerProps {
	chipsBottomAction?: typeof chipsBottomAction;
	fileUploaderDescription?: React.ReactFragment;
	uploaderWidth?: number;
	onBlur?: typeof onBlur;
	onChange?: typeof onChange;
	onClose?: typeof onClose;
	onKeyPress?: typeof onKeyPress;

	[propName: string]: unknown;
}

export interface CustomInputSelectOptionsItem {
	subtitle?: string;
	text: string;
	value: unknown;
	disabled?: boolean;
}

export interface CustomInputModel<ValueType> {
	chipsBottomLabel?: string;
	defaultValue?: ValueType;
	emptySelectOption?: CustomInputSelectOptionsItem;
	error: string | null;
	formStatePath?: string;
	hasClearButton?: boolean;
	imageExtensionName?: string;
	imageFileType?: string;
	imageUrl?: string;
	isImageFile?: boolean;
	isLoading?: boolean;
	label?: string;
	pristine: boolean;
	placeholder?: string;
	radioIsLinear?: boolean;
	readonly?: boolean;
	selectOptions?: CustomInputSelectOptionsItem[];
	statePath: string;
	type: CustomInputType;
	uploaderIconName?: string;
	uploaderStateLoaderKey?: string;
	useDefaultValueGetterName?: string;
	validations?: CustomInputModelValidations;
	value?: ValueType;
	prefilledValues?: [];
}

export interface CustomInputProps {
	inputProps?: CustomInputInnerProps;
	InputProps?: Partial<FilledInputProps>;
	options: {
		_emit: unknown;
		defaultValueGetters?: DefaultValueGettersObject;
		genericApiService?: SetImageUrlGenericApiService;
		plugins?: string[];
		inputData: CustomInputModel<unknown>;
		setStateValue: SetStateValue;
		uploadFile?: UploadFile;
		validateForm?: ValidateFormAction;
	};
	containerClassName?: {};
	theme?: Theme;

	[propName: string]: unknown;
}

export type DefaultValueGetter = typeof defaultValueGetter;
export type DefaultValueGettersObject = { [name: string]: DefaultValueGetter };

export interface ValidateInputOptions {
	dispatch: Dispatch<unknown>;
	inputData: CustomInputModel<unknown>;
	markAsDirty?: boolean;
	setStateValue: SetStateValue;
	validateForm?: ValidateFormAction;
}
