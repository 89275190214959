import { Box } from "@material-ui/core";

import styled from "styled-components";

export const RecommendedWrapper = styled(Box)`
	min-height: 400px;
	.tabs {
		button {
			background: ${({ theme }) => theme.palette.colors.primary[2300]};
			color: ${({ theme }) => theme.palette.colors.primary[1900]};
			&.deactivated {
				background: ${({ theme }) => theme.palette.background.default};
				color: ${({ theme }) => theme.palette.colors.basic[400]};
			}
		}
		.MuiTabs-indicator {
			display: none;
		}
	}
`;

export const RecommendedLessonsContainer = styled(Box)`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	min-height: 400px;
	max-height: 600px;
	overflow: auto;

	${props => props.theme.breakpoints.down("sm")} {
		max-height: 400px;
	}
`;

export const RecommendedLessonWrapper = styled(Box)`
	display: flex;
	flex-flow: column;

	& > div {
		margin: 5px;
	}
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}

	.lessonTitleBlock {
		margin-top: 10px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		p:first-child {
			margin-right: 10px;
		}
		p:last-child {
			${props => props.theme.breakpoints.down("md")} {
				margin-top: 5px;
			}
		}
	}
`;

export const RecommendedSubjectLessons = styled(Box)`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	min-height: 400px;
	max-height: 600px;
	overflow: auto;

	.title {
		color: ${({ theme }) => theme.palette.colors.basic[400]};
	}
	.lesson {
		display: flex;
		margin-top: 12px;

		.lessonTags {
			margin-left: 10px;
			display: flex;
			p {
				margin-right: 10px;
			}
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		max-height: 400px;
	}
`;
