import { ColorsType } from "../theme/default";

const yellow: ColorsType = {
	100: "hsl(41,100%,90%)",
	200: "hsl(42,93%,83%)",
	300: "hsl(43,98%,63%)",
	400: "hsl(42,100%,49%)",
	500: "hsl(42,99%,40%)",
	600: "hsl(42,88%,23%)",
	700: "hsl(40,41%,25%)"
};

export default yellow;
