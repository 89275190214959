import React from "react";

import { Box, Typography } from "@material-ui/core";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { CartItemWrapper, PlusMinus } from "../styles";

const CartItem = ({ item, addBook, removeBook }) => {
	const { name, quantity, data } = item;
	return (
		<CartItemWrapper>
			<Box flexGrow={1}>
				<Typography>{name}</Typography>
			</Box>
			<Box display={"flex"} alignItems={"center"} mx={2}>
				<PlusMinus size="tiny" onClick={() => removeBook(item)}>
					-
				</PlusMinus>
				<Box display={"flex"} alignItems={"center"} mx={1}>
					<Typography>{quantity}</Typography>
				</Box>
				<PlusMinus size="tiny" onClick={() => addBook(item)}>
					+
				</PlusMinus>
			</Box>
			<Box width={"70px"} textAlign={"end"}>
				<Typography>{formatUSD(data.price * quantity || 0)}</Typography>
			</Box>
		</CartItemWrapper>
	);
};
export default CartItem;
