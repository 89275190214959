import React, { FC } from "react";

import { Box, Card, IconButton, Modal, Typography, useTheme } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import parse from "html-react-parser";

import { generateClassName, useSimpleModalStyles } from "./styles";

type SimpleModalProps = {
	[propName: string]: unknown;
	title?: string;
	description?: string;
	attachmentContent?: React.ReactNode;
	open: boolean;
	onClose?: () => void;
	footer?: React.ReactElement | null;
	text?: string;
	headerClass?: string;
	icon?: JSX.Element;
	IconSvg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	extraContent?: JSX.Element;
	modalWidth?: number;
};

export const SimpleModal: FC<SimpleModalProps> = function ({
	title,
	description,
	attachmentContent = null,
	open,
	onClose = () => {},
	footer = <CloseIcon color="disabled" />,
	text,
	IconSvg,
	extraContent,
	headerClass,
	icon,
	modalWidth,
	...props
}) {
	const classes = useSimpleModalStyles();
	const theme = useTheme();
	const DEFAULT_WIDTH = 550;

	const renderIconSvg = () => {
		return (
			IconSvg && (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<IconSvg />
				</Box>
			)
		);
	};

	const renderText = () => {
		return (
			text && (
				<Box className={classes.subTitleContainer}>
					<Typography className={classes.subTitle}>{parse(text)}</Typography>
				</Box>
			)
		);
	};

	const renderExtraContent = () => {
		return extraContent && extraContent;
	};

	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProvider theme={theme}>
				<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} {...props}>
					<Box width={modalWidth || DEFAULT_WIDTH}>
						<Card className={classes.card}>
							<Box
								className={classnames(classes.modalHeader, {
									[headerClass as string]: headerClass
								})}
							>
								<Box>
									<Typography className={classes.title}>{title}</Typography>
									{!!description && <Typography className={classes.description}>{description}</Typography>}
								</Box>
								<Box display="flex" alignItems="center" gridGap={16}>
									{attachmentContent}
									<IconButton
										className={classes.closeIcon}
										style={!!attachmentContent ? { marginTop: 0 } : undefined}
										onClick={onClose}
									>
										<CloseIcon color="action" />
									</IconButton>
								</Box>
							</Box>
							{icon && (
								<Box display="flex" justifyContent="center" alignItems="center">
									{icon}
								</Box>
							)}
							{renderIconSvg()}
							{renderText()}
							{renderExtraContent()}
							{footer && <Box className={classes.modalFooter}>{footer}</Box>}
						</Card>
					</Box>
				</Modal>
			</ThemeProvider>
		</StylesProvider>
	);
};
