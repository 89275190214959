import React, { useState } from "react";

import { Box, Card, IconButton, Modal, Tab, Tabs, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { StyledNameChip } from "@remar/shared/dist/components/Table/styles";
import { CourseChapterSection, CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { IStudyGuide } from "store/features/Auth/auth.model";

import {
	RecommendedLessonWrapper,
	RecommendedLessonsContainer,
	RecommendedSubjectLessons,
	RecommendedWrapper
} from "./styles";

import CourseLessonItem from "../../../../Course/CourseLessonItem";

enum TabValues {
	LESSONS = "LESSONS",
	SUBJECTS = "SUBJECTS"
}

const Lessons = ({ studyGuide, onClose }) => {
	const lessons = studyGuide ? studyGuide.lessons || [] : null;
	return (
		<RecommendedLessonsContainer mt={2} gridGap={8}>
			{lessons?.map((lesson, i) => (
				<RecommendedLessonWrapper key={lesson.id}>
					<CourseLessonItem
						number={i + 1}
						sectionLesson={lesson}
						section={{} as CourseChapterSection}
						latestLesson={{} as CourseChapterSectionLesson}
						onRedirection={onClose}
					/>
					<Box className={"lessonTitleBlock"}>
						<Typography>{lesson.lesson.name}</Typography>
						{lesson.tags.map(tag => (
							<StyledNameChip key={tag.id}>{tag.name}</StyledNameChip>
						))}
					</Box>
				</RecommendedLessonWrapper>
			))}
		</RecommendedLessonsContainer>
	);
};
const Subjects = ({ studyGuide }: { studyGuide: IStudyGuide }) => {
	const lessonSubject = studyGuide ? studyGuide.subjects || [] : null;
	if (!lessonSubject) return <div></div>;
	const lessons = lessonSubject.flatMap(({ lessons }) => lessons);
	return (
		<RecommendedSubjectLessons>
			<Typography variant={"caption"} className={"title"}>
				Subject Lessons
			</Typography>
			{lessons.map(lesson => (
				<Box className={"lesson"} key={lesson.id}>
					<Typography>{lesson.name}</Typography>
					<Box className={"lessonTags"}>
						{lesson.tags.map(tag => (
							<StyledNameChip key={tag.id}>{tag.name}</StyledNameChip>
						))}
					</Box>
				</Box>
			))}
		</RecommendedSubjectLessons>
	);
};
const CATRecommendedMaterials = ({ onClose, studyGuide }) => {
	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState<TabValues>(TabValues.LESSONS);

	const handleTabChange = (_, v) => setSelectedTab(v);

	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width={"90vw"}>
				<Card className={classes.card}>
					<Box display="flex" justifyContent="space-between" alignItems={"center"} mb={3}>
						<Typography className={classes.title}>Recommended Material for CAT Retake</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<RecommendedWrapper mt={2}>
						<Tabs
							className={"tabs"}
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							TabIndicatorProps={{
								style: {
									display: "none"
								}
							}}
						>
							{Object.values(TabValues).map(v => (
								<Tab key={v} label={v} value={v} className={`${v !== selectedTab ? "deactivated" : ""}`} />
							))}
						</Tabs>
						{selectedTab === TabValues.LESSONS ? (
							<Lessons studyGuide={studyGuide} onClose={onClose} />
						) : (
							<Subjects studyGuide={studyGuide} />
						)}
					</RecommendedWrapper>
				</Card>
			</Box>
		</Modal>
	);
};

export default CATRecommendedMaterials;
