import defaultColorPalette from "./dark_defaultPalette";

import * as colors from "../../colors";

import { DoubleExtendedPalette } from "../../models/theme.types";

const colorPalette: Partial<DoubleExtendedPalette> = {
	type: "dark",
	...defaultColorPalette,
	primary: {
		text: colors.basic[100],
		contrastText: colors.basic[100],
		dark: colors.yellow[800],
		light: colors.yellow[200],
		main: colors.yellow[400],
		tabs: {
			border: colors.yellow[500],
			contrastText: colors.yellow[800],
			main: colors.yellow[300]
		}
	},
	secondary: {
		text: colors.basic[200],
		contrastText: colors.yellow[400],
		dark: colors.yellow[800],
		light: colors.yellow[200],
		main: colors.yellow[200],
		tabs: {
			contrastText: colors.yellow[800],
			dark: colors.yellow[500],
			main: colors.yellow[200],
			border: colors.yellow[400]
		}
	}
};

export default colorPalette;
