import { ColorsType } from "../theme/default";

const purple: ColorsType = {
	100: "hsl(269,100%,93%)",
	200: "hsl(270,81%,88%)",
	300: "hsl(274,87%,57%)",
	400: "hsl(275,100%,60%)",
	500: "hsl(274,100%,42%)",
	600: "hsl(274,100%,42%)",
	700: "hsl(277,96%,36%)",
	800: "hsl(273,100%,33%)",
	900: "hsl(274,100%,33%)",
	1000: "hsl(278,89%,25%)",
	1100: "hsl(272,94%,20%)",
	1200: "hsl(270,84%,15%)"
};

export default purple;
