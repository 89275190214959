import React from "react";

import { Box, Button, Typography, useTheme } from "@material-ui/core";

import { IExtendedTheme } from "../../../theme/default";
import { EmptyWrapper } from "../styles";

type EmptyStateProps = {
	description: string;
	additionalDesc?: string;
	buttonTitle?: string;
	placeHolder?: React.ReactNode;
	onButtonClick?: () => void;
};
/**
 * A functional React component that renders an empty state UI with customizable elements.
 *
 * @param {Object} props - The properties object
 * @param {string} props.description - Main description text for the empty state screen.
 * @param {string} [props.additionalDesc] - Additional descriptive text that provides more details.
 * @param {string} [props.buttonTitle] - The title for the button, if provided.
 * @param {function} [props.onButtonClick] - Callback function to handle button click events.
 * @param {React.ReactNode} [props.placeHolder] - Optional placeholder element to be displayed.
 *
 * @returns {React.ReactElement} The rendered empty state component.
 *
 * @example
 *
 * <EmptyState
 *	 description="No items found yet"
 *	 additionalDesc="Start adding items to see them here"
 *	 buttonTitle="Add new Item"
 *	 onButtonClick={() => {
 *		 // Logic to handle button click
 *		 console.log("Button clicked");
 *	 }}
 *	 placeHolder={<PlaceholderImage/>}
 * />
 */
const EmptyState = ({ description, additionalDesc, buttonTitle, onButtonClick, placeHolder }: EmptyStateProps) => {
	const theme = useTheme<IExtendedTheme>();

	return (
		<Box bgcolor={theme.palette.background.paper} padding="75px 0">
			<EmptyWrapper>
				{placeHolder}
				<Typography variant="h3">{description}</Typography>
				{!!additionalDesc && <Typography>{additionalDesc}</Typography>}
				{buttonTitle && (
					<Button variant={"contained"} color={"primary"} onClick={onButtonClick}>
						{buttonTitle}
					</Button>
				)}
			</EmptyWrapper>
		</Box>
	);
};

export default EmptyState;
