import React from "react";

import { maintenancePath } from "@remar/shared/dist/constants";
import { BaseLayout as SharedLayout } from "@remar/shared/dist/layouts/BaseLayout";
import { useLocation } from "react-router-dom";
import { RootState, useAppSelector } from "store";
import { getFullState } from "store/features/notifications/notifications.slice";

import SideNav from "./SideNav";
import { TopNav } from "./TopNav";

const BaseLayout: React.FC = ({ children }) => {
	const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
	const location = useLocation();
	const isMaintenance = location.pathname.includes(maintenancePath);

	const { bannerData } = useAppSelector(getFullState);
	return (
		<SharedLayout
			isLoggedIn={isLoggedIn && !isMaintenance}
			topNav={<TopNav />}
			sideBarNav={<SideNav />}
			isTrial={bannerData?.showBanner}
		>
			{children}
		</SharedLayout>
	);
};

export default BaseLayout;
