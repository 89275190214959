import defaultColorPalette from "./light_defaultPalette";

import * as colors from "../../colors";

import { DoubleExtendedPalette } from "../../models/theme.types";

const colorPalette: Partial<DoubleExtendedPalette> = {
	type: "light",
	...defaultColorPalette,
	primary: {
		text: colors.basic[800],
		contrastText: colors.basic[100],
		dark: colors.cyanBlue[800],
		light: colors.cyanBlue[200],
		main: colors.cyanBlue[600],
		tabs: {
			border: colors.cyanBlue[500],
			contrastText: colors.cyanBlue[800],
			main: colors.cyanBlue[300]
		}
	},
	secondary: {
		text: colors.basic[200],
		contrastText: colors.cyanBlue[600],
		dark: colors.cyanBlue[800],
		light: colors.cyanBlue[200],
		main: colors.cyanBlue[200],
		tabs: {
			contrastText: colors.cyanBlue[800],
			dark: colors.cyanBlue[500],
			main: colors.cyanBlue[200],
			border: colors.cyanBlue[600]
		}
	}
};

export default colorPalette;
