import React, { useMemo } from "react";

import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import { ReactComponent as ShopSVG } from "@remar/shared/dist/assets/icons/store.svg";
import LogoIcon from "@remar/shared/dist/components/LogoIcon";
import { SideNav as SharedSideNav, SideNavItem, SideNavItemNested } from "@remar/shared/dist/layouts/BaseLayout";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useAppSelector } from "store";

import { getFullState as getAuthFullState, selectSubscriptions } from "store/features/Auth/authSlice";

import { getFullState } from "store/features/Theme/theme.slice";

import { HomeSvg, JobsSvg, NotesSvg, QuestionSvg, SettingsSvg, TestTinySvg } from "assets/icons";

import { JOBS_LINK, routes } from "core/constants";

const SideNav = () => {
	const { canAccessCourse, canAccessQuiz, canAccessQuestionBank } = useAppSelector(getAuthFullState);
	const subscriptions = useAppSelector(selectSubscriptions);
	const { logoImageUrl, isLoading } = useAppSelector(getFullState);
	const analytics = useAnalyticsEventTracker("User");
	const isTrial = useMemo(() => getCurrentSubscription(subscriptions).type?.isTrial, [subscriptions]);

	const navItemList: (SideNavItem | SideNavItemNested)[] = useMemo(
		() => [
			// {
			// 	id: "dashboard",
			// 	title: "Dashboard",
			// 	route: "/",
			// 	icon: HomeSvg
			// },
			{
				id: routes.course.getElementKey(),
				title: "Courses",
				route: routes.course.getPath(),
				icon: HomeSvg,
				visible: canAccessCourse,
				disabled: false
			},
			{
				id: routes.questionBank.getElementKey(),
				title: "Question Bank",
				route: routes.questionBank.getPath(),
				icon: QuestionSvg,
				visible: canAccessQuestionBank,
				disabled: false
			},
			{
				id: routes.shop.getElementKey(),
				title: "Shop",
				route: routes.shop.getPath(),
				icon: ShopSVG,
				visible: true,
				disabled: false
			},
			{
				id: routes.tests.getElementKey(),
				title: "Tests",
				icon: TestTinySvg,
				route: routes.tests.getPath(),
				visible: canAccessQuiz,
				disabled: false
			},
			{
				id: "file-vault",
				title: "File Vault",
				icon: FolderSvg,
				disabled: false,
				visible: true,
				subItems: [
					{
						id: routes.fileVault.getElementKey(),
						route: routes.fileVault.getPath(),
						title: "Course Resources",
						visible: !isTrial,
						disabled: false
					},
					{
						id: routes.assets.getElementKey(),
						route: routes.assets.getPath(),
						title: "Books and Certificates",
						visible: true,
						disabled: false
					}
				]
			},
			{
				id: "my-notes",
				title: "My Notes",
				route: routes.notes.getPath(),
				icon: NotesSvg,
				visible: true,
				disabled: false
			},
			{
				id: "jobs",
				title: "Jobs",
				route: JOBS_LINK,
				icon: JobsSvg,
				visible: true,
				disabled: false,
				external: true
			},
			// {
			// 	id: routes.questionbank.getElementKey(),
			// 	title: "Question Bank",
			// 	icon: QuestionSvg,
			// }
			// 	subItems: [
			// 		{
			// 			id: routes.questionbank.getElementKey(),
			// 			route: routes.questionbank.getPath(),
			// 			title: "Questions"
			// 		},
			// 		{
			// 			id: "assignment questions",
			// 			route: routes.questionbank.getPath(),
			// 			title: "Assignment Questions"
			// 		}
			// 	]
			// },
			{
				id: routes.settings.getElementKey(),
				title: "Settings",
				icon: SettingsSvg,
				visible: true,
				disabled: false,
				subItems: [
					{
						id: routes.billings.getElementKey(),
						route: routes.myAccount.getPath(),
						title: "My Account",
						visible: true,
						disabled: false
					},
					{
						id: routes.billings.getElementKey(),
						route: routes.billings.getPath(),
						title: "Payments and Billings",
						visible: true,
						disabled: false
					}
				]
			}
		],
		[canAccessCourse, isTrial, canAccessQuestionBank, canAccessQuiz]
	);

	const logo = <LogoIcon isLoading={isLoading} logoImageUrl={logoImageUrl} />;

	return (
		<SharedSideNav
			navItemList={navItemList}
			logo={logo}
			clickSideEffect={s =>
				analytics({
					eventName: "clicked_on_side_menu",
					eventIdentifier: s
				})
			}
			isTrial={isTrial}
			isCustomized={!!logoImageUrl}
		/>
	);
};

export default SideNav;
