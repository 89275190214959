import { Dispatch } from "react";

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearJwt } from "@remar/shared/dist/api/jwt";
import { CustomInputModel, CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import { IBaseState, ICourseCompletion, User, UserInfo } from "@remar/shared/dist/models";
import { ICertificate } from "@remar/shared/dist/models/certificate.model";
import { handleStripePaymentConfirmation } from "@remar/shared/dist/utils/auth";
import { pendingReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { RootState } from "store";

import {
	CancellationOption,
	ICancellationOptions,
	UserGetSubscriptionInfoResponseDto,
	UserUpdateDataDto,
	assetsService,
	usersService
} from "store/services";
import { UserUpdateSubscriptionDto } from "store/services/users/dto/users.updateSubscription.dto";

import {
	getUserData,
	handleStripePayment,
	resetCoupon,
	setPaymentDiscount,
	setSelectedCountry,
	setUser
} from "../Auth/authSlice";
import { emit } from "../notifications/notifications.slice";

export const INSUFFICIENT_FUNDS = "insufficient_funds";

interface MyAccountState extends Omit<IBaseState, "error"> {
	userInfo: UserInfo | null;
	subscriptionInfo: UserGetSubscriptionInfoResponseDto | null;
	errorMessage: string;
	isLoadingSubscription: boolean;
	isLoadingAccountDetails: boolean;
	mainImageKey: CustomInputModel<string>;
	isLoadingSubscriptionUpdate: boolean;
	isDeleteError: boolean;
	accountDeleted: boolean;
	isLoadingCourseCompletion: boolean;
	courseCompletion: ICourseCompletion | null;
	isLoadingCancellationOptions: boolean;
	cancellationOptions: CancellationOption[] | [];
	isCancellingSubscription: boolean;
	isLoadingCertificateLogs: boolean;
	certificateLogs: ICertificate[];
	displayChangeSubscriptionDateModal: boolean;
	displayRecommendedMaterialModal: boolean;
}

const initialState: MyAccountState = {
	isLoading: false,
	userInfo: null,
	subscriptionInfo: null,
	errorMessage: "",
	isLoadingSubscription: false,
	isLoadingSubscriptionUpdate: false,
	isLoadingAccountDetails: false,
	isDeleteError: false,
	accountDeleted: false,
	mainImageKey: {
		imageExtensionName: "png, jpeg, jpg",
		isImageFile: true,
		label: "Upload Photo",
		placeholder: "Upload Photo",
		type: CustomInputType.File,
		uploaderIconName: "cloud-upload",
		uploaderStateLoaderKey: "fileIsUploading",
		error: "",
		pristine: true,
		statePath: "mainImageKey"
	},
	isLoadingCourseCompletion: false,
	courseCompletion: null,
	isLoadingCancellationOptions: false,
	cancellationOptions: [],
	isCancellingSubscription: false,
	isLoadingCertificateLogs: false,
	certificateLogs: [],
	displayChangeSubscriptionDateModal: false,
	displayRecommendedMaterialModal: false
};

export const getUserInfo = createAsyncThunk("myAccount/getUserInfo", async (_, { rejectWithValue, dispatch }) => {
	try {
		const { user } = (await usersService.getAccountInfo()) as {
			user: User;
		};
		dispatch(setUser({ user }));
		dispatch(setSelectedCountry(user.userShippingDetails?.countryId));
		return user;
	} catch {
		return rejectWithValue("Error in getting user info");
	}
});

export const getSubscriptionInfo = createAsyncThunk("myAccount/getSubscriptionInfo", async (_, { rejectWithValue }) => {
	return await usersService.getSubscriptionInfo().catch(() => rejectWithValue("Error in getting subscription info"));
});

export const cancelSubscription = createAsyncThunk(
	"myAccount/cancelSubscription",
	async (
		{ id, sideEffect = () => {} }: { id: number; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.cancelSubscription(id)
			.then(() => dispatch(getSubscriptionInfo()))
			.catch(() => rejectWithValue("Error in cancelling subscription"))
			.finally(sideEffect);
	}
);

export const CancelUserSubscription = createAsyncThunk(
	"myAccount/CancelUserSubscription",
	async (
		{
			userSubscriptionId,
			cancellationReasonId,
			refetchData = true,
			cb
		}: {
			userSubscriptionId: number;
			cancellationReasonId: number;
			refetchData: boolean;
			cb: () => void;
		},
		{ dispatch }
	) => {
		await usersService.cancelUserSubscription({ userSubscriptionId, cancellationReasonOptionId: cancellationReasonId });
		if (refetchData) {
			dispatch(getSubscriptionInfo());
			dispatch(getUserData());
		}
		cb();
	}
);

export const getSubscriptionCancellationOptions = createAsyncThunk(
	"myAccount/getSubscriptionCancellationOptions",
	async (_, { rejectWithValue }) => {
		return await usersService.getCancellationReasons().catch(rejectWithValue);
	}
);

export const deleteAccount = createAsyncThunk(
	"myAccount/deleteAccount",
	async (
		{ password, sideEffect = () => {} }: { password: string; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	) => {
		await usersService
			.deleteAccount(password)
			.then(() => {
				dispatch(setStateValue({ key: "isDeleteError", value: false }));
				dispatch(setStateValue({ key: "accountDeleted", value: true }));
				clearJwt();
				dispatch(emit({ message: "User account has been deleted, you are going to be logged out", color: "success" }));
			})
			.catch(error => {
				dispatch(setStateValue({ key: "isDeleteError", value: true }));
				rejectWithValue("Error in deleting account");
				dispatch(emit({ message: error.message, color: "error" }));
			})
			.finally(sideEffect);
	}
);

export const resumeSubscription = createAsyncThunk(
	"myAccount/resumeSubscription",
	async (
		{ id, sideEffect = () => {} }: { id: number; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.resumeSubscription({ subscriptionId: id })
			.then(() => {
				dispatch(
					emit({
						message: "Subscription start date updated!",
						color: "success"
					})
				);
				dispatch(getSubscriptionInfo());
				sideEffect();
			})
			.catch(() => rejectWithValue("Error in resuming subscription"));
	}
);

export const changeSubscriptionDate = createAsyncThunk(
	"myAccount/changeSubscriptionDate",
	async (
		{ data, sideEffect = () => {} }: { data: UserUpdateSubscriptionDto; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.changeSubscriptionDate(data)
			.then(() => {
				dispatch(
					emit({
						message: "Subscription start date has been updated",
						color: "success"
					})
				);
				dispatch(getSubscriptionInfo());
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				rejectWithValue("Error in updating subscription date");
			})
			.finally(sideEffect);
	}
);

export const fetchCourseCompletion = createAsyncThunk(
	"myAccount/fetchCourseCompletion",
	async (_, { rejectWithValue }) => {
		return await usersService.getCourseCompletion().catch(rejectWithValue);
	}
);

export const fetchCertificateLogs = createAsyncThunk(
	"myAccount/fetchCertificateLogs",
	async ({ userId, typeId }: { userId: number; typeId: number }, { rejectWithValue }) =>
		await assetsService.find({ orderBy: { createdAt: "DESC" }, filters: { userId, typeId } }).catch(rejectWithValue)
);

export const renewSubscription = createAsyncThunk(
	"myAccount/renewSubscription",
	async (
		{
			id,
			subscriptionBody,
			dispatch,
			CardElement,
			elements,
			stripe,
			sideEffect,
			handleError,
			couponCode
		}: {
			id: number;
			subscriptionBody;
			dispatch;
			handleError;
			CardElement;
			elements;
			stripe;
			sideEffect: () => void;
			couponCode?: string;
		},
		{ getState, rejectWithValue }
	): Promise<void> => {
		try {
			const {
				auth: { userSubscriptionTypeAddonIds },
				notifications: {
					bannerData: { paymentAndDiscount }
				}
			} = getState() as RootState;
			const payload = {
				data: {
					...subscriptionBody,
					fullName: subscriptionBody.fullName
				},
				filters: { id: subscriptionBody!.userId }
			};
			const cardElement = elements?.getElement(CardElement);
			if (stripe && cardElement) {
				const paymentMethod = await handleStripePayment(stripe, cardElement);
				if (paymentMethod) {
					await usersService.updatePaymentMethod({
						paymentProviderPaymentMethodIdentifier: paymentMethod!.id
					});
				}
			}
			await usersService.updateBillingAddress(payload);
			const subscription = await usersService.resumeSubscription({
				subscriptionId: id,
				couponCode,
				userSubscriptionTypeAddonIds
			});
			if (subscription!.data!.paymentNeedsConfirmation) {
				const { success, error, errorObject } = await handleStripePaymentConfirmation(
					stripe!,
					subscription!.data!.subscriptionPaymentIntentClientSecret as string
				);
				if (!success && error) {
					if (errorObject && errorObject.decline_code === INSUFFICIENT_FUNDS) {
						dispatch(setPaymentDiscount(paymentAndDiscount));
						throw new Error(INSUFFICIENT_FUNDS);
					}

					throw new Error(
						"Your payment did not succeed. You can try to renew your subscription again once your account has been moved to trial"
					);
				}
			}
			dispatch(resetCoupon());
			sideEffect();
		} catch (e) {
			dispatch(handleError(e.message));
			rejectWithValue(`Error in resuming subscription: ${e.message}}`);
		}
	}
);
interface SubscriptionThunk {
	CardElement: typeof CardElement;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	values;
	// eslint-disable-next-line no-unused-vars
	sideEffect: (values) => void;
	couponCode?: string;
	paymentAndDiscountId?: number;
}

// TODO: Refactor to use a destructured object an input instead to allow for better optional value handling
async function subscribeUser(
	userSubscriptionTypeId: number,
	values,
	paymentMethodId: string,
	sideEffect: (values: unknown) => void,
	stripe,
	dispatch: Dispatch<unknown>,
	userSubscriptionTypeAddonIds?: number[],
	couponCode?: string,
	paymentAndDiscountId?: number,
	userPaymentProviderAccountId?: number
) {
	try {
		const { address1, address2, city, countryId, phoneNumber, startDate, state, zip } = values;
		const subscriptionBody = {
			...(userPaymentProviderAccountId
				? { userPaymentProviderAccountId }
				: { paymentProviderId: 1, paymentProviderPaymentMethodIdentifier: paymentMethodId }),
			userSubscriptionTypeId,
			userSubscriptionTypeAddonIds,
			userSubscriptionStartDate: startDate.toISOString(),
			address: {
				phoneNumber,
				address1,
				address2,
				countryId,
				city,
				state,
				zipCode: zip
			},
			couponCode,
			paymentAndDiscountId
		};
		const subscription = await usersService.createSubscription(subscriptionBody);
		if (subscription!.data!.paymentNeedsConfirmation) {
			const { success, error } = await handleStripePaymentConfirmation(
				stripe!,
				subscription!.data!.subscriptionPaymentIntentClientSecret as string
			);
			if (!success && error) {
				throw new Error(
					"Your payment did not succeed. You can try to upgrade your subscription again once your account has been moved to trial"
				);
			}
		}
		dispatch(resetCoupon());
		sideEffect && sideEffect(values);
	} catch (e) {
		throw new Error(e.message);
	}
}

export const upgradeSubscription = createAsyncThunk(
	"auth/upgradeSubscription",
	async (
		{ CardElement, elements, stripe, values, sideEffect, couponCode, paymentAndDiscountId }: SubscriptionThunk,
		{ getState, dispatch }
	): Promise<void> => {
		dispatch(setError(""));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId },
			billing: { userPaymentProviderAccountId }
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		// Sign up with payment
		if (stripe && cardElement) {
			const { error, paymentMethod } = await stripe!.createPaymentMethod({ type: "card", card: cardElement! });
			if (error) {
				throw new Error("Please check your card details");
			} else if (paymentMethod) {
				return await subscribeUser(
					userSubscriptionTypeId,
					values,
					paymentMethod!.id,
					sideEffect,
					stripe,
					dispatch,
					userSubscriptionTypeAddonIds,
					couponCode,
					paymentAndDiscountId
				);
			}
		} else {
			return await subscribeUser(
				userSubscriptionTypeId,
				values,
				"", //We don't need to pass payment method id if we are not adding a new card
				sideEffect,
				stripe,
				dispatch,
				userSubscriptionTypeAddonIds,
				couponCode,
				paymentAndDiscountId,
				userPaymentProviderAccountId //We pass account id instead which has card attached
			);
		}
	}
);

export const editMyAccountDetails = createAsyncThunk(
	"myAccount/editMyAccountDetails",
	async (
		{
			data,
			sideEffect = () => {},
			successMsg,
			successSideEffect
		}: { data: UserUpdateDataDto; sideEffect: () => void; successMsg: string; successSideEffect?: () => void },
		{ rejectWithValue, dispatch }
	): Promise<void> => {
		await usersService
			.update({ data, filters: {} })
			.then(() => {
				successSideEffect && successSideEffect();
				dispatch(
					emit({
						message: successMsg,
						color: "success"
					})
				);
				dispatch(getUserInfo()); // todo: catch the exception
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect);
	}
);

export const changePassword = createAsyncThunk(
	"myAccount/changeUserPassword",
	async (
		{
			newPassword,
			currentPassword,
			sideEffect = () => {},
			successSideEffect
		}: { newPassword: string; currentPassword: string; sideEffect: () => void; successSideEffect },
		{ rejectWithValue, dispatch }
	) =>
		await usersService
			.changePassword({ newPassword, currentPassword })
			.then(() => {
				successSideEffect && successSideEffect();
				dispatch(
					emit({
						message: "Account Password changed successfully.",
						color: "success"
					})
				);
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			})
			.finally(sideEffect)
);

export const selectNewSubscription = createAsyncThunk(
	"myAccount/selectNewSubscription",
	async (subscriptionTypeId: number, { rejectWithValue }) => {
		return await usersService.selectRenewSubscription(subscriptionTypeId).catch(rejectWithValue);
	}
);

export const myAccountSlice = createSlice({
	name: "myAccount",
	initialState,
	reducers: {
		setError: (state, { payload }: PayloadAction<string>) => {
			state.errorMessage = payload;
		},
		setChangeDisplaySubscriptionDateModal: (state, { payload }: PayloadAction<boolean>) => {
			state.displayChangeSubscriptionDateModal = payload;
		},
		setRecommendedMaterial: (state, action) => {
			state.displayRecommendedMaterialModal = action.payload;
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		[getUserInfo.pending.type]: state => pendingReducer(state, "isLoading"),
		[getUserInfo.fulfilled.type]: (state, { payload }) => {
			if (!payload) {
				state.isLoading = false;
				return;
			}

			const {
				email,
				firstName,
				lastName,
				phoneNumber,
				profileImageUrl,
				school,
				userShippingDetails,
				acceptedTermsAndConditions,
				badges
			} = payload;
			const schoolName = school?.name || undefined;

			state.userInfo = {
				firstName,
				lastName,
				profileImageUrl,
				email,
				schoolName,
				phoneNumber,
				acceptedTermsAndConditions,
				badges,
				address:
					userShippingDetails?.address1 &&
					`${userShippingDetails?.address1}${
						userShippingDetails?.address2 ? `${`, ${userShippingDetails?.address2}`}` : " "
					}`,
				shippingDetails: userShippingDetails
			};
			state.isLoading = false;
		},
		[getSubscriptionInfo.pending.type]: state => pendingReducer(state, "isLoading"),
		[getSubscriptionInfo.fulfilled.type]: (state, { payload }) => {
			state.subscriptionInfo = payload;
			state.isLoading = false;
		},
		[getSubscriptionInfo.rejected.type]: state => {
			state.isLoading = false;
		},
		// upgradeSubscription flow
		[upgradeSubscription.pending.type]: state => {
			state.isLoadingSubscription = true;
		},
		[upgradeSubscription.fulfilled.type]: state => {
			state.isLoadingSubscription = false;
		},
		[upgradeSubscription.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingSubscription = false;
		},
		// edit My Account Details flow
		[editMyAccountDetails.pending.type]: state => {
			state.isLoadingAccountDetails = true;
		},
		[editMyAccountDetails.fulfilled.type]: state => {
			state.isLoadingAccountDetails = false;
		},
		[editMyAccountDetails.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingAccountDetails = false;
		},
		//update start subscription data
		[changeSubscriptionDate.pending.type]: state => {
			state.isLoadingSubscriptionUpdate = true;
		},
		[changeSubscriptionDate.fulfilled.type]: state => {
			state.isLoadingSubscriptionUpdate = false;
		},
		[changeSubscriptionDate.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoadingSubscriptionUpdate = false;
		},
		[renewSubscription.pending.type]: state => {
			state.isLoadingSubscription = true;
		},
		[renewSubscription.fulfilled.type]: state => {
			state.isLoadingSubscription = false;
		},
		[renewSubscription.rejected.type]: state => {
			state.isLoadingSubscription = false;
		},
		[selectNewSubscription.pending.type]: state => {
			state.isLoadingSubscription = true;
		},
		[selectNewSubscription.fulfilled.type]: state => {
			state.isLoadingSubscription = false;
		},
		[selectNewSubscription.rejected.type]: state => {
			state.isLoadingSubscription = false;
		},
		[fetchCourseCompletion.pending.type]: state => {
			state.isLoadingCourseCompletion = true;
		},
		[fetchCourseCompletion.fulfilled.type]: (state, { payload }) => {
			state.isLoadingCourseCompletion = false;
			state.courseCompletion = payload;
		},
		[fetchCourseCompletion.rejected.type]: state => {
			state.isLoadingCourseCompletion = false;
		},
		[getSubscriptionCancellationOptions.pending.type]: state => {
			state.isLoadingCancellationOptions = true;
		},
		[getSubscriptionCancellationOptions.fulfilled.type]: (state, { payload }: PayloadAction<ICancellationOptions>) => {
			state.isLoadingCancellationOptions = false;
			state.cancellationOptions = payload.items;
		},
		[getSubscriptionCancellationOptions.rejected.type]: state => {
			state.isLoadingCancellationOptions = false;
		},
		[CancelUserSubscription.pending.type]: state => {
			state.isCancellingSubscription = true;
		},
		[CancelUserSubscription.fulfilled.type]: state => {
			state.isCancellingSubscription = false;
		},
		[CancelUserSubscription.rejected.type]: state => {
			state.isCancellingSubscription = false;
		},
		[fetchCertificateLogs.pending.type]: state => {
			state.isLoadingCertificateLogs = true;
		},
		[fetchCertificateLogs.fulfilled.type]: (state, { payload }) => {
			state.isLoadingCertificateLogs = false;
			state.certificateLogs = payload.items;
		},
		[fetchCertificateLogs.rejected.type]: state => {
			state.isLoadingCertificateLogs = false;
		}
	}
});

export const { setError, setStateValue, setChangeDisplaySubscriptionDateModal, setRecommendedMaterial } =
	myAccountSlice.actions;

export const getFullState = (state: RootState): MyAccountState => state.myAccount;
export const selectMyAccountIsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoading;
export const selectUserInfo = ({ myAccount }: RootState): UserInfo | null => myAccount.userInfo;
export const selectDisplaySubscriptionModal = ({ myAccount }: RootState): boolean =>
	myAccount.displayChangeSubscriptionDateModal;
export const selectSubscriptionInfo = ({ myAccount }: RootState): UserGetSubscriptionInfoResponseDto | null =>
	myAccount.subscriptionInfo;
export const selectSubcriptionIsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoadingSubscription;
export const selectUpdateSubcriptionIsLoading = ({ myAccount }: RootState): boolean =>
	myAccount.isLoadingSubscriptionUpdate;
export const selectError = ({ myAccount }: RootState): string => myAccount.errorMessage;
export const selectMyAccountDetailsLoading = ({ myAccount }: RootState): boolean => myAccount.isLoadingAccountDetails;
export const selectMainImageKey = ({ myAccount }: RootState): CustomInputModel<string> => myAccount.mainImageKey;
export const selectRecommendedMaterial = ({ myAccount }: RootState): boolean =>
	myAccount.displayRecommendedMaterialModal;
export const selectFullState = ({ myAccount }: RootState): MyAccountState => myAccount;

export default myAccountSlice.reducer;
