import React from "react";

import { CircularProgress } from "@material-ui/core";

import { LoadingWrapper } from "../styles";

const ContentLoader = ({
	height,
	size,
	color = "primary"
}: {
	height?: number | string;
	size?: string;
	color?: "primary" | "secondary";
}) => (
	<LoadingWrapper height={height}>
		<CircularProgress size={size ? size : "7rem"} color={color} thickness={5} variant="indeterminate" />
	</LoadingWrapper>
);

export default ContentLoader;
