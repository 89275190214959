import { ThemeOptions } from "@material-ui/core/styles";

import * as colors from "../../colors";

import redColorPalette from "../palettes/dark_redColorPalette";

export const redOptions = {
	typography: {
		allVariants: {
			color: colors.basic[100],
			fontFamily: "var(--openSans)"
		},
		h1: {
			fontSize: 22,
			fontFamily: "var(--poppins)",
			fontWeight: 900,
			color: colors.red[600]
		},
		h2: {
			fontSize: 18
		},
		h3: {
			fontSize: 16
		}
	},
	overrides: {
		MuiList: {
			root: {
				backgroundColor: colors.basic[750]
			}
		},
		MuiStepper: {
			root: {
				background: "transparent"
			}
		},
		MuiStepLabel: {
			root: {
				color: colors.basic[100],
				fontWeight: 600,
				"& .MuiStepLabel-label.MuiStepLabel-active": {
					fontWeight: 600,
					color: colors.basic[100]
				},
				"& .MuiStepLabel-label.MuiStepLabel-completed": {
					fontWeight: 600,
					color: colors.basic[100]
				}
			},
			completed: {
				color: colors.basic[100],
				opacity: 0.3,
				fontWeight: 600,
				fontSize: "15px"
			},
			active: {
				color: colors.basic[100],
				opacity: 0.9,
				fontWeight: 600,
				fontSize: "15px"
			},
			label: {
				color: colors.basic[100],
				opacity: 0.4,
				fontWeight: 600,
				fontSize: "15px"
			}
		},
		MuiSelect: {
			root: {
				backgroundColor: colors.basic[900],
				borderRadius: "4px"
			},
			filled: {
				color: colors.basic[100]
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.basic[800],
				borderRadius: "4px",
				"&:hover input:-webkit-autofill": {
					"-webkit-box-shadow": `0 0 0 5000px ${colors.basic[750]} inset`
				}
			},
			input: {
				"&:-webkit-autofill": {
					WebkitBoxShadow: `0 0 0 1000px ${colors.basic[750]} inset`,
					borderTopRightRadius: 0,
					borderTopLeftRadius: 0
				},
				color: colors.basic[100]
			}
		},
		MuiCard: {
			root: {
				background: colors.basic[900],
				padding: "30px",
				width: "100%",
				borderRadius: "4px"
			}
		},
		MuiButton: {
			root: {
				fontFamily: "var(--poppins)",
				textTransform: "none",
				padding: "10px 20px",
				fontSize: "14px"
			},
			text: {
				color: "hsl(0,0%,69%)" //todo: add into the colors set
			},
			containedPrimary: {
				fontWeight: 600
			},
			containedSecondary: {
				fontWeight: 600,
				"&:hover": {
					backgroundColor: colors.basic[900]
				}
			}
			// containedPrimary: {
			// 	borderRadius: 4,
			// 	backgroundColor: "#d5af34",
			// 	"&:hover": {
			// 		backgroundColor: "#f4c93f"
			// 	}
			// }
		},
		MuiSvgIcon: {
			root: {
				"& #path_primary": {
					fill: colors.red[600],
					"stop-color": colors.red[600]
				},
				"& #path_secondary": {
					fill: colors.red[200],
					"stop-color": colors.red[200]
				}
			}
		},
		MuiCheckbox: {
			root: {
				color: colors.basic[600],
				"&$checked": {
					color: colors.primary[500]
				}
			}
		},
		MuiRadio: {
			root: {
				color: colors.basic[600],
				"&$checked": {
					color: colors.primary[500]
				}
			}
		},
		MuiPaginationItem: {
			root: {
				"&[aria-current='true']": {
					background: `${colors.red[600]} !important`
				},
				"&:hover": {
					backgroundColor: `${colors.red[200]} !important`
				}
			}
		},
		MuiTableCell: {
			root: {
				borderBottomColor: colors.basic[700]
			}
		}
	},
	palette: {
		...redColorPalette
	}
} as ThemeOptions;
