import { Box, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Box)`
	display: flex;
	margin: auto;
	width: 100%;
	height: 100%;
	max-width: 1024px;
	max-height: 768px;
	background-color: ${({ theme }) => theme.palette.background.default};
`;

export const LeftBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 80px;
	gap: 16px;

	svg {
		margin-bottom: 16px;
	}

	.title-text {
		font-family: var(--poppins);
		font-weight: 700;
		opacity: 0.87;
		font-size: 36px;

		strong {
			color: ${({ theme }) => theme.palette.colors.basic[500]};
		}

		.description-text {
			font-family: var(--openSans);
			font-weight: 600;
			color: ${({ theme }) => theme.palette.colors.basic[500]};
			font-size: 15px;
			margin-bottom: 16px;
		}
	}
`;

export const RightBlock = styled(Box)`
	padding: 16px 24px;
	min-width: 431px;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.palette.colors.basic[800]};
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.about-text {
		font-family: var(--openSans);
		font-weight: 400;
		color: ${({ theme }) => theme.palette.colors.basic[500]};
		font-size: 15px;
	}

	.course-title {
		opacity: 0.87;
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 26px;
	}

	.course-description {
		margin-top: -2px;
		font-family: var(--openSans);
		font-weight: 600;
		font-size: 15px;
	}

	.duration-block {
		display: flex;
		align-items: center;
		gap: 4px;

		p {
			font-family: var(--openSans);
			font-weight: 400;
			color: ${({ theme }) => theme.palette.colors.basic[500]};
			font-size: 13px;
		}
	}

	.course-curriculum {
		margin-top: 16px;
		font-family: var(--openSans);
		font-weight: 400;
		color: ${({ theme }) => theme.palette.colors.basic[500]};
		font-size: 15px;
	}

	.lesson-header-text {
		font-family: var(--openSans);
		font-weight: 600;
		font-size: 15px;
		color: ${({ theme }) => theme.palette.colors.basic[500]};
	}

	.lesson-description-text {
		font-family: var(--openSans);
		font-weight: 400;
		font-size: 13px;
	}
`;

export const LessonNumber = styled(Box)`
	height: 56px;
	min-width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	position: relative;
	font-weight: bold;
	cursor: pointer;

	span {
		z-index: 1;
	}

	${({ current, theme }) =>
		`
		&::before {
			content: ' ';
			position: absolute;
			top: 50%;
			right: 50%;
			color: ${current ? theme.palette.colors.basic[100] : theme.palette.text.primary};
			background: ${current ? theme.palette.primary.dark : theme.palette.colors.basic[500]};
			height: 32px;
			width: 32px;
			transform: translate(50%, -50%);
			border-radius: 50%;
		}
	`}
`;

export const useAccordionStyles = makeStyles(theme => ({
	discussionBoard: {
		width: "100%",
		marginTop: "20px !important",
		backgroundColor: theme.palette.colors.basic[1000],
		color: theme.palette.text.primary,
		borderRadius: "4px !important",
		"&:before": {
			height: 0
		}
	},
	accSummary: {
		background: theme.palette.colors.basic[900]
	},
	commentPost: {
		padding: "8px 16px 5px",
		"& a": {
			textDecoration: "underline"
		}
	}
}));
