import { Coupon } from "@remar/shared/dist/models";

export const getCouponScope = (
	coupon: Coupon,
	courseId: number
): { signUp: boolean; renewals: boolean; books: boolean; shipping: boolean } => {
	const isSelectedCourseIncluded = coupon.courses?.some(c => c.id === courseId);
	const findProduct = (coupon, name) => coupon.products.some(p => p.name === name);
	return {
		signUp: findProduct(coupon, "Sign Up") && isSelectedCourseIncluded,
		renewals: findProduct(coupon, "Renewals"),
		books: findProduct(coupon, "Books"),
		shipping: findProduct(coupon, "Shipping")
	};
};
