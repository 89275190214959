import React, { memo, useEffect, useMemo } from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";

import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { TopNav as SharedTopNav } from "@remar/shared/dist/layouts/BaseLayout";
import { NotificationAudienceTypeIdEnum, NotificationDeliveryMethodEnum } from "@remar/shared/dist/models";
import useFirebaseNotifications from "@remar/shared/dist/services/useFirebaseNotifications";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getQbNextSubscriptions, logout } from "store/features/Auth/authSlice";
import { setChangeDisplaySubscriptionDateModal } from "store/features/MyAccount/myAccountSlice";
import {
	getFullState as getFullNotifications,
	getUserNotifications,
	setBannerData
} from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import Banner from "./Banner";
import BellNotifications from "./BellNotifications";

const TopNav = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const {
		user: userInfo,
		subscription: { isTrial }
	} = useAppSelector(getFullState);
	const { onDeleteFcmToken } = useFirebaseNotifications();
	const { bannerNotifications, bannerData } = useAppSelector(getFullNotifications);

	const analytics = useAnalyticsEventTracker("User");

	const hasQBOnlySubscription = useMemo(
		() =>
			userInfo?.subscriptions?.some(
				s => s.type?.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.QuestionBank
			),
		[userInfo?.subscriptions]
	);

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.deliveryMethod": NotificationDeliveryMethodEnum.Socket
				},
				include: ["notification.coupon.courses", "notification.coupon.products", "notification.paymentAndDiscount"]
			})
		);
	}, [dispatch]);

	const BannerNotifications = useMemo(() => bannerNotifications?.items || [], [bannerNotifications]);

	useEffect(() => {
		if (hasQBOnlySubscription) {
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: "To access the full content lectures click 𝗛𝗲𝗿𝗲!",
					isTrial: true,
					isDismissible: false,
					takeOver: true,
					action: () => dispatch(getQbNextSubscriptions())
				})
			);
		}
	}, [dispatch, hasQBOnlySubscription]);

	useEffect(() => {
		if (!!getCurrentSubscription(userInfo?.subscriptions)?.isPaused) {
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: "Your subscription is currently paused. Please click to resume.",
					isTrial: false,
					isDismissible: false,
					takeOver: false,
					action: () => dispatch(setChangeDisplaySubscriptionDateModal(true))
				})
			);
		}
	}, [dispatch, isTrial, userInfo?.subscriptions]);

	useEffect(() => {
		if (BannerNotifications.length) {
			const notificationAudiences = [NotificationAudienceTypeIdEnum.AllUsers];
			isTrial || hasQBOnlySubscription
				? notificationAudiences.push(NotificationAudienceTypeIdEnum.TrialUsers)
				: notificationAudiences.push(NotificationAudienceTypeIdEnum.PaidUsers);

			const banner = [...BannerNotifications]
				.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
				.find(
					b =>
						b.notification.isActive &&
						notificationAudiences.includes(b.notification.notificationAudienceTypeId) &&
						(!b.expiresAt || new Date(b.expiresAt) > new Date())
				);
			if (!banner) return;
			const {
				createdAt,
				expiresAt,
				notification: { body, duration, coupon, isDismissible, paymentAndDiscount }
			} = banner;
			dispatch(
				setBannerData({
					showBanner: true,
					bannerMessage: body,
					isTrial: isTrial || hasQBOnlySubscription,
					isDismissible: isDismissible,
					duration,
					createdAt,
					expiresAt,
					takeOver: true,
					coupon,
					paymentAndDiscount
				})
			);
		}
	}, [BannerNotifications, dispatch, hasQBOnlySubscription, isTrial]);

	return (
		<SharedTopNav
			userInfo={userInfo}
			banner={bannerData?.showBanner ? <Banner bannerData={bannerData} /> : undefined}
			accountPath={routes.myAccount.getPath()}
			onLogOut={async () => {
				dispatch(logout());
				await onDeleteFcmToken();
				analytics({ eventName: "logout" });
			}}
			topMenuItems={
				<>
					{/*	<MessageIcon>
						<Icon name="message-linear" height={19} width={22} fill="#2a2e37" />
						</MessageIcon>
					*/}
					<BellNotifications />
					<Tooltip title="FAQ">
						<IconButton
							size="small"
							onClick={() => {
								if (location.pathname === routes.help.getPath()) history.go(0);
								else history.push(routes.help.getPath());
							}}
						>
							<HelpRounded style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
						</IconButton>
					</Tooltip>
				</>
			}
		/>
	);
};

export default memo(TopNav);
