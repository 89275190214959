import React, { useState } from "react";

import { Box, Grid, Modal, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";

import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { RootState, useAppDispatch, useAppSelector } from "store";

import { updateBillingAddress } from "store/features/Billing/BillingSlice";

import ChangeBillingAddress from "./ChangeBillingAddress";
import { AddressText, useChangePasswordStyles } from "./styles";

const BillingAddress = ({ closeModal }) => {
	const [modal, setModal] = useState(false);
	const dispatch = useAppDispatch();

	const { userInfo } = useAppSelector((store: RootState) => store.myAccount);

	const classes = useChangePasswordStyles();

	const submitBillingAddress = async values => {
		const payload = {
			data: {
				...values,
				phoneNumber: userInfo?.phoneNumber,
				userId: userInfo?.shippingDetails?.userId
			},
			filters: { id: userInfo!.shippingDetails?.id }
		};
		await dispatch(
			updateBillingAddress({
				payload,
				sideEffect: () => {
					setModal(false);
					closeModal();
				}
			})
		);
	};

	if (!userInfo) return <ContentLoader />;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box>
					<Typography className={classes.formSubtitle}>Billing address</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box>
					<AddressText>{userInfo!.shippingDetails?.fullName || userInfo!.firstName + userInfo!.lastName}</AddressText>
					<AddressText>{`${userInfo!.shippingDetails?.address1}, ${userInfo!.shippingDetails?.address2}`}</AddressText>
					<AddressText>{`${userInfo!.shippingDetails?.city}, ${userInfo!.shippingDetails?.zipCode}`}</AddressText>
					<AddressText>{userInfo!.shippingDetails?.country?.name}</AddressText>
					<AddressText>{userInfo!.shippingDetails?.phoneNumber}</AddressText>
				</Box>
				<Box mt={2} ml={-1}>
					<Button color="primary" onClick={() => setModal(true)}>
						Change
					</Button>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Modal open={modal} onClose={() => setModal(false)}>
					<ChangeBillingAddress handleSubmit={submitBillingAddress} closeModal={() => setModal(false)} />
				</Modal>
			</Grid>
		</Grid>
	);
};
export default BillingAddress;
