import React from "react";

export enum FilterTypes {
	MultipleCheckbox = "MultipleCheckbox"
}

export type alignments = "left" | "right" | "inherit" | "center" | "justify";

interface ICheckBoxType {
	id: number;
	label: string;
	checked: boolean;
}
export interface IMultipleCheckbox {
	allFilters: ICheckBoxType;
	checkboxFilters: ICheckBoxType[];
}

export type filtersTypes = IMultipleCheckbox; // TODO:add more types to filterTypes eg: IMultipleCheckbox| IDropDown

export interface IRowProps {
	item;
	childItems;
	columns;
	index: number;
	customCellStyle?: React.CSSProperties;
	disabled?: boolean | undefined;
	showNestedRowsIcon?: boolean;
	onRowClick?: <T>(data: T) => void;
}

export interface IColumnFilter {
	type: string;
	data: filtersTypes;
	width?: number;
	onChange: (val: IMultipleCheckbox) => void;
}

export type sortDirectionType = "ASC" | "DESC";
export enum sortDirectionEnum {
	ASC = "ASC",
	DESC = "DESC"
}
export interface IColumn<TData> {
	label?: JSX.Element | string;
	dataKey?: string;
	alignment?: alignments | string | undefined;
	Cell?: (c: { rowData: TData; rowIndex: number }) => JSX.Element;
	ChildCell?: (c: { parentRowData: TData; rowData: TData; rowIndex: number }) => JSX.Element;
	childCellColSpan?: number;
	width?: number;
	filter?: IColumnFilter;
	sort?: (direction: sortDirectionType | null) => void;
	sortDirection?: sortDirectionType;
}

export interface ITableProps<T> {
	columns: IColumn<T>[];
	data: Array<T>;
	customStyle?: React.CSSProperties;
	height: number | string;
	hideHeader?: boolean;
	customTableStyle?: React.CSSProperties;
	rowCustomCellStyle?: React.CSSProperties;
	customCellStyle?: React.CSSProperties;
	disableRow?: <T>(data: T) => boolean;
	showNestedRowsIcon?: boolean;
	showMobileView?: boolean;
	onRowClick?: <T>(data: T) => void;
}
