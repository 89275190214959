import { ColorsType } from "../theme/default";

const primary: ColorsType = {
	100: "hsl(225,42%,83%)",
	200: "hsl(226,43%,72%)",
	300: "hsl(226,43%,60%)",
	400: "hsl(224,44%,51%)",
	500: "hsl(222,64%,42%)",
	600: "hsl(221,69%,38%)",
	700: "hsl(220,80%,33%)",
	800: "hsl(218,97%,28%)",
	900: "hsl(222,100%,24%)",
	950: "hsl(213,53%,19%)",
	960: "hsl(222, 13%, 19%)",
	1000: "hsl(213,94%,63%)",
	1100: "hsl(213,55%,19%)",
	1200: "hsl(216,41%,13%)",
	1300: "hsl(213,72%,57%)",
	1400: "hsl(199,79%,47%)",
	1500: "hsl(200,100%,97%)",
	1600: "hsl(213,54%,44%)",
	1700: "hsl(211,83%,54%)",
	1800: "hsl(274,100%,42%)",
	1900: "hsl(213, 94%, 63%)",
	2000: "hsl(212, 48%, 18%)",
	2100: "hsl(214, 53%, 26%)",
	2200: "hsl(213, 72%, 57%)",
	2300: "hsl(212, 46%, 19%)"
};

export default primary;
