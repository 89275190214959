import React from "react";

import { SnackBar } from "@remar/shared/dist/components/Notifications";
import { useAppDispatch, useAppSelector } from "store";
import { NotificationItem, dismiss, selectNotifications } from "store/features/notifications/notifications.slice";

const SnackBarNotifications = () => {
	const dispatch = useAppDispatch();

	const items: NotificationItem[] = useAppSelector(selectNotifications);

	const handleClose = (id: number) => dispatch(dismiss(id));

	return <SnackBar items={items} handleClose={id => handleClose(id)} />;
};

export default SnackBarNotifications;
