import { Box, TableCell, TableRow } from "@material-ui/core";

import { darken, lighten, makeStyles } from "@material-ui/core/styles";

import styled, { css } from "styled-components";

import { sortDirectionEnum } from "./columnFilters/types";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	tableContainer: {
		background: theme.palette.background.paper,
		padding: 0
	},
	table: {
		minWidth: 650,
		width: "inherit",
		background: theme.palette.background.paper,
		position: "relative"
	},
	theader: {
		maxHeight: "48px",
		position: "sticky",
		top: 0,
		background:
			theme.palette.type === "dark"
				? lighten(theme.palette.background.paper, 0.03)
				: darken(theme.palette.background.paper, 0.03),
		zIndex: 3
	},
	columnFilterIcon: {
		margin: "15px 5px",
		width: "18px",
		cursor: "pointer"
	},
	filterContainer: {
		display: "flex",
		alignItems: "center",
		position: "relative"
	},
	filterWrapper: {
		position: "absolute",
		top: "40px",
		right: 0,
		background: theme.palette.background.paper,
		padding: "10px"
	}
}));

export const StyledCell = styled(TableCell)`
	width: ${({ width }) => (width ? `${width}px` : "fit-content")};
	& p {
		opacity: ${props => (props.$disabled ? 0.5 : 1)}
`;

export const StyledSubCell = styled(TableCell)`
	background-color: ${({ theme }) =>
		theme.palette.type === "dark"
			? lighten(theme.palette.background.paper, 0.03)
			: darken(theme.palette.background.paper, 0.03)};
	color: ${({ theme }) => theme.palette.text.primary};
`;
export const StyledTableRow = styled(TableRow)`
	cursor: pointer;
`;
export const StyledTableSubRow = styled(TableRow)`
	@keyframes fade_in_show {
		0% {
			opacity: 0;
			display: none;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
			display: table-row;
		}
	}
	opacity: 0;
	display: none;
	${({ $isExpanded }) =>
		$isExpanded &&
		css`
			display: table-row;
			opacity: 1;
			animation: fade_in_show 0.3s ease-in-out;
		`}
`;
export const ClosedChevron = styled.img`
	width: 15px;
	transform: rotate(-90deg);
	${({ isExpanded }) =>
		isExpanded &&
		css`
			transform: rotate(0deg);
		`}
`;
export const SortArrowWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	cursor: pointer;

	svg:first-child {
		margin-bottom: -8px;
		fill: ${({ theme, sortDirection }) =>
			sortDirection === sortDirectionEnum.ASC ? theme.palette.colors.primary[1400] : theme.palette.colors.basic[400]};
	}
	svg:last-child {
		margin-top: -8px;
		fill: ${({ theme, sortDirection }) =>
			sortDirection === sortDirectionEnum.DESC ? theme.palette.colors.primary[1400] : theme.palette.colors.basic[400]};
	}
`;
