import React, { FC, useMemo, useState } from "react";

import { Box, Card, Container, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LogoComponent from "@remar/shared/dist/components/LogoComponent";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Course } from "@remar/shared/dist/models";

import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";

import SelectCourse from "./SelectCourse";
import SelectOfferings from "./SelectOfferings";

import { useChangeCourseStyles } from "./styles";

interface Props {
	onClose: () => void;
	selectCourse?: boolean;
	courses?: Course[];
	accountClaimCode?: string;
}

const ChangeOrSelectCourse: FC<Props> = ({ onClose, selectCourse, courses, accountClaimCode }) => {
	const { courseId } = useParams<{ courseId: number }>();
	const classes = useChangeCourseStyles();

	const [pickCourse, setPickCourse] = useState<number | null>(parseInt(courseId, 10) || null);

	const { isLoading, course: _c, subscription } = useAppSelector(getFullState);

	const course = useMemo(
		() =>
			_c
				? { ..._c, allowedForUST: [subscription] }
				: courses
				? courses?.find(course => course.id === pickCourse)
				: null,
		[_c, courses, pickCourse, subscription]
	);

	const courseSelectionSteps = !course ? (
		<SelectCourse courses={courses} setPickCourse={setPickCourse} />
	) : (
		<SelectOfferings
			course={course}
			onClose={onClose}
			accountClaimCode={accountClaimCode}
			isGetRecurring={false}
			setPickCourse={setPickCourse}
		/>
	);

	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card className={classes.changeCourseCard}>
					<Box>
						<LogoComponent />
						<Box display="flex" alignItems="center" justifyContent={!selectCourse ? "space-between" : "center"}>
							<Typography className={classes.changeCourseTitle} variant={!selectCourse ? "h6" : "h5"}>
								{selectCourse ? "Select Course" : "Change Course"}
							</Typography>
							{!selectCourse && (
								<IconButton onClick={onClose}>
									<CloseIcon color="disabled" />
								</IconButton>
							)}
						</Box>
					</Box>
					{isLoading ? <ContentLoader /> : courseSelectionSteps}
				</Card>
			</Box>
		</Container>
	);
};

export default ChangeOrSelectCourse;
