import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseFindDto } from "@remar/shared/dist/api/baseApiService";
import { BookCartItem, BookList, Book as BookType, BuyBook } from "@remar/shared/dist/models";
import { validateFormAction as utilsValidateFormAction } from "@remar/shared/dist/utils/form/form.utils";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { RootState } from "../../index";
import { booksService, usersService } from "../../services";
import { emit } from "../notifications/notifications.slice";

interface BooksState {
	books: BookList | null;
	isFetchingBooks: boolean;
	isPurchasingBooks: boolean;
	bookPrice: number;
	cart: BookCartItem[];
}

const initialState: BooksState = {
	books: null,
	isFetchingBooks: false,
	isPurchasingBooks: false,
	bookPrice: 0,
	cart: []
};

export const bookSlice = createSlice({
	name: "books",
	initialState,
	reducers: {
		addToCart: (state, action: PayloadAction<BookType>) => {
			const book = action.payload;
			const _cart = [...state.cart];
			const itemIndex = _cart.findIndex(item => item.id === book.id);
			if (itemIndex > -1) {
				_cart[itemIndex].quantity += 1;
			} else {
				_cart.push({ ...book, quantity: 1 });
			}
			state.cart = _cart;
		},
		removeFromCart: (state, action) => {
			const book = action.payload;
			const _cart = [...state.cart];
			const itemIndex = _cart.findIndex(item => item.id === book.id);
			if (_cart[itemIndex].quantity > 1) {
				_cart[itemIndex].quantity -= 1;
				state.cart = _cart;
			} else {
				state.cart = _cart.filter(item => item.id !== book.id);
			}
		},
		emptyCart: state => {
			state.cart = [];
		},
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction
	},
	extraReducers: builder =>
		builder
			.addCase(fetchBooks.pending.type, state => {
				state.isFetchingBooks = true;
			})
			.addCase(fetchBooks.fulfilled.type, (state, action: PayloadAction<BookList>) => {
				state.isFetchingBooks = false;
				state.books = action.payload;
			})
			.addCase(fetchBooks.rejected.type, state => {
				state.isFetchingBooks = false;
			})
			.addCase(purchaseBooks.pending.type, state => {
				state.isPurchasingBooks = true;
			})
			.addCase(purchaseBooks.fulfilled.type, state => {
				state.isPurchasingBooks = false;
			})
			.addCase(purchaseBooks.rejected.type, state => {
				state.isPurchasingBooks = false;
			})
});

export const fetchBooks = createAsyncThunk("books/fetchBooks", async (data: BaseFindDto, { rejectWithValue }) => {
	return await booksService.find({ ...data, orderBy: { createdAt: "DESC" } }).catch(e => rejectWithValue(e.message));
});

export const purchaseBooks = createAsyncThunk(
	"books/purchaseBooks",
	async ({ data, cb }: { data: BuyBook; cb: () => void }, { dispatch, rejectWithValue }) => {
		try {
			const res = await usersService.purchaseBooks(data);
			dispatch(emit({ message: "Books has been successfully purchased", color: "success" }));
			cb && cb();
			return res;
		} catch (e) {
			dispatch(emit({ message: "Failed to purchase Book(s)", color: "error" }));
			return rejectWithValue(e.message);
		}
	}
);

export const getFullState = (state: RootState): BooksState => state.books;
export const { setStateValue, validateForm, addToCart, removeFromCart, emptyCart } = bookSlice.actions;
export default bookSlice.reducer;
