import React, { useRef, useState } from "react";

import { useTheme } from "@material-ui/core";

import { CommentSend, HelperText, PostCommentContainer, TextField } from "./style";

import { IExtendedTheme } from "../../theme/default";

interface PostCommentProps {
	lessonId: number;
	parentId?: number;
	placeholder?: string;
	defaultValue?: string;
	onEditClose?: () => void;
	edit?: boolean;
	commentId?: number;
	onMockDelete: (commentId: number | undefined, parentId: number | undefined, text: string) => void;
	onCreate: (text: string, lessonId: number, parentId?: number) => void;
	onEdit: (text: string, lessonId: number, parentId?: number, commentId?: number) => void;
	isReadOnly?: boolean;
}

const PostComment = ({
	lessonId,
	parentId,
	placeholder,
	defaultValue,
	onEditClose,
	edit,
	commentId,
	onMockDelete,
	onCreate,
	onEdit,
	isReadOnly = false
}: PostCommentProps) => {
	const theme = useTheme<IExtendedTheme>();
	const textAreaRef = useRef<HTMLInputElement>(null);
	const [commentLimit, setCommentLimit] = useState(false);
	const resetPostCommentField = () => {
		textAreaRef!.current!.value = "";
	};

	const postComment = () => {
		setCommentLimit(false);
		if (textAreaRef!.current!.value.trim().length !== 0) {
			if (edit && onEditClose) {
				onMockDelete(parentId, commentId, textAreaRef!.current!.value);
				onEdit(textAreaRef!.current!.value, lessonId, parentId, commentId);
				resetPostCommentField();
				onEditClose();
				return;
			}
			onCreate(textAreaRef!.current!.value, lessonId, parentId);
			resetPostCommentField();
		}
	};

	const onChangeText = e => {
		if (e.target.value.length > 600) {
			let value = e.target.value;
			value = value.substring(0, 600);
			textAreaRef!.current!.value = value;
			setCommentLimit(true);
		} else if (commentLimit) {
			setCommentLimit(false);
		}
	};

	return (
		<PostCommentContainer>
			<TextField
				disabled={isReadOnly}
				isEditing={edit}
				{...(commentLimit && {
					helperText: <HelperText> You have reached the maximum text limit. </HelperText>
				})}
				autoFocus
				fullWidth
				hiddenLabel
				multiline
				inputRef={textAreaRef}
				InputProps={{
					style: {
						color: theme.palette.text.primary,
						height: "100%",
						paddingTop: "10px",
						backgroundColor: theme.palette.background.default
					},
					disableUnderline: true
				}}
				defaultValue={defaultValue}
				color="primary"
				onChange={onChangeText}
				onKeyPress={(event: React.KeyboardEvent) => {
					if (event.key === "Enter" && !event.shiftKey) {
						event.preventDefault();
						postComment();
					}
				}}
				variant="filled"
				size="small"
				placeholder={placeholder || "Write a comment..."}
			/>
			<CommentSend isEditing={edit} onClick={() => !isReadOnly && postComment()} disabled={isReadOnly}>
				<span>{edit ? "Save Changes" : "Send"}</span>
			</CommentSend>
		</PostCommentContainer>
	);
};

export default PostComment;
