import { ColorsType } from "../theme/default";

const info: ColorsType = {
	100: "hsl(202,95%,93%)",
	200: "hsl(205,97%,85%)",
	300: "hsl(207,96%,78%)",
	400: "hsl(210,94%,72%)",
	500: "hsl(213,94%,63%)",
	600: "hsl(216,66%,53%)",
	650: "hsl(222,64%,42%)",
	700: "hsl(218,66%,42%)",
	750: "hsl(224,7%,32%)",
	800: "hsl(221,72%,33%)",
	900: "hsl(224,79%,26%)"
};

export default info;
