import React from "react";

import { Box, Snackbar, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { IExtendedTheme } from "../../theme/default";

type SnackbarProps = {
	items: { id: number; message: string; type: "success" | "info" | "warning" | "error" }[];
	handleClose: (id: number) => void;
};
/**
 * The SnackBar functional component renders a list of snackbars with messages.
 * It adjusts its positioning and style based on the screen size.
 *
 * @param {SnackbarProps} props - The properties passed to the component.
 * @param {Array} props.items - An array of snackbar items to display. Each item should have an id, message, and type.
 * @param {Function} props.handleClose - A function to handle the closing of a snackbar. It takes the id of the snackbar to close.
 *
 * @returns {JSX.Element} The rendered JSX element for the Snackbar component.
 *
 * The component uses the `useTheme` hook to apply the current theme and `useMediaQuery` to determine if the screen size is small.
 * Anchors the snackbar components to the bottom right of the screen, with style adjustments for mobile screens.
 *
 * @example
 * // Example usage of the SnackBar component
 * import React, { useState } from "react";
 * import SnackBar from "./SnackBar";
 *
 * const ExampleComponent = () => {
 *	 const [snackbars, setSnackbars] = useState([
 *		 { id: 1, message: "Success message", type: "success" },
 *		 { id: 2, message: "Info message", type: "info" },
 *		 { id: 3, message: "Warning message", type: "warning" },
 *		 { id: 4, message: "Error message", type: "error" },
 *	 ]);
 *
 *	 const handleClose = (id) => {
 *		 setSnackbars((prev) => prev.filter((snackbar) => snackbar.id !== id));
 *	 };
 *
 *	 return <SnackBar items={snackbars} handleClose={handleClose} />;
 * };
 *
 * export default ExampleComponent;
 */
const SnackBar = ({ items, handleClose }: SnackbarProps) => {
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box position={isMobile ? "relative" : "fixed"} bottom="0" right="0" width="100%">
			<Box display="flex" flexDirection="column" width="100%">
				{items.map(({ id, message, type }) => (
					<Snackbar
						key={id}
						open
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						style={{ position: isMobile ? "fixed" : "relative", margin: "6px 0", width: isMobile ? "100%" : "auto" }}
					>
						<Alert
							elevation={6}
							variant="filled"
							style={{ width: isMobile ? "100%" : "auto" }}
							onClose={() => handleClose(id)}
							severity={type}
						>
							{message}
						</Alert>
					</Snackbar>
				))}
			</Box>
			{/* <Button onClick={() => snackbar.enqueueSnackbar("=")}>+</Button> */}
		</Box>
	);
};

export default SnackBar;
