import React, { useMemo } from "react";

import { Badge, ClickAwayListener, IconButton, List, ListItem, Tooltip, useTheme } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";

import { ISocketNotificationItem } from "../../models";
import { IExtendedTheme } from "../../theme/default";

type BellNotificationsBlockProps = {
	bellNotifications: ISocketNotificationItem[];
	markAsRead?: () => void;
};
/**
 * A functional component that displays a bell icon with notification badges. When clicked,
 * it expands to show a list of notifications. If there are no notifications, it shows a
 * message indicating that there are no notifications.
 *
 * @param {BellNotificationsBlockProps} props - The properties for the BellNotificationsBlock component.
 * @param {Array} props.bellNotifications - An array of notification objects to be displayed.
 *
 * @returns {JSX.Element} The rendered BellNotificationsBlock component.
 *
 * @example
 * // Example usage:
 * import React from "react";
 * import BellNotificationsBlock from "./BellNotificationsBlock";
 *
 * const notifications = [
 *	 { id: 1, notification: { body: "New message received" } },
 *	 { id: 2, notification: { body: "Friend request" } }
 * ];
 *
 * function App() {
 *	 return <BellNotificationsBlock bellNotifications={notifications} />;
 * }
 *
 * export default App;
 *
 * @returns {JSX.Element} The rendered BellNotificationsBlock component.
 */
const BellNotificationsBlock = ({ bellNotifications, markAsRead = () => {} }: BellNotificationsBlockProps) => {
	const theme = useTheme<IExtendedTheme>();
	const [expanded, setExpanded] = React.useState(false);

	const newItemsNumber = useMemo(() => bellNotifications.filter(n => !n.readAt).length, [bellNotifications]);

	return (
		<Tooltip title="Notifications">
			<IconButton
				size="small"
				onClick={() => {
					if (newItemsNumber) {
						markAsRead();
					}
					setExpanded(s => !s);
				}}
			>
				<Badge badgeContent={newItemsNumber} color="primary">
					<NotificationsIcon style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
				</Badge>
				{expanded && (
					<ClickAwayListener onClickAway={() => setExpanded(false)}>
						<List
							style={{
								position: "absolute",
								width: "400px",
								top: "55px",
								borderRadius: "4px",
								padding: "16px",
								background: theme.palette.background.paper,
								boxShadow: "0 0 12px rgba(27,31,35,.15)"
							}}
						>
							{bellNotifications.length === 0 && (
								<ListItem style={{ justifyContent: "center" }}>No notifications yet</ListItem>
							)}
							{bellNotifications.map(item => (
								<ListItem onClick={item.action} key={item.id}>
									{item.notification.body}
								</ListItem>
							))}
						</List>
					</ClickAwayListener>
				)}
			</IconButton>
		</Tooltip>
	);
};

export default BellNotificationsBlock;
