import { Box } from "@material-ui/core";

import styled from "styled-components";

export const PaymentContent = styled.div`
	width: ${({ width }) => width || "auto"};
	height: ${({ height }) => height || "auto"};
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-radius: 4px;
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	${props => props.theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
	}
`;

export const PaymentCardColumn = styled.div`
	flex: 424px 0 0;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.palette.background.paper};
	margin-top: 0;
	> div {
		box-shadow: none;
		background-color: transparent;
		padding-bottom: 15px;

		> .MuiTypography-root {
			font-size: ${props => props.theme.typography.pxToRem(26)};
			font-weight: bold;
			line-height: 1.23;
			margin-top: 10px;
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		flex: 300px 0 0;
		max-height: 380px;
	}
`;

export const PaymentOrderSummary = styled.div`
	flex: 1;
	background: ${({ theme }) => theme.palette.background.default};
	padding: 24px;
	display: flex;
	justify-content: start;
	gap: 24px;
	flex-flow: column;
`;

export const PaymentOrderHeader = styled.h1`
	font-size: ${props => props.theme.typography.pxToRem(22)};
	font-weight: bold;
	line-height: 1.45;
	margin: 20px 0;
	${props => props.theme.breakpoints.down("sm")} {
		margin: 0 0 15px 0;
	}
`;

export const BuyButtons = styled(Box)`
	display: flex;
	flex-flow: row nowrap;
	align-items: end;
	gap: 10px;
	justify-content: end;
	padding: 16px 24px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column-reverse;
		padding: 16px 24px;
		> button {
			width: 100%;
		}
	}
`;
export const PaymentAgreementRow = styled.div`
	padding: 0 24px 0 24px !important;
`;
