import React from "react";

import { Box, Card, Typography, useTheme } from "@material-ui/core";

import { ReactComponent as CheckMarkSvg } from "@remar/shared/dist/assets/icons/icon-checkmark.svg";
import { ReactComponent as CrossSvg } from "@remar/shared/dist/assets/icons/icon-cross.svg";
import Button from "@remar/shared/dist/components/Button";
import { UserCustomTest } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useAppDispatch } from "store";
import { IStudyGuide } from "store/features/Auth/auth.model";
import { setRecommendedMaterial } from "store/features/MyAccount/myAccountSlice";

import { QuizPercentage, QuizRecommendation } from "../Lesson/style";
import CircularProgressBar from "../QuestionBank/Charts/CircularProgress";

const LastCatExamCard = ({ lastCATTest, studyGuide }: { lastCATTest: UserCustomTest; studyGuide?: IStudyGuide }) => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useAppDispatch();
	const isCATPassed = lastCATTest.passed;
	return (
		<>
			<Card>
				<Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
					<Box width={"100%"}>
						<Typography>Your Last CAT Exam</Typography>
					</Box>
					<Box mt={4} display="flex" justifyContent="center" position={"relative"} width="150px">
						<Box>
							<CircularProgressBar
								strokeWidth={4}
								sqSize={150}
								percentage={100}
								hideIndicatorCircle
								stroke={isCATPassed ? theme.palette.colors.success[500] : theme.palette.colors.danger[500]}
							/>
						</Box>
						<QuizPercentage test={true} position="absolute" width="100px" top={"30px"} left={"27px"}>
							<Box mb={1}>{isCATPassed ? <CheckMarkSvg /> : <CrossSvg />}</Box>
							<Box textAlign={"center"}>{isCATPassed ? "Above Passing Standard" : "Below Passing Standard"}</Box>
						</QuizPercentage>
					</Box>
				</Box>
			</Card>
			{studyGuide && (
				<QuizRecommendation>
					<Typography>Need Help in doing better with Retake Test?</Typography>
					<Box mt={2}>
						<Button
							variant={"filled"}
							color={"infoLight"}
							onClick={() => {
								dispatch(setRecommendedMaterial(true));
							}}
						>
							View Recommended Material
						</Button>
					</Box>
				</QuizRecommendation>
			)}
		</>
	);
};

export default LastCatExamCard;
