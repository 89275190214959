import React, { useEffect, useMemo, useState } from "react";

import { Box, Chip, Drawer, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Book as BookType, Integrations } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState as getFullAuthState } from "store/features/Auth/authSlice";
import { getFullState as getFullBillingState } from "store/features/Billing/BillingSlice";
import {
	addToCart,
	emptyCart,
	fetchBooks,
	getFullState as getBooksFullState,
	purchaseBooks,
	removeFromCart
} from "store/features/Books/booksSlice";

import Book from "./Book";

import CartContent from "./CartContent";
import { CartWrapper, MobileCartSummaryWrapper } from "./styles";

import { BoldText, BookLabel, BooksWrapper, CourseNameWrapper, TotalWrapper } from "../Auth/components/styles";

import OneTimePayment from "../Billings/OneTimePayment";

const BOOKS_PER_PAGE = 10;
const ListInitialValue = {
	bookList: [],
	page: 1,
	perPage: BOOKS_PER_PAGE,
	totalItems: BOOKS_PER_PAGE
};
const Shop = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const [quantity] = useState(false);
	const [pay, setPay] = useState(false);
	const [toggleDrawer, setToggleDrawer] = useState(false);
	const { multipleShippingPlan, selectedShippingPlan } = useAppSelector(getFullAuthState);
	const { books, isFetchingBooks, cart, isPurchasingBooks } = useAppSelector(getBooksFullState);
	const { paymentMethods } = useAppSelector(getFullBillingState);

	useEffect(() => {
		if (!books) {
			dispatch(fetchBooks({ page: 1, perPage: BOOKS_PER_PAGE }));
		}
	}, [books]);

	const { bookList, page, perPage, totalItems } = useMemo(() => {
		if (!books) {
			return ListInitialValue;
		}
		return {
			bookList: books.items,
			page: books.page,
			perPage: books.perPage,
			totalItems: books.totalItems
		};
	}, [books]);

	const cartTotal: number = useMemo(
		() =>
			cart.reduce((previousValue, currentValue) => previousValue + currentValue.data.price * currentValue.quantity, 0),
		[cart]
	);
	const booksCountInCart = useMemo(
		() => cart.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0),
		[cart]
	);

	const shippingPlan = useMemo(() => {
		return booksCountInCart > 1 ? multipleShippingPlan : selectedShippingPlan;
	}, [booksCountInCart, multipleShippingPlan, selectedShippingPlan]);

	const shippingPrice = useMemo(() => shippingPlan?.data["price"] || 0, [shippingPlan?.data]);

	const handleAddBook = (book: BookType) => {
		dispatch(addToCart(book));
	};

	const handleRemoveBook = (book: BookType) => {
		dispatch(removeFromCart(book));
	};

	const handlePay = () => {
		dispatch(
			purchaseBooks({
				data: {
					paymentProviderPaymentMethodIdentifier: paymentMethods?.id ?? "",
					paymentProviderId: 1,
					books: cart.map(({ quantity, id }) => ({ quantity, bookId: id }))
				},
				cb: () => {
					dispatch(emptyCart());
					setPay(false);
				}
			})
		);
	};

	return (
		<Wrapper heading={"Shop"} position={"relative"}>
			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={8}>
						{isFetchingBooks ? (
							<ContentLoader />
						) : (
							<Box display={"flex"} style={{ flexFlow: "row wrap" }}>
								{bookList.map(book => (
									<Book key={book.id} {...book} addBook={() => handleAddBook(book)} />
								))}
							</Box>
						)}

						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) => dispatch(fetchBooks({ page: page, perPage: BOOKS_PER_PAGE }))}
							rowsPerPage={perPage}
							bgColor={theme.palette.background.default}
						/>
					</Grid>
					{!isMobile && (
						<Grid item xs={12} lg={4} style={{ position: "relative" }}>
							<CartWrapper>
								<Box>
									<Typography variant={"h6"}>Books Selected</Typography>
									<CartContent
										cart={cart}
										checkoutModal={() => setPay(true)}
										addBook={handleAddBook}
										removeBook={handleRemoveBook}
									/>
								</Box>
							</CartWrapper>
						</Grid>
					)}
				</Grid>
			</Box>
			{isMobile && (
				<>
					<MobileCartSummaryWrapper onClick={() => setToggleDrawer(true)}>
						<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
							<Box display={"flex"} alignItems={"center"}>
								<Box mr={0.5}>
									<Chip label={cart.length} />
								</Box>
								<Box mr={1}>
									<Typography>Items:</Typography>
								</Box>
								<Typography variant={"h6"}>{formatUSD(cartTotal)}</Typography>
							</Box>
							<Button variant={"filled"} color={"primary"} disabled={quantity} onClick={() => setToggleDrawer(true)}>
								View
							</Button>
						</Box>
					</MobileCartSummaryWrapper>
					<Drawer open={toggleDrawer} onClose={() => setToggleDrawer(false)} anchor={"bottom"}>
						<Box p={2}>
							<CartContent
								cart={cart}
								checkoutModal={() => setPay(true)}
								addBook={handleAddBook}
								removeBook={handleRemoveBook}
							/>
						</Box>
					</Drawer>
				</>
			)}

			{pay && (
				<OneTimePayment
					width={"800px"}
					open={true}
					handleClose={() => {
						setPay(false);
					}}
					handlePayment={handlePay}
					succeeded={false}
					successBody={null}
					failed={false}
					onTryAgain={() => console.log("try")}
					isLoading={isPurchasingBooks}
					orderSummary={
						<>
							<BooksWrapper>
								{cart?.map((item, i) => (
									<CourseNameWrapper key={i} p={1} mb={1} $spaceBetween>
										<Box display={"flex"}>
											{item.mainImageUrl && (
												<Box mr={2}>
													<img width="50px" src={item.mainImageUrl as string} alt="book name" />
												</Box>
											)}
											<Box display="flex" flexDirection={"column"} alignItems={"flex-start"}>
												<BoldText>{item.name}</BoldText>
												<BookLabel key={i} $isPhysical={item.integrationId !== Integrations.DigitalAssetIntegrationId}>
													{item.integrationId !== Integrations.DigitalAssetIntegrationId
														? "Physical Book"
														: "Digital book"}
												</BookLabel>
											</Box>
											<Box ml={1}>
												<Typography> x {item.quantity}</Typography>
											</Box>
										</Box>
										<Box>
											<Typography> {formatUSD(item.quantity * item.data.price)}</Typography>
										</Box>
									</CourseNameWrapper>
								))}
							</BooksWrapper>
							{shippingPrice > 0 && (
								<>
									<TotalWrapper>
										<Typography variant={"subtitle1"}>Subtotal</Typography>
										<Typography variant={"subtitle1"}> {formatUSD(cartTotal)}</Typography>
									</TotalWrapper>
									<TotalWrapper>
										<Typography variant={"caption"}>Shipping</Typography>
										<Typography variant={"caption"}> {formatUSD(shippingPrice)}</Typography>
									</TotalWrapper>
								</>
							)}
							<TotalWrapper>
								<Typography>Your Total</Typography>
								<Typography>{formatUSD(cartTotal + shippingPrice)}</Typography>
							</TotalWrapper>
						</>
					}
				/>
			)}
		</Wrapper>
	);
};

export default Shop;
