import { alpha, createGenerateClassName, makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "../../theme/default";

export const generateClassName = createGenerateClassName({
	disableGlobal: true,
	productionPrefix: "RemarModal",
	seed: "ReMarSimpleModalComponent"
});

export const useSimpleModalStyles = makeStyles(
	(theme: IExtendedTheme) => ({
		modal: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexWrap: "wrap",
			minHeight: "250px",
			margin: "auto",
			overflow: "auto"
		},
		deleteIcon: {
			background: "transparent"
		},
		card: {
			borderRadius: "5px"
		},
		title: {
			fontfamily: "Poppins",
			lineHeight: " 1.33",
			color: alpha(theme.palette.text.primary, 0.87),
			fontSize: "18px",
			fontWeight: 900
		},
		description: {
			fontfamily: "Poppins",
			color: alpha(theme.palette.text.primary, 0.6),
			fontSize: "16px",
			fontWeight: 500
		},
		closeIcon: {
			marginTop: "-15px",
			marginRight: "-15px",
			flex: "0 1"
		},
		subTitle: {
			color: alpha(theme.palette.text.primary, 0.6),
			marginBottom: "25px",
			textAlign: "center",
			"&>span": {
				color: "white",
				fontWeight: 500
			}
		},
		subTitleContainer: {
			margin: "auto",
			width: "80%"
		},
		modalHeader: {
			display: "flex",
			alignItems: "flex-start",
			marginBottom: "40px",
			justifyContent: "space-between"
		},
		modalFooter: {
			paddingTop: "25px",
			borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end"
		}
	}),
	{ name: "SimpleModal" }
);
