import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { Card } from "@material-ui/core";
import { videoOptions } from "@remar/shared/dist/config";
import { School } from "@remar/shared/dist/models";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";

import {
	closeTutorialModal,
	getFullState,
	getIntroLessonVideo,
	setPaymentDiscount,
	setUpgradeModal,
	updateSchool
} from "store/features/Auth/authSlice";

import { getSchools, selectSchoolList } from "store/features/Course/courseSlice";
import { schoolsService } from "store/services";
import videojs from "video.js";

import { routes } from "core/constants";

import ActNow from "./Modals/ActNow";
import Intro from "./Modals/Intro";
import PickSchool from "./Modals/PickSchool";
import { useStyles } from "./studentFormStyle";

const StudentForm = ({ closeModal }): React.ReactElement => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();

	const schools = useAppSelector(selectSchoolList);
	const { introVideo, isLoading, redirectToIntroVIT, user, introVideoNotFound, paymentDiscount } =
		useAppSelector(getFullState);

	useEffect(() => {
		dispatch(getSchools());
	}, [dispatch]);

	const videoRef = useRef(null);
	const videoPlayer = useRef<videojs.Player>();

	useEffect(() => {
		if (!introVideo) {
			dispatch(getIntroLessonVideo());
			return;
		}
		if (user!.schoolId && !user!.hasTakenIntro && !paymentDiscount) {
			videoPlayer.current =
				videoPlayer.current ||
				getVideoPlayer(videoRef!.current!, introVideo.videoUrl, {
					...videoOptions,
					muted: false,
					loop: true,
					autoplay: false,
					controls: false
				});
		}
	}, [dispatch, introVideo, user, paymentDiscount]);

	useEffect(() => {
		if (introVideoNotFound && user && user!.schoolId) {
			closeModal();
			return;
		}
	}, [closeModal, introVideoNotFound, user]);

	useEffect(() => {
		if (!introVideo || !redirectToIntroVIT) {
			return;
		}
		history.push(`${routes.lesson.getPath()}/${introVideo.interactiveBlocks![0].lessonId}/0`);
	}, [history, introVideo, redirectToIntroVIT]);

	const getSchoolId = useCallback(
		(name: string) => {
			const school = schools?.find(obj => obj.name.toLowerCase().trim() === name.toLowerCase().trim());
			return school?.id;
		},
		[schools]
	);

	const addInfo = useCallback(
		async (values: { school: string; timesTakenNCLEX: number; examDate: string }, schoolId?: number) => {
			const id = schoolId || getSchoolId(values.school);
			if (id) {
				dispatch(
					updateSchool({
						data: {
							schoolId: id,
							examInfo: {
								...(values.timesTakenNCLEX && { nclexTimesTaken: values.timesTakenNCLEX }),
								...(values.examDate && { dateOfExam: values.examDate })
							}
						},
						filters: { id: user!.id }
					})
				);
			}
		},
		[dispatch, getSchoolId, user]
	);

	const handleSchoolSubmit = useCallback(
		async values => {
			if (!getSchoolId(values.school)) {
				const res: School = await schoolsService.create({ name: values.school });
				await addInfo(values, res.id);
			} else {
				await addInfo(values);
			}
		},
		[addInfo, getSchoolId]
	);

	const handleIntroVITClick = useCallback(
		async (redirectToIntroVIT: boolean) => {
			await dispatch(closeTutorialModal(redirectToIntroVIT));
			closeModal();
		},
		[dispatch, closeModal]
	);
	const handleClaimActNowDeal = useCallback(() => {
		dispatch(setUpgradeModal(true));
	}, [dispatch]);

	const handleIgnoreDeal = useCallback(() => {
		dispatch(setPaymentDiscount(null));
		closeModal();
	}, [dispatch, closeModal]);
	const ModalContent = useMemo(() => {
		if (!user!.schoolId) {
			return <PickSchool classes={classes} isLoading={isLoading} handleSchoolSubmit={handleSchoolSubmit} />;
		} else if (paymentDiscount) {
			return <ActNow claimDeal={handleClaimActNowDeal} ignoreDeal={handleIgnoreDeal} />;
		} else if (!user!.hasTakenIntro && introVideo) {
			return (
				<Intro
					classes={classes}
					introVideo={introVideo}
					videoRef={videoRef}
					handleIntroVITClick={handleIntroVITClick}
				/>
			);
		}
		return null;
	}, [
		user,
		paymentDiscount,
		introVideo,
		classes,
		isLoading,
		handleSchoolSubmit,
		handleClaimActNowDeal,
		handleIgnoreDeal,
		handleIntroVITClick
	]);

	const CardClassName = useMemo(() => {
		if (!user!.schoolId) {
			return "";
		}
		if (paymentDiscount) {
			return " act-now-card";
		}
		if (!user!.hasTakenIntro) {
			return " intro-card";
		}
	}, [user, paymentDiscount]);

	return (
		<Card variant="outlined" className={`${classes.card}${CardClassName}`}>
			{ModalContent}
		</Card>
	);
};

export default StudentForm;
