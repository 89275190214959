import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";

import { BaseModel } from "@remar/shared/dist/models";

import { IGetLocationOpenSignupDetailsResponse } from "./dto";

import { URLS } from "../services.constants";

export class LocationPackageService extends BaseApiService<
	BaseModel,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<{}>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getLocationCourseInfo(locationId: number, courseId: number): Promise<IGetLocationOpenSignupDetailsResponse> {
		return request(
			`${this.baseUrl}/${locationId}/open-signup/${courseId}`,
			"GET"
		) as Promise<IGetLocationOpenSignupDetailsResponse>;
	}
}

export const locationService = new LocationPackageService(URLS.LOCATIONS);
