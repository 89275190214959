import { Button, alpha } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import styled from "styled-components";

import { ButtonProps } from "./types";

const getHeight = (size: ButtonProps["size"]) => {
	switch (size) {
		case "tiny":
			return "24px";
		case "small":
			return "32px";
		case "large":
			return "48px";
		case "giant":
			return "56px";
		default:
			return "40px";
	}
};

const getPadding = (size: ButtonProps["size"]) => {
	switch (size) {
		case "tiny":
			return "8px 11px";
		case "small":
			return "8px 14.5px";
		case "large":
			return "13px 18px";
		case "giant":
			return "15px 22px";
		default:
			return "11px 18.5px";
	}
};

export const BaseButton = styled(Button)`
	height: ${({ size }) => getHeight(size)};
	padding: ${({ size }) => getPadding(size)};
	font-weight: 700;
	border-radius: 4px;
`;

export const FilledButton = styled(BaseButton)`
	background: ${({ theme, color, disabled }) =>
		disabled ? theme.palette.colors.basic[750] : theme.palette[color || "primary"].main};
	& span {
		color: ${({ theme, color, disabled }) =>
			disabled ? theme.palette.colors.basic[300] : theme.palette[color || "primary"].text};
	}
	&:hover {
		background-color: ${({ theme, color }) => alpha(theme.palette[color || "primary"].main, 0.2)};
	}
`;

export const OutlinedButton = styled(BaseButton)`
	background: ${({ theme, color }) => alpha(theme.palette[color || "primary"].main, 0)};
	border: 1px solid ${({ theme, color }) => theme.palette[color || "primary"].main};
	color: ${({ theme, color }) => theme.palette[color || "primary"].main};
	box-sizing: border-box;
	&:hover {
		background-color: ${({ theme, color }) => alpha(theme.palette[color || "primary"].main, 0.2)};
	}
`;

export const LightButton = styled(BaseButton)`
	background: ${({ theme, color }) => theme.palette[color || "primary"].light};
	color: ${({ theme, color }) => theme.palette[color || "primary"].dark};

	&:hover {
		background: ${({ theme, color }) => darken(theme.palette[color || "primary"].light, 0.3)};
	}
`;

export const GhostButton = styled(BaseButton)`
	background: transparent;
	color: ${({ theme, color }) => theme.palette[color || "primary"].text};

	&:hover {
		background: ${({ theme, color }) => alpha(theme.palette[color || "primary"].light, 0.3)};
	}
`;
