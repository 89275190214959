import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { clearJwt, getJwt, setJwt } from "@remar/shared/dist/api/jwt";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import {
	BadgeInfo,
	BookExternalIntegrationDataItem,
	Country,
	Coupon,
	Course,
	IErrorResponseData,
	IPaymentDiscount,
	IValidateCouponResponse,
	IValidateCoupons,
	Integrations,
	LessonVideo,
	ShippingPlan,
	User,
	UserCustomTest,
	UserSubscription,
	UserSubscriptionType,
	UserSubscriptionTypeExternalIntegrationDataItem,
	UserSubscrptnType
} from "@remar/shared/dist/models";
import { handleStripePaymentConfirmation } from "@remar/shared/dist/utils/auth";
import { ThemeNames, convertThemeName } from "@remar/shared/dist/utils/convertThemeName";
import { pendingReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";
import * as Sentry from "@sentry/react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { differenceInDays, formatDistanceToNowStrict } from "date-fns";
import { AppThunk, RootState } from "store";

import {
	UserInvitationDto,
	UserInvitationResponseDto,
	UserLoginDto,
	UserLoginResponseDto,
	UserSocialSignUpDto,
	UserUpdateDto,
	countriesService,
	coursesService,
	genericService,
	lessonVideosService,
	userSubscriptionTypesService,
	usersService
} from "store/services";

import { IStudyGuide, IStudyGuideResponse, InactiveSubscription } from "./auth.model";
import { ERROR_MESSAGES, LAST_DAYS_IN_SUBSCRIPTION, UserSubscriptionTypeCategories } from "./constants";

import { getCouponScope } from "../../../utils/couponScope";
import { couponsService } from "../../services/coupons";

import { locationService } from "../../services/locations";
import { getSubscriptionInfo, resumeSubscription } from "../MyAccount/myAccountSlice";
import { setImageLogo, switchColorShade } from "../Theme/theme.slice";
import { emit, setBannerData } from "../notifications/notifications.slice";

interface AuthState {
	papCookie: string;
	token: string | null;
	user: User | null;
	subscription: {
		isTrial: boolean;
	};
	userLoading: boolean;
	countries: Country[];
	courses: Course[];
	course: Course | null;
	loadingCourse: boolean;
	inactiveSubscription: InactiveSubscription;
	subscriptionTypes: UserSubscrptnType[];
	loadingSubscriptionTypes: boolean;
	userSubscriptionTypeId: number;
	userSubscriptionTypeAddonIds?: number[];
	isLoading: boolean;
	externalIntegrations?: UserSubscriptionTypeExternalIntegrationDataItem[];
	subExternalIntegrations?: BookExternalIntegrationDataItem[];
	isLoggedIn: boolean;
	canAppendRedirectQueryParam: boolean;
	errorMessage: string;
	tryAgainEnabled: boolean;
	resendEmail: string;
	isPasswordResetLinkSent: boolean;
	passwordResetEmail: string;
	passwordReset: boolean;
	selectedCountryId?: number;
	selectedShippingPlan?: ShippingPlan;
	multipleShippingPlan?: ShippingPlan;
	shippingApplicable: boolean;
	introVideo?: LessonVideo;
	guestSignUpData?: {
		user?: Record<string, unknown>;
		paymentProviderAccount?: Record<string, unknown>;
	};
	redirectToIntroVIT?: boolean;
	introVideoNotFound?: boolean;
	invitationDetailsLoading: boolean;
	invitationDetails?: UserInvitationResponseDto | null;
	invitationError?: IErrorResponseData | null;
	canAccessCourse: boolean;
	canAccessQuiz: boolean;
	canAccessQuestionBank: boolean;
	userAccountStatus: string;
	isValidatingCoupon: boolean;
	invalidCoupon: boolean;
	validatedCoupon: IValidateCouponResponse | null;
	discountScope?: {
		signUp: boolean;
		renewals: boolean;
		books: boolean;
		shipping: boolean;
	} | null;
	paymentDiscount: IPaymentDiscount | null;
	badgeInfoErrorMessage: string;
	firebaseErrorMessage: string;
	upgradeModal?: boolean;
	selectSubModal: boolean;
	qbAvailableOfferings?: Array<UserSubscriptionType>;
	lastCATTest?: UserCustomTest;
	studyGuide?: IStudyGuide;
}

export interface SocialSignUpUser {
	accessToken: string;
	email: string;
	first_name: string;
	last_name: string;
	name: string;
}

const initialToken = getJwt();

const initialState: AuthState = {
	papCookie: "",
	token: initialToken || null,
	user: null,
	userLoading: true,
	subscription: {
		isTrial: false
	},
	inactiveSubscription: {
		isRenewEnabled: false
	},
	subscriptionTypes: [],
	loadingSubscriptionTypes: false,
	externalIntegrations: [],
	// RN With materials TODO: Will be dynamic afterwards
	userSubscriptionTypeId: 0,
	isLoading: false,
	canAppendRedirectQueryParam: true,
	isLoggedIn: !!initialToken,
	errorMessage: "",
	tryAgainEnabled: true,
	resendEmail: "",
	isPasswordResetLinkSent: false,
	passwordResetEmail: "",
	passwordReset: false,
	shippingApplicable: false,
	userSubscriptionTypeAddonIds: [],
	countries: [],
	guestSignUpData: {},
	courses: [],
	course: null,
	loadingCourse: false,
	invitationDetailsLoading: true,
	invitationDetails: null,
	invitationError: null,
	canAccessCourse: false,
	canAccessQuiz: false,
	canAccessQuestionBank: false,
	userAccountStatus: "",
	isValidatingCoupon: false,
	invalidCoupon: false,
	validatedCoupon: null,
	discountScope: null,
	paymentDiscount: null,
	badgeInfoErrorMessage: "",
	firebaseErrorMessage: "",
	upgradeModal: false
};

interface SignUpThunk {
	CardElement: typeof CardElement;
	guest?: boolean;
	accountClaimCode?: string;
	skipEmailVerification?: boolean;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	recaptchaGoogle: string;
	values;
	couponCode?: string;
}
interface SignUpInvitations {
	CardElement: typeof CardElement;
	elements: ReturnType<typeof useElements>;
	stripe: ReturnType<typeof useStripe>;
	skipPayment?: boolean;
	values;
}

interface SocialTrialSignUpThunk {
	values: UserSocialSignUpDto;
}

export const handleStripePayment = async (
	stripe: ReturnType<typeof useStripe>,
	cardElement: ReturnType<typeof useElements>
): Promise<PaymentMethod | undefined> => {
	if (stripe && cardElement) {
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement
		});

		if (error) {
			throw new Error("Please check your card details");
		}
		return paymentMethod;
	}
	throw new Error("Please check your card details");
};

export const signUp = createAsyncThunk(
	"auth/signUp",
	async (
		{
			skipEmailVerification,
			guest,
			accountClaimCode,
			CardElement,
			elements,
			stripe,
			recaptchaGoogle,
			couponCode,
			values: {
				address1,
				address2,
				city,
				countryId,
				email,
				firstName,
				fullName,
				lastName,
				password,
				phoneNumber,
				startDate,
				state,
				zip,
				zipCode,
				userPaymentAndDiscountId
			}
		}: SignUpThunk,
		{ getState, dispatch, rejectWithValue }
	) => {
		dispatch(setError(""));
		dispatch(setTryAgainEnabled(true));
		const {
			auth: {
				subExternalIntegrations,
				userSubscriptionTypeAddonIds,
				userSubscriptionTypeId,
				papCookie,
				user: stateUser
			}
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		let paymentMethod;
		if (stripe && cardElement) {
			paymentMethod = await handleStripePayment(stripe, cardElement);
		}
		// Sign up with payment
		if (!guest && !accountClaimCode && stripe && cardElement) {
			const signUpPayload = {
				paymentProviderId: 1,
				userTypeId: 2,
				userSubscriptionTypeId,
				userSubscriptionTypeAddonIds,
				firstName,
				lastName,
				email,
				startDate: startDate.toISOString(),
				password,
				paymentProviderPaymentMethodIdentifier: paymentMethod!.id,
				address: {
					phoneNumber,
					address1,
					address2,
					countryId,
					city,
					state,
					zipCode: zip
				},
				customerDescription: papCookie,
				recaptchaGoogle,
				couponCode,
				userPaymentAndDiscountId
			};

			if (stateUser?.managedLocations?.length) {
				signUpPayload["locationId"] = stateUser.managedLocations[0].id;
			}

			if (paymentMethod) {
				try {
					const user = stateUser?.managedLocations.length
						? await usersService.openInstitutionSignUp(signUpPayload)
						: await usersService.signUp(signUpPayload);

					if (user.paymentNeedsConfirmation) {
						const { success, error } = await handleStripePaymentConfirmation(
							stripe!,
							user!.subscriptionPaymentIntentClientSecret!
						);
						if (!success && error) {
							dispatch(setTryAgainEnabled(false));
							return rejectWithValue(error);
						}
					}
					return user;
				} catch (e) {
					return rejectWithValue(e.message);
				}
			}
		}
		// handle guest sign up
		else if (guest && stripe && cardElement) {
			if (paymentMethod) {
				const payload = {
					paymentProviderId: 1,
					paymentProviderPaymentMethodIdentifier: paymentMethod.id,
					firstName,
					lastName,
					userTypeId: 3,
					email: email,
					books: subExternalIntegrations?.map(({ id, quantity }) => ({ id, quantity })),
					address: {
						fullName,
						phoneNumber,
						address1,
						address2,
						countryId,
						city,
						state,
						zipCode
					},
					customerDescription: papCookie,
					recaptchaGoogle,
					couponCode
				};
				try {
					const user = await usersService.guestCheckout(payload);
					if (user.paymentNeedsConfirmation) {
						const { success, error } = await handleStripePaymentConfirmation(
							stripe!,
							user!.subscriptionPaymentIntentClientSecret!
						);
						if (!success && error) {
							dispatch(setTryAgainEnabled(false));
							return rejectWithValue(error);
						}
					}
					return user;
				} catch (e) {
					return rejectWithValue(e.message);
				}
			}
		} else if (accountClaimCode) {
			let paymentProviderPaymentMethodIdentifier = "";
			if (stripe && cardElement) {
				paymentProviderPaymentMethodIdentifier = paymentMethod!.id;
			}
			const payload = {
				accountClaimCode,
				triggerEmailVerfication: !skipEmailVerification, // todo <- fix typo "triggerEmailVerfication" -> "triggerEmailVerification
				userSubscriptionTypeId,
				userTypeId: 2,
				password,
				...(paymentProviderPaymentMethodIdentifier && {
					paymentProviderId: 1,
					paymentProviderPaymentMethodIdentifier
				}),
				recaptchaGoogle,
				couponCode
			};
			return usersService.guestSignUp(payload).catch(e => rejectWithValue(e.message));
		} else {
			// Sign Up for trial
			const signUpBody = {
				firstName,
				lastName,
				email,
				password,
				paymentProviderId: 1,
				userSubscriptionTypeId,
				userTypeId: 2,
				recaptchaGoogle,
				couponCode
			};

			return usersService.signUp(signUpBody).catch(e => rejectWithValue(e.message));
		}
	}
);
export const getInvitationDetails = createAsyncThunk(
	"auth/getInvitationDetails",
	async (options: UserInvitationDto, { rejectWithValue, dispatch }) => {
		try {
			const { inviteCode } = options;
			dispatch(setStateValue({ key: "invitationDetailsLoading", value: true }));
			const invitation = await usersService.getInvitationDetails({ inviteCode });
			dispatch(setStateValue({ key: "invitationDetails", value: invitation }));

			if (invitation.theme) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>invitation.theme.name)));
				dispatch(setImageLogo(invitation.logoImageUrl));
			}

			return invitation;
		} catch (e) {
			if ((e.statusCode = 422)) {
				dispatch(setStateValue({ key: "invitationError", value: e }));
				return rejectWithValue(e.message);
			}
			dispatch(emit({ message: "Failed to fetch invitation details", color: "error" }));
			return rejectWithValue("Failed to fetch invitation details");
		} finally {
			dispatch(setStateValue({ key: "invitationDetailsLoading", value: false }));
		}
	}
);
export const studentSignUpInvitation = createAsyncThunk(
	"auth/studentSignUpInvitation",
	async ({ CardElement, elements, skipPayment, stripe, values }: SignUpInvitations, { dispatch, rejectWithValue }) => {
		try {
			dispatch(setIsLoading(true));
			let paymentMethod;
			if (!skipPayment) {
				const cardElement = elements?.getElement(CardElement);
				paymentMethod = await handleStripePayment(stripe, cardElement);
			}
			const {
				firstName,
				lastName,
				password,
				phoneNumber,
				state,
				city,
				countryId,
				zip,
				address1,
				address2,
				invitationId,
				execludedSubTypeEIDItemIds
			} = values;
			const body = {
				inviteCode: invitationId,
				paymentProviderId: 1,
				paymentProviderPaymentMethodIdentifier: skipPayment ? undefined : paymentMethod!.id,
				firstName: firstName,
				lastName: lastName,
				password: password,
				execludedSubTypeEIDItemIds,
				address: {
					fullName: `${firstName} ${lastName}`,
					phoneNumber: phoneNumber,
					address1: address1,
					address2: address2,
					countryId: countryId,
					city: city,
					state: state,
					zipCode: zip
				}
			};
			try {
				const res = await usersService.studentSignUpInvitation(body);
				if (res!.paymentNeedsConfirmation) {
					const { success, error } = await handleStripePaymentConfirmation(
						stripe!,
						res!.subscriptionPaymentIntentClientSecret as string
					);
					if (!success && error) {
						throw new Error("Your payment did not succeed. Please try again");
					}
				}
				return res;
			} catch (e) {
				return rejectWithValue(e.message);
			}
		} finally {
			dispatch(setIsLoading(false));
		}
	}
);

export const validateDuplicateEmail = createAsyncThunk(
	"auth/validateduplicateemail",
	(email: string, { rejectWithValue }) => {
		return usersService
			.validateDuplicateEmail(email)
			.then(({ isValid, message }) => {
				if (!isValid) {
					throw new Error(message);
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const getIntroLessonVideo = createAsyncThunk(
	"auth/getIntroLessonVideo",
	async (_, { dispatch, rejectWithValue }) => {
		dispatch(setIsLoading(true));
		try {
			const { items } = await lessonVideosService.find({
				filters: { "interactiveBlocks.lesson.isIntro": true },
				perPage: 1
			});
			return items[0];
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const resendVerificationEmail = createAsyncThunk(
	"auth/resendverificationemail",
	(email: string, { dispatch, rejectWithValue }) => {
		dispatch(setIsLoading(true));
		return usersService
			.resendEmailVerification(email)
			.then(({ isValid, message }) => {
				if (!isValid) {
					throw new Error(message);
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);
const handleResumeSubscription = (dispatch, activatedSubs) => {
	dispatch(
		resumeSubscription({
			id: activatedSubs.id,
			sideEffect: () => {
				dispatch(getUserData());
				dispatch(getSubscriptionInfo());
				dispatch(
					setBannerData({
						showBanner: false,
						expiresIn: null,
						isTrial: false,
						isActionLoading: false
					})
				);
			}
		})
	);
};
const updateBannerData = (_user, dispatch) => {
	const activatedSubs = getCurrentSubscription(_user.subscriptions);
	if (activatedSubs?.type?.isTrial) {
		dispatch(
			setBannerData({
				showBanner: true,
				isTrial: true,
				bannerMessage: "You are in trial mode. Click 𝐇𝐞𝐫𝐞 to upgrade your subscription."
			})
		);
	} else {
		if (activatedSubs?.isCancelled) {
			const expiresIn = differenceInDays(new Date(activatedSubs.expiresOn), new Date());
			const hideBannerForUser = Boolean(localStorage.getItem(`hide_banner_user_${_user?.id.toString()}`));
			const bannerExpiration = formatDistanceToNowStrict(new Date(activatedSubs.expiresOn));
			dispatch(
				setBannerData({
					showBanner: hideBannerForUser ? false : Number(expiresIn) <= LAST_DAYS_IN_SUBSCRIPTION,
					expiresIn: bannerExpiration,
					isTrial: true,
					bannerMessage: `Your account will turn back to trial in ${bannerExpiration}. Click to resume your subscription.`,
					action: () => {
						handleResumeSubscription(dispatch, activatedSubs);
					}
				})
			);
		} else {
			dispatch(
				setBannerData({
					showBanner: false,
					expiresIn: null,
					isTrial: false
				})
			);
		}
	}
};
export const signIn = createAsyncThunk("auth/signIn", async (user: UserLoginDto, { rejectWithValue, dispatch }) => {
	const res = await usersService.login(user).catch(error => {
		let email = "";
		let errorMessage = error.message;

		if (error.message === ERROR_MESSAGES.EMAIL_VERIFY_BUTTON_DISPLAY) {
			email = user.email;
			errorMessage = "We sent you an email to verify, Do you want a ";
		}
		dispatch(setResendEmail(email));
		return rejectWithValue(errorMessage);
	});
	Sentry.setUser({
		username: user.email
	});
	const _user = (res as UserLoginResponseDto).user;
	if (_user) {
		const _redirect_url = _user.redirectUrl as string;
		const shouldRedirect = !!_redirect_url;
		if (shouldRedirect) {
			window.location.replace(_redirect_url);
			return;
		}
		const { allowedLocations } = _user;
		if (allowedLocations && allowedLocations.length > 0 && allowedLocations[0].theme) {
			dispatch(switchColorShade(convertThemeName(<ThemeNames>allowedLocations[0]?.theme.name)));
			allowedLocations[0]?.logoImage && dispatch(setImageLogo(allowedLocations[0]?.logoImageUrl));
		}
		updateBannerData(_user, dispatch);
	}
	return res;
});

export const signInByToken = createAsyncThunk(
	"auth/signInByToken",
	async (user: { sessionId: string; sideEffect?: () => void }, { rejectWithValue, dispatch }) => {
		const { sideEffect, ...restUserData } = user;
		setIsLoading(true);
		const res = await usersService.loginByToken(restUserData).catch(error => {
			const errorMessage = error.message;
			return rejectWithValue(errorMessage);
		});
		const _user = (res as UserLoginResponseDto)?.user;
		if (_user) {
			const { allowedLocations } = _user;
			if (allowedLocations && allowedLocations.length > 0 && allowedLocations[0].theme) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>allowedLocations[0]?.theme.name)));
				allowedLocations[0]?.logoImage && dispatch(setImageLogo(allowedLocations[0]?.logoImageUrl));
			}
			updateBannerData(_user, dispatch);
		}
		sideEffect && sideEffect();
		return res;
	}
);

export const setSubscriptionTypesForSignup = createAsyncThunk(
	"auth/setSubscriptionTypesForSignup",
	async ({ courseId, isTrial }: { courseId?: number; isTrial?: boolean }, { rejectWithValue, dispatch }) => {
		const filters = {
			isRecurring: false,
			isActive: true,
			userSubscriptionTypeCategoryId: [
				UserSubscriptionTypeCategories.Course,
				UserSubscriptionTypeCategories.QuestionBankOnly,
				UserSubscriptionTypeCategories.LocationPerSeat
			]
		};
		if (courseId) {
			filters["allowedCourses.id"] = courseId;
		}
		if (typeof isTrial !== "undefined") {
			filters["isTrial"] = isTrial;
		}
		userSubscriptionTypesService
			.find({ filters, findAll: true, include: ["subTypeEIDItems.counterparts", "allowedCourses"] })
			.then(({ items }) => dispatch(setSubscriptionTypes(items!)))
			.catch(error => rejectWithValue(error.message));
	}
);

export const fetchCountries = createAsyncThunk(
	"auth/fetchCountries",
	async (subscriptionTypeId: number, { rejectWithValue }) => {
		return await countriesService
			.find({
				...(subscriptionTypeId && { filters: { "shippingPlans.subscriptionTypes.id": subscriptionTypeId } }),
				orderBy: { name: "ASC" },
				findAll: true
			})
			.catch(rejectWithValue);
	},
	{
		condition: (_, { getState }) => {
			const { auth } = getState() as RootState;
			return !auth.isLoading;
		}
	}
);

export const getCourses = createAsyncThunk("auth/getCourses", async (_, { rejectWithValue }) => {
	return coursesService
		.getCourses({
			filters: { "allowedForUST.isActive": true },
			findAll: true
		})
		.catch(error => rejectWithValue(error.message));
});

export const getCourse = createAsyncThunk(
	"auth/getCourse",
	async (
		{ id, isInstitutionalStudent = false }: { id: number; isInstitutionalStudent?: boolean },
		{ rejectWithValue }
	) => {
		const filters = {
			"allowedForUST.isActive": true,
			id
		};
		if (isInstitutionalStudent) {
			filters["allowedForUST.userSubscriptionTypeCategoryId"] = UserSubscriptionTypeCategoriesEnum.LocationPerSeat;
		}
		return coursesService
			.getCourses({
				filters: filters,
				findAll: true
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const fetchExternalIntegrationDataItems = createAsyncThunk(
	"auth/externalIntegrationDataItems",
	async (bookId: number, { rejectWithValue }): Promise<unknown> =>
		genericService.getBooks().catch(error => rejectWithValue(error.message))
);

export const forgotPassword = createAsyncThunk(
	"auth/forgotPassword",
	async (email: string, { dispatch, rejectWithValue }) =>
		await usersService
			.forgotPassword(email)
			.then(() => {
				dispatch(setPasswordResetEmail(email));
			})
			.catch(e => rejectWithValue(e.message))
);

export const guestSignUpVerification = createAsyncThunk(
	"auth/guestSignUpVerification",
	async (
		{ accountClaimCode, sideEffect = () => {} }: { accountClaimCode: string; sideEffect: () => void },
		{ rejectWithValue }
	) => {
		return await usersService.guestSignUpVerification(accountClaimCode).catch(e => {
			sideEffect();
			return rejectWithValue(e.message);
		});
	}
);

export const forgotVerification = createAsyncThunk(
	"auth/forgotVerification",
	async (
		{ code, sideEffect = () => {} }: { code: string; sideEffect?: () => void },
		{ rejectWithValue }
	): Promise<unknown> =>
		await usersService.forgotVerification(code).catch(e => {
			sideEffect();
			return rejectWithValue(e.message);
		})
);

export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	async (
		{
			code,
			password,
			successSideEffect
		}: { code: string; password: string; successSideEffect: (code: string, hasToken: boolean) => void },
		{ rejectWithValue, dispatch }
	) => {
		return await usersService
			.resetPassword({ code, password })
			.then(r => {
				if (r.sessionToken && r.refreshToken) {
					setJwt(r.sessionToken, "", r.refreshToken);
					dispatch(getUserData());
					dispatch(setStateValue({ key: "isLoggedIn", value: true }));
					successSideEffect && successSideEffect(code, true);
				} else {
					successSideEffect && successSideEffect(code, false);
				}
				return r;
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const closeTutorialModal = createAsyncThunk(
	"auth/closeTutorialModal",
	async (redirectToIntroVIT: boolean, { rejectWithValue }) => {
		try {
			await usersService.update({ data: { hasTakenIntro: true }, filters: {} });
			return redirectToIntroVIT;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const facebookLogin = createAsyncThunk(
	"auth/facebookLogin",
	async (
		{
			facebookData,
			sideEffect = () => {},
			successSideEffect
		}: { facebookData: SocialSignUpUser; sideEffect: () => void; successSideEffect },
		{ rejectWithValue, dispatch }
	) => {
		try {
			const tokenId = facebookData.accessToken;
			const res = await usersService.facebookLogin(tokenId);
			if (successSideEffect) {
				successSideEffect(facebookData.email, "facebook");
			}
			Sentry.setUser({
				username: facebookData.email
			});
			updateBannerData(res.user, dispatch);
			return res;
		} catch (e) {
			if (e.message === "User does not exists") {
				sessionStorage.setItem("firstName", facebookData.first_name);
				sessionStorage.setItem("lastName", facebookData.last_name);
				sessionStorage.setItem("email", facebookData.email);
				sessionStorage.setItem("tokenId", facebookData.accessToken);
				sessionStorage.setItem("platform", "facebook");
				sessionStorage.setItem("analyticsId", `${facebookData.email}-facebook`);
				sideEffect();
			}
			return rejectWithValue(e.message);
		}
	}
);

export const googleLogin = createAsyncThunk(
	"auth/googleLogin",
	async (
		{
			googleUser,
			sideEffect = () => {},
			successSideEffect
		}: {
			googleUser: SocialSignUpUser;
			sideEffect: () => void;
			successSideEffect;
		},
		{ rejectWithValue, dispatch }
	) => {
		try {
			const tokenId = googleUser.accessToken;
			const res = await usersService.googleLogin(tokenId);
			if (successSideEffect) {
				successSideEffect(googleUser.email, "google");
			}
			Sentry.setUser({
				username: googleUser.email
			});
			updateBannerData(res.user, dispatch);
			return res;
		} catch (e) {
			if (e.message === "User does not exists") {
				sessionStorage.setItem("firstName", googleUser.first_name);
				sessionStorage.setItem("lastName", googleUser.last_name);
				sessionStorage.setItem("email", googleUser.email);
				sessionStorage.setItem("tokenId", googleUser.accessToken);
				sessionStorage.setItem("platform", "google");
				sessionStorage.setItem("analyticsId", `${googleUser.email}-google`);
				sideEffect();
			}
			return rejectWithValue(e.message);
		}
	}
);
const mapSignUpBody = (userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values) => ({
	paymentProviderId: 1,
	userTypeId: 2,
	userSubscriptionTypeId,
	userSubscriptionTypeAddonIds,
	firstName: values.firstName || sessionStorage.getItem("firstName"),
	lastName: values.lastName || sessionStorage.getItem("lastName"),
	email: values.email || sessionStorage.getItem("email"),
	startDate: values.startDate.toISOString(),
	paymentProviderPaymentMethodIdentifier: paymentMethod!.id,
	tokenId: values.tokenId || sessionStorage.getItem("tokenId"),
	address: {
		phoneNumber: values.phoneNumber,
		address1: values.address1,
		address2: values.address2,
		countryId: values.countryId,
		city: values.city,
		state: values.state,
		zipCode: values.zip
	}
});
export const facebookSignUp = createAsyncThunk(
	"auth/facebookSignUp",
	async (
		{ CardElement, elements, stripe, values }: SignUpThunk,
		{ getState, dispatch, rejectWithValue }
	): Promise<User | unknown | void> => {
		dispatch(setError(""));
		dispatch(setTryAgainEnabled(true));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;

		const cardElement = elements?.getElement(CardElement);
		const paymentMethod = await handleStripePayment(stripe, cardElement);

		const signUpBody = mapSignUpBody(userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values);

		if (paymentMethod) {
			const user = await usersService.facebookSignUp(signUpBody);
			if (user.paymentNeedsConfirmation) {
				const { success, error } = await handleStripePaymentConfirmation(
					stripe!,
					user!.subscriptionPaymentIntentClientSecret!
				);
				if (!success && error) {
					dispatch(setTryAgainEnabled(false));
					return rejectWithValue(error);
				}
			}

			return user;
		}
	}
);

export const googleSignUp = createAsyncThunk(
	"auth/googleSignUp",
	async (
		{ CardElement, elements, stripe, values }: SignUpThunk,
		{ getState, dispatch, rejectWithValue }
	): Promise<User | unknown | void> => {
		dispatch(setError(""));
		dispatch(setTryAgainEnabled(true));
		const {
			auth: { userSubscriptionTypeAddonIds, userSubscriptionTypeId }
		} = getState() as RootState;
		const cardElement = elements?.getElement(CardElement);
		const paymentMethod = await handleStripePayment(stripe, cardElement);

		const signUpBody = mapSignUpBody(userSubscriptionTypeId, userSubscriptionTypeAddonIds, paymentMethod, values);
		if (paymentMethod) {
			const user = await usersService.googleSignUp(signUpBody);
			if (user.paymentNeedsConfirmation) {
				const { success, error } = await handleStripePaymentConfirmation(
					stripe!,
					user!.subscriptionPaymentIntentClientSecret!
				);
				if (!success && error) {
					dispatch(setTryAgainEnabled(false));
					return rejectWithValue(error);
				}
			}
			return user;
		}
	}
);

export const socialTrialSignUp = createAsyncThunk(
	"auth/socialTrialSignUp",
	async ({ values: { firstName, lastName } }: SocialTrialSignUpThunk, { getState, dispatch }): Promise<User | void> => {
		dispatch(setError(""));
		dispatch(setTryAgainEnabled(true));
		const {
			auth: { userSubscriptionTypeId }
		} = getState() as RootState;
		const signUpBody = {
			userTypeId: 2,
			userSubscriptionTypeId,
			paymentProviderId: 1,
			firstName,
			lastName,
			email: sessionStorage.getItem("email") as string,
			tokenId: sessionStorage.getItem("tokenId") as string
		};
		return sessionStorage.getItem("platform") === "facebook"
			? await usersService.facebookSignUp(signUpBody)
			: await usersService.googleSignUp(signUpBody);
	}
);
export const getUserData = createAsyncThunk("auth/getUserData", async (_, { dispatch, rejectWithValue }) => {
	return usersService
		.whoami()
		.then(({ user }) => {
			dispatch(setUser({ user }));

			if (user.allowedLocations?.length) {
				dispatch(switchColorShade(convertThemeName(<ThemeNames>user.allowedLocations[0]?.theme?.name)));
				user?.allowedLocations[0]?.logoImage && dispatch(setImageLogo(user?.allowedLocations[0]?.logoImageUrl));
			}

			Sentry.setUser({
				username: user.email
			});
			const activatedSubs = getCurrentSubscription(user.subscriptions);
			let expiresIn: string | null = null;
			let showBanner = false;
			let isTrial = false;
			let bannerMessage = "";
			let action: (() => void) | undefined = undefined;
			if (activatedSubs.type?.isTrial) {
				showBanner = true;
				bannerMessage = "You are in trial mode. Click 𝐇𝐞𝐫𝐞 to upgrade your subscription.";
				isTrial = true;
				dispatch(setBannerData({ isTrial, showBanner, bannerMessage }));
				dispatch(setIsTrial(true));
				const isRenewEnabled = user.isRenewEnabled as boolean;
				const expiredSubscriptions = user!.expiredSubscriptions;
				const expiredSubscription = [...expiredSubscriptions!]
					.sort((a, b) => b.id - a.id)
					.find(i => !i.type?.isTrial && !i.type?.isRecurring);
				dispatch(
					setInactiveSubscription({
						isRenewEnabled,
						nextUserSubscriptionType: user.nextUserSubscriptionType,
						subscriptionId: isRenewEnabled ? expiredSubscription?.id : 0,
						typeId: isRenewEnabled ? expiredSubscription?.typeId : 0
					})
				);
			} else {
				const activeSub = activatedSubs;
				if (activeSub.isCancelled) {
					expiresIn = formatDistanceToNowStrict(new Date(activeSub.expiresOn));
					bannerMessage = `Your account will turn back to trial in ${expiresIn}. Click to resume your subscription.`;
					const _expiresIn = differenceInDays(new Date(activeSub.expiresOn), new Date());
					const hideBannerForUser = Boolean(localStorage.getItem(`hide_banner_user_${user.id.toString()}`));
					showBanner = hideBannerForUser ? false : Number(_expiresIn) <= LAST_DAYS_IN_SUBSCRIPTION;
					action = () => {
						handleResumeSubscription(dispatch, activatedSubs);
					};
				}
				dispatch(setInactiveSubscription({ isRenewEnabled: false }));
			}
			if (!!bannerMessage) {
				dispatch(setBannerData({ expiresIn, showBanner, isTrial, bannerMessage, action }));
			}
			dispatch(setIsTrial(isTrial));
			dispatch(getBadgeInfo());
			return user;
		})
		.catch(error => {
			dispatch(clearAuth());
			return rejectWithValue(error.message);
		});
});

export const getBadgeInfo = createAsyncThunk("auth/getBadgeInfo", async (_, { rejectWithValue }) => {
	return await usersService.getUserBadgeInfo().catch(rejectWithValue);
});

export const confirmAcceptedTermsAndConditions = createAsyncThunk(
	"auth/confirmacceptedTermsAndConditions",
	async (isAgreed: boolean, { rejectWithValue }) => {
		return await usersService.confirmAcceptedTermsAndConditions(isAgreed).catch(rejectWithValue);
	}
);

export const subscribeFirebaseNotifications = createAsyncThunk(
	"auth/subscribeFirebaseNotifications",
	async (token: string, { rejectWithValue }) => {
		return await usersService.subscribeFirebaseNotifications(token).catch(rejectWithValue);
	}
);

const getCourseId = (state: AuthState) => {
	const {
		inactiveSubscription: { isRenewEnabled, nextUserSubscriptionType },
		subscriptionTypes,
		userSubscriptionTypeId
	} = state;
	const activeSubscription = isRenewEnabled
		? nextUserSubscriptionType
		: subscriptionTypes?.find(us => us.id === userSubscriptionTypeId);
	return activeSubscription?.allowedCourses?.length ? activeSubscription.allowedCourses[0].id : 0;
};

const signInReducer = (
	state: AuthState,
	{ payload: { refreshToken, sessionToken, user, badges } }: PayloadAction<UserLoginResponseDto>
) => {
	state.papCookie = "";
	state.errorMessage = "";
	state.isLoading = false;
	state.isLoggedIn = true;
	state.user = user;
	if (badges) {
		state.user["badges"] = badges;
	}
	state.canAccessCourse = user.canAccessCourse;
	state.canAccessQuiz = user.canAccessQuiz;
	state.canAccessQuestionBank = user.canAccessQuestionBank;
	state.token = sessionToken;
	state.canAppendRedirectQueryParam = true;
	setJwt(sessionToken, "", refreshToken as string);
	const activatedSubs = getCurrentSubscription(user.subscriptions);
	if (activatedSubs?.type?.isTrial) {
		state.subscription.isTrial = true;
		const isRenewEnabled = user.isRenewEnabled as boolean;
		const expiredSubscriptions = user!.expiredSubscriptions;
		const expiredSubscription = [...expiredSubscriptions!]
			.sort((a, b) => b.id - a.id)
			.find(i => !i.type?.isTrial && !i.type?.isRecurring);
		state.inactiveSubscription = {
			isRenewEnabled,
			nextUserSubscriptionType: user.nextUserSubscriptionType,
			subscriptionId: isRenewEnabled ? expiredSubscription?.id : 0,
			typeId: isRenewEnabled ? expiredSubscription?.typeId : 0
		};
	} else {
		if (!activatedSubs?.isCancelled) {
			state.subscription.isTrial = false;
		}
	}
};

export const validateCoupon = createAsyncThunk(
	"auth/validateCoupon",
	async (data: IValidateCoupons, { rejectWithValue }) => {
		return await couponsService.validateCoupon(data).catch(e => rejectWithValue(e.message));
	}
);
export const updateSchool = createAsyncThunk(
	"auth/updateSchool",
	async (data: UserUpdateDto, { rejectWithValue, dispatch }) => {
		return await usersService
			.update(data)
			.then(res => {
				const { schoolId, paymentAndDiscount } = (res as { raw: User[] }).raw[0];
				dispatch(setSchoolId(schoolId));
				dispatch(setPaymentDiscount(paymentAndDiscount));
			})
			.catch(e => {
				rejectWithValue(e.message);
				dispatch(emit({ message: "Something goes wrong when exam data update", color: "error" }));
			});
	}
);

export const getLocationOpenSignupDetails = createAsyncThunk(
	"auth/getLocationOpenSignupDetails",
	async ({ locationId, courseId }: { locationId: number; courseId: number }, { rejectWithValue }) => {
		return await locationService.getLocationCourseInfo(locationId, courseId).catch(e => rejectWithValue(e.message));
	}
);

export const getQbNextSubscriptions = createAsyncThunk("auth/getQbNextSubscription", async (_, { rejectWithValue }) => {
	return await usersService.getQbNextSubscriptions().catch(e => rejectWithValue(e.message));
});

export const getUserStudyGuide = createAsyncThunk(
	"auth/getUserStudyGuide",
	async ({ cb }: { cb?: (res: IStudyGuideResponse) => void }, { rejectWithValue }) => {
		const res = await usersService.getStudyGuide({}).catch(e => rejectWithValue(e.message));
		cb && cb(res as IStudyGuideResponse);
		return res;
	}
);

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		hideBannerOnCancelSub: state => {
			const currentSub = getCurrentSubscription(state.user?.subscriptions);
			if (currentSub && currentSub.isCancelled) {
				localStorage.setItem(`hide_banner_user_${state.user!.id.toString()}`, "true");
			}
		},
		setSelectedCountry: (state, { payload }: PayloadAction<number>) => {
			state.selectedCountryId = payload;
			const selectedCountry = state.countries?.find(({ id }) => id == payload);
			const { shippingPlans } = selectedCountry || {};
			const getCheapestPlan = plans =>
				plans?.find(sp => sp.freeShipping) ||
				plans?.reduce((prev, curr) => (prev.data.price < curr.data.price ? prev : curr));
			const singleShippingPlans = shippingPlans?.filter(sp => !sp.multipleItems);
			const multipleShippingPlans = shippingPlans?.filter(sp => sp.multipleItems);
			state.selectedShippingPlan = getCheapestPlan(singleShippingPlans);
			state.multipleShippingPlan = getCheapestPlan(multipleShippingPlans);
		},
		clearAuth: state => {
			state.canAppendRedirectQueryParam = false;
			state.isLoggedIn = false;
			state.token = null;
			state.user = null;
		},
		setUser: (state, { payload: { user } }: PayloadAction<{ user: User }>) => {
			state.user = { ...state.user, ...user };
		},
		setPapCookie: (state, { payload }: PayloadAction<string>) => {
			state.papCookie = payload;
		},
		setSchoolId: (state, { payload }: PayloadAction<number>) => {
			state.user = { ...state.user, schoolId: payload } as User;
		},
		setPaymentDiscount: (state, { payload }: PayloadAction<IPaymentDiscount>) => {
			state.paymentDiscount = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.errorMessage = payload;
		},
		setTryAgainEnabled: (state, { payload }: PayloadAction<boolean>) => {
			state.tryAgainEnabled = payload;
		},
		changeSubscriptionType: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeId = payload;
		},
		addUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number>) => {
			if (!state.userSubscriptionTypeAddonIds?.includes(payload)) {
				state.userSubscriptionTypeAddonIds?.push(payload);
			}
		},
		setInitUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number[]>) => {
			state.userSubscriptionTypeAddonIds = [];
			for (let index = 0; index < payload.length; index++) {
				// todo: refactor
				state.userSubscriptionTypeAddonIds.push(payload[index]);
			}
		},
		removeUserSubscriptionTypeAddonIds: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeAddonIds = state.userSubscriptionTypeAddonIds?.filter(v => v !== payload);
		},
		removeBookFromInvitationData: (state, { payload }: PayloadAction<number>) => {
			const index = state.invitationDetails.subscriptionData.subTypeEIDItems.findIndex(
				({ integrationId, parentId }) => integrationId === Integrations.StripeIntegrationId && parentId === null
			);

			if (index > -1) {
				state.invitationDetails.subscriptionData.subTypeEIDItems[index].children =
					state.invitationDetails.subscriptionData.subTypeEIDItems[index].children.filter(v => v.id !== payload);
			}
		},
		setSubscriptionTypes: (state, { payload }: PayloadAction<UserSubscrptnType[]>) => {
			state.subscriptionTypes = payload;
		},
		setShippingApplicable: (state, { payload }: PayloadAction<boolean>) => {
			state.shippingApplicable = payload;
		},
		// eslint-disable-next-line no-unused-vars
		addSubscriptionIntegrations: (
			state,
			{ payload: { index, quantity } }: PayloadAction<{ index: number; id: number; quantity: number }>
		) => {
			state.subExternalIntegrations![index].quantity = quantity;
		},
		additionSubscriptionIntegrations: (state, { payload }: PayloadAction<number>) => {
			const index = state.subExternalIntegrations?.findIndex(({ id }) => id === payload) as number;

			if (index > -1) {
				state.subExternalIntegrations![index].quantity = (state.subExternalIntegrations![index].quantity as number) + 1;
			} else {
				const newSubIntegration = state.externalIntegrations?.find(
					({ id }) => id === payload
				) as UserSubscriptionTypeExternalIntegrationDataItem;
				const { id, data, mainImageUrl } = newSubIntegration;
				state.subExternalIntegrations?.push({ id, data, mainImageUrl, quantity: 1 });
			}
		},

		changeSubscribedIntegrations: (state, { payload }: PayloadAction<number>) => {
			const newSubIntegration = state.externalIntegrations?.find(({ id }) => id === payload);
			const { id, data, mainImageUrl } = newSubIntegration as UserSubscriptionTypeExternalIntegrationDataItem;
			state.subExternalIntegrations = [{ id, data, mainImageUrl, quantity: 1 }];
		},

		removeSubscriptionIntegrations: (state, { payload }: PayloadAction<number>) => {
			state.subExternalIntegrations = state.subExternalIntegrations?.filter(({ id }) => id != payload);
		},

		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload;
		},
		setUserLoading: (state, action: PayloadAction<boolean>) => {
			state.userLoading = action.payload;
		},
		setResendEmail: (state, { payload }: PayloadAction<string>) => {
			state.resendEmail = payload;
		},
		setInactiveSubscription: (state, { payload }: PayloadAction<InactiveSubscription>) => {
			state.inactiveSubscription = payload;
		},
		setPasswordResetEmail: (state, { payload }: PayloadAction<string>) => {
			state.passwordResetEmail = payload;
		},
		clearForgotPassword: state => {
			state.isPasswordResetLinkSent = false;
			state.passwordResetEmail = "";
			state.errorMessage = "";
		},
		clearPasswordReset: state => {
			state.passwordReset = false;
			state.errorMessage = "";
		},
		setUserSubscriptionTypeId: (state, { payload }: PayloadAction<number>) => {
			state.userSubscriptionTypeId = payload;
		},
		resetCoupon: state => {
			state.validatedCoupon = null;
			state.discountScope = null;
			state.invalidCoupon = false;
		},
		setIsTrial: (state, { payload }: PayloadAction<boolean>) => {
			state.subscription.isTrial = payload;
		},
		signInWithTokenFromResponse: (state, action: PayloadAction<UserLoginResponseDto>) => {
			signInReducer(state as AuthState, action);
		},
		applyValidatedCoupon: (state, { payload }: PayloadAction<Coupon>) => {
			state.validatedCoupon = {
				...state.validatedCoupon,
				coupon: payload
			} as IValidateCouponResponse;
			const courseId = getCourseId(state as AuthState);
			state.discountScope = getCouponScope(payload, courseId);
		},
		setUpgradeModal: (state, { payload }: PayloadAction<boolean>) => {
			state.upgradeModal = payload;
		},
		setSelectSubModal: (state, { payload }: PayloadAction<boolean>) => {
			state.selectSubModal = payload;
		},
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		// Sign Up flow
		[signUp.pending.type]: state => {
			state.isLoading = true;
		},
		[signUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[signUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Social Trial Sign Up flow
		[socialTrialSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[socialTrialSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[socialTrialSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Facebook Sign Up flow
		[facebookSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[facebookSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[facebookSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Google Sign Up flow
		[googleSignUp.pending.type]: state => {
			state.isLoading = true;
		},
		[googleSignUp.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[googleSignUp.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		// Sign In flow
		[signIn.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[signIn.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[signIn.fulfilled.type]: signInReducer,
		// Sign In by token flow
		[signInByToken.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[signInByToken.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[signInByToken.fulfilled.type]: signInReducer,
		// Facebook Sign In flow
		[facebookLogin.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[facebookLogin.rejected.type]: state => {
			state.isLoading = false;
		},
		[facebookLogin.fulfilled.type]: signInReducer,
		// Google Sign In flow
		[googleLogin.pending.type]: state => {
			state.isLoading = true;
			//Reset fetched subscription types
			state.subscriptionTypes = [];
		},
		[googleLogin.rejected.type]: state => {
			state.isLoading = false;
		},
		[googleLogin.fulfilled.type]: signInReducer,
		// Invitation Sign Up flow
		[studentSignUpInvitation.pending.type]: state => pendingReducer(state),
		[studentSignUpInvitation.fulfilled.type]: state => {
			state.isLoading = false;
		},
		// Forgot Password Flow
		[forgotPassword.pending.type]: state => {
			state.isLoading = true;
		},
		[forgotPassword.fulfilled.type]: state => {
			state.isPasswordResetLinkSent = true;
			state.isLoading = false;
		},
		[forgotPassword.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		[guestSignUpVerification.pending.type]: state => {
			state.isLoading = true;
		},
		[guestSignUpVerification.fulfilled.type]: (state, { payload }) => {
			state.guestSignUpData = payload;
			state.isLoading = false;
		},
		[guestSignUpVerification.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		// Forgot Password Link Verification Flow
		[forgotVerification.pending.type]: state => {
			state.isLoading = true;
		},
		[forgotVerification.fulfilled.type]: (state, { payload }) => {
			state.passwordResetEmail = payload?.email;
			state.isLoading = false;
		},
		[forgotVerification.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		// Reset Password Flow
		[resetPassword.pending.type]: state => {
			state.isLoading = true;
		},
		[resetPassword.fulfilled.type]: state => {
			state.passwordReset = true;
			state.isLoading = false;
		},
		[resetPassword.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[fetchCountries.pending.type]: state => {
			state.isLoading = true;
			// Reset shippingPlan state
			state.selectedCountryId = undefined;
			state.selectedShippingPlan = undefined;
		},
		[fetchCountries.fulfilled.type]: (
			state,
			{ payload: { items: countries } }: PayloadAction<FindResponse<Country>>
		) => {
			const USA = countries.find(({ code }) => code === "US")!;
			if (USA) {
				countries.unshift(USA);
			}
			state.countries = countries;
			state.isLoading = false;
		},
		[fetchCountries.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.errorMessage = message;
			state.isLoading = false;
		},
		[getCourses.pending.type]: state => {
			state.isLoading = true;
		},
		[getCourses.fulfilled.type]: (state, { payload }) => {
			const filteredCourses = payload.items.filter(({ allowedForUST }) => allowedForUST?.length);
			state.courses = filteredCourses as Course[];
			state.isLoading = false;
		},
		[getCourses.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getCourse.pending.type]: state => {
			state.loadingCourse = true;
		},
		[getCourse.fulfilled.type]: (state, { payload }) => {
			state.course = payload.items[0];
			state.subscriptionTypes = (payload.items[0].allowedForUST || []).filter(ust => ust.isActive && !ust.isTrial);
			state.loadingCourse = false;
		},
		[getCourse.rejected.type]: state => {
			state.loadingCourse = false;
		},

		[fetchExternalIntegrationDataItems.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchExternalIntegrationDataItems.fulfilled.type]: (state, { meta, payload }) => {
			const bookId = meta.arg;
			const book = payload.find(({ id }) => id === bookId);
			state.externalIntegrations = payload;
			if (book) {
				const { id, data, mainImageUrl } = book;
				state.subExternalIntegrations = [{ id, data, mainImageUrl, quantity: 1 }];
			} else {
				state.subExternalIntegrations = [];
			}
			state.isLoading = false;
		},
		[fetchExternalIntegrationDataItems.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},

		[getIntroLessonVideo.fulfilled.type]: (state, { payload }) => {
			state.errorMessage = "";
			state.introVideo = payload;
			if (!state.introVideo) {
				state.introVideoNotFound = true;
			}
			state.isLoading = false;
		},
		[getIntroLessonVideo.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[closeTutorialModal.fulfilled.type]: (state, { payload }) => {
			state.errorMessage = "";
			state.redirectToIntroVIT = payload;
			state.user!.hasTakenIntro = true;
		},
		[closeTutorialModal.rejected.type]: (state, action) => {
			state.errorMessage = action.payload;
		},
		[getUserData.pending.type]: state => {
			state.userLoading = true;
		},
		[getUserData.rejected.type]: state => {
			state.userLoading = false;
		},
		[getUserData.fulfilled.type]: (state, action) => {
			const { accountStatus, canAccessCourse, canAccessQuiz, canAccessQuestionBank } = action.payload;
			state.userAccountStatus = accountStatus?.label ?? "";
			state.canAccessCourse = canAccessCourse;
			state.canAccessQuiz = canAccessQuiz;
			state.canAccessQuestionBank = canAccessQuestionBank;
			state.userLoading = false;
		},
		[confirmAcceptedTermsAndConditions.pending.type]: state => {
			state.isLoading = true;
		},
		[confirmAcceptedTermsAndConditions.fulfilled.type]: (state, { payload: { acceptedTermsAndConditions } }) => {
			state.isLoading = false;
			state.user!.acceptedTermsAndConditions = acceptedTermsAndConditions;
		},
		[confirmAcceptedTermsAndConditions.rejected.type]: (state, { payload: { message } }) => {
			state.errorMessage = message;
			state.isLoading = false;
		},
		[validateCoupon.pending.type]: state => {
			state.isValidatingCoupon = true;
			state.invalidCoupon = false;
			state.validatedCoupon = null;
			state.discountScope = null;
		},
		[validateCoupon.fulfilled.type]: (state, { payload }) => {
			state.isValidatingCoupon = false;
			if (!payload.discountedAmount) {
				state.invalidCoupon = true;
				state.validatedCoupon = null;
				state.discountScope = null;
				return;
			}
			state.invalidCoupon = false;

			const coupon = payload.coupon || payload.paymentAndDiscount;

			state.validatedCoupon = {
				...payload,
				coupon
			};
			const courseId = getCourseId(state as AuthState);
			state.discountScope = getCouponScope(coupon, courseId);
		},
		[validateCoupon.rejected.type]: state => {
			state.isValidatingCoupon = false;
			state.invalidCoupon = true;
			state.validatedCoupon = null;
			state.discountScope = null;
		},
		[studentSignUpInvitation.rejected.type]: (state, { payload }) => {
			state.invitationError = {
				message: payload
			};
		},
		[getBadgeInfo.pending.type]: state => {
			state.isLoading = true;
		},
		[getBadgeInfo.fulfilled.type]: (state, { payload }: PayloadAction<BadgeInfo>) => {
			state.user!.badgeInfo = payload;
			state.isLoading = false;
		},
		[getBadgeInfo.rejected.type]: (state, { payload }) => {
			state.badgeInfoErrorMessage = payload.message;
			state.isLoading = false;
		},
		[subscribeFirebaseNotifications.pending.type]: state => {
			state.isLoading = true;
		},
		[subscribeFirebaseNotifications.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[subscribeFirebaseNotifications.rejected.type]: (state, { payload }: PayloadAction<Error>) => {
			state.firebaseErrorMessage = payload.message;
			state.isLoading = false;
		},
		[getLocationOpenSignupDetails.pending.type]: state => {
			state.isLoading = true;
		},
		[getLocationOpenSignupDetails.fulfilled.type]: (state, { payload }) => {
			state.course = payload.course;
			state.isLoading = false;
			state.subscriptionTypes = [payload.subscriptionData];
			state.subscription = payload.subscriptionData;
			state.user = { managedLocations: [{ ...payload.location }] } as User;
		},
		[getLocationOpenSignupDetails.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getQbNextSubscriptions.pending.type]: state => {
			state.isLoading = true;
		},
		[getQbNextSubscriptions.fulfilled.type]: (state, { payload }) => {
			state.upgradeModal = true;
			state.qbAvailableOfferings = payload;
			state.isLoading = false;
		},
		[getQbNextSubscriptions.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getUserStudyGuide.fulfilled.type]: (state, action) => {
			state.lastCATTest = action.payload.test;
			state.studyGuide = action.payload.studyGuide;
		}
	}
});

export const {
	applyValidatedCoupon,
	hideBannerOnCancelSub,
	setSelectedCountry,
	clearAuth,
	setUser,
	setPapCookie,
	setSchoolId,
	setPaymentDiscount,
	changeSubscriptionType,
	addUserSubscriptionTypeAddonIds,
	additionSubscriptionIntegrations,
	removeSubscriptionIntegrations,
	changeSubscribedIntegrations,
	addSubscriptionIntegrations,
	removeUserSubscriptionTypeAddonIds,
	removeBookFromInvitationData,
	setInitUserSubscriptionTypeAddonIds,
	setSubscriptionTypes,
	setShippingApplicable,
	setError,
	setIsLoading,
	setUserLoading,
	setUpgradeModal,
	setSelectSubModal,
	signInWithTokenFromResponse,
	setResendEmail,
	setInactiveSubscription,
	setPasswordResetEmail,
	setTryAgainEnabled,
	setUserSubscriptionTypeId,
	clearForgotPassword,
	clearPasswordReset,
	setStateValue,
	setUpgradeQbSubscription,
	resetCoupon,
	setIsTrial
} = authSlice.actions;

export const logout = (): AppThunk => dispatch => {
	usersService
		.logout()
		.then(() => {
			clearJwt();
			dispatch(clearAuth());
			localStorage.clear();
		})
		.catch(() => {
			console.log("Error logging out");
		});
};

export const selectAuth = (
	state: RootState
): {
	errorMessage: string;
	isLoading: boolean;
	isLoggedIn: boolean;
	isPasswordResetLinkSent: boolean;
	passwordResetEmail: string;
	passwordReset: boolean;
} => {
	const { errorMessage, isLoading, isLoggedIn, isPasswordResetLinkSent, passwordResetEmail, passwordReset } =
		state.auth;
	return {
		errorMessage,
		isLoading,
		isLoggedIn,
		isPasswordResetLinkSent,
		passwordResetEmail,
		passwordReset
	};
};

export const getFullState = (state: RootState): AuthState => state.auth;
export const selectCurrentlyAllowedTrialCourses = (state: RootState): Course[] | undefined =>
	state.auth.user?.currentlyAllowedTrialCourses;
export const selectCurrentlyAllowedFullCourses = (state: RootState): Course[] | undefined =>
	state.auth.user?.currentlyAllowedFullCourses;
export const selectSubscriptions = (state: RootState): UserSubscription[] | undefined => state.auth.user?.subscriptions;
export const selectUser = (state: RootState): User | null => state.auth.user;
export const selectIsTrialUser = (state: RootState): boolean => !!state.auth.user?.trialAccess;
export const selectUserId = (state: RootState): number => state.auth.user?.id as number;
export const selectResendEmail = (state: RootState): string => state.auth.resendEmail;
export const selectIsTrial = (state: RootState): boolean | undefined => state.auth.subscription.isTrial;
export const selectInactiveSubscription = (state: RootState): InactiveSubscription => state.auth.inactiveSubscription;
export const selectCourse = (state: RootState): Course => state.auth.course;
export const selectLogedIn = (state: RootState): boolean => state.auth.isLoggedIn;
export const selectLoadingCourse = (state: RootState): Course => state.auth.loadingCourse;

export default authSlice.reducer;
