import { Country } from "@remar/shared/dist/models";
import { passwordMatch } from "@remar/shared/dist/schemas";

import { validateZipCode } from "@remar/shared/dist/utils/validateZipCode";
import * as Yup from "yup";

const AFRICAN_COUNTRY_CODES = [
	"DZ", // Algeria
	"AO", // Angola
	"BJ", // Benin
	"BW", // Botswana
	"BF", // Burkina Faso
	"BI", // Burundi
	"CV", // Cape Verde
	"CM", // Cameroon
	"CF", // Central African Republic
	"TD", // Chad
	"KM", // Comoros
	"CG", // Congo - Brazzaville
	"CD", // Congo - Kinshasa
	"CI", // Côte d’Ivoire
	"DJ", // Djibouti
	"EG", // Egypt
	"GQ", // Equatorial Guinea
	"ER", // Eritrea
	"SZ", // Eswatini
	"ET", // Ethiopia
	"GA", // Gabon
	"GM", // Gambia
	"GH", // Ghana
	"GN", // Guinea
	"GW", // Guinea-Bissau
	"KE", // Kenya
	"LS", // Lesotho
	"LR", // Liberia
	"LY", // Libya
	"MG", // Madagascar
	"MW", // Malawi
	"ML", // Mali
	"MR", // Mauritania
	"MU", // Mauritius
	"MA", // Morocco
	"MZ", // Mozambique
	"NA", // Namibia
	"NE", // Niger
	"NG", // Nigeria
	"RW", // Rwanda
	"ST", // São Tomé and Príncipe
	"SN", // Senegal
	"SC", // Seychelles
	"SL", // Sierra Leone
	"SO", // Somalia
	"ZA", // South Africa
	"SS", // South Sudan
	"SD", // Sudan
	"TZ", // Tanzania
	"TG", // Togo
	"TN", // Tunisia
	"UG", // Uganda
	"ZM", // Zambia
	"ZW" // Zimbabwe
];
export const ALLOWED_COUNTRIES_FOR_SHIPPING = ["US", ...AFRICAN_COUNTRY_CODES];
export const PO_BOX_ERROR_MESSAGE = "Enter a physical address, we won’t be able to ship your books to a PO box address";

export const SignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: passwordMatch().required("Required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required"),
	terms: Yup.bool()
	// we might nedd it in future
	// terms: Yup.bool().oneOf([true], "You should accept these terms to sign up")
});
export const payedSignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: passwordMatch().required("Required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required")
});

export const SocialTrialSignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	terms: Yup.bool()
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ShippingSchema = (hasPhysicalBooks: boolean, countries: Array<Country>) => {
	return Yup.object().shape({
		countryId: Yup.number().required("Required"),
		address1: Yup.string()
			.required("Required")
			.test("validate-po-box", PO_BOX_ERROR_MESSAGE, function (v) {
				const { countryId } = this.parent;
				const country = countries.find(c => c.id === countryId);
				if (!ALLOWED_COUNTRIES_FOR_SHIPPING.includes(country!.code) && hasPhysicalBooks) {
					return !/p\.?o\.?\s?box/i.test(v);
				}
				return true;
			}),
		city: Yup.string().min(2).required("Required"),
		state: Yup.string().min(2).required("Required"),
		zip: validateZipCode(Yup).max(10).required("Required"),
		phoneNumber: Yup.string()
			.max(20, "Phone number must be at most 20 characters")
			.min(7, "Phone number must be at least 7 characters")
			.required("Required")
			.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
	});
};

export const PaymentSchema = Yup.object().shape({
	nameOnCard: Yup.string().required("Required"),
	validCardDetails: Yup.bool().required("Required").equals([true]),
	terms: Yup.bool().oneOf([true], "You should accept these terms to sign up")
});

export const GuestShippingSchema = Yup.object().shape({
	firstName: Yup.string().min(1, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(1, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	countryId: Yup.number().required("Required"),
	address1: Yup.string().required("Required"),
	city: Yup.string().min(2).required("Required"),
	state: Yup.string().min(2).required("Required"),
	zipCode: validateZipCode(Yup).max(10).required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.required("Required")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});

export const AccountSchema = Yup.object().shape({
	firstName: Yup.string().required("required"),
	lastName: Yup.string().required("required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: passwordMatch().required("Required")
});
