import React from "react";

import { BoxProps } from "@material-ui/core";

import ContentLoader from "./ContentLoader";

import HeaderContainer, { HeaderWrapperProps } from "../../../components/HeaderContainer/HeaderContainer";
import { Container, RowWrapper } from "../styles";

export type WrapperProps = HeaderWrapperProps &
	BoxProps & {
		infoBlock?: React.ReactNode;
		showLoader?: boolean;
		hideTable?: boolean;
		restContent?: React.ReactNode;
	};
/**
 * A React Functional Component that serves as a wrapper for various UI elements.
 *
 * @param {React.ReactNode} children - The content to be displayed within the wrapper.
 * @param {string} heading - The heading text to be displayed in the header.
 * @param {any} breadcrumb - The breadcrumb navigation to be displayed.
 * @param {any} actions - The actions to be displayed in the header.
 * @param {React.ReactNode} infoBlock - Additional informational content to be displayed.
 * @param {boolean} [showLoader=false] - Determines whether to show a loading indicator.
 * @param {boolean} [hideTable=false] - Determines whether to hide the table content.
 * @param {React.ReactNode} restContent - Additional content to be displayed below the main content.
 * @param {any} customActionStyle - Custom styles for the action elements.
 * @param {object} rest - Additional properties to be passed to the child container component.
 *
 * @example
 *
 * Here's how to use the `Wrapper` component:
 *
 * ```tsx
 * import React from "react";
 * import Wrapper from "./Wrapper";
 * import { Button, Typography } from "@material-ui/core";
 *
 * const MyComponent: React.FC = () => (
 *	 <Wrapper
 *		 heading="My Heading"
 *		 breadcrumb={[
 *		 	{ title: "Dashboard", key: 0, link: "/" },
 *		 	{ title: "Path", key: 1, link: "/path" },
 *		 	{ title: "Path", key: 2, onClick: () => doAction() },
 *		 	{ title: "Path", key: 3 },
 *		 ]}
 *		 actions={<Button>Click Me</Button>}
 *		 infoBlock={<Typography variant="body1">Some additional information</Typography>}
 *		 showLoader={false}
 *		 hideTable={false}
 *		 restContent={<div>Additional content goes here</div>}
 *		 customActionStyle={{ color: 'red' }}
 *	 >
 *		 <div>Main Content</div>
 *	 </Wrapper>
 * );
 *
 * export default MyComponent;
 * ```
 */
const Wrapper: React.FC<WrapperProps> = ({
	children,
	heading,
	breadcrumb,
	actions,
	infoBlock,
	showLoader = false,
	hideTable = false,
	restContent,
	customActionStyle,
	...rest
}) => (
	<Container {...rest}>
		<HeaderContainer
			heading={heading}
			breadcrumb={breadcrumb}
			actions={actions}
			customActionStyle={customActionStyle}
		/>
		{infoBlock && <RowWrapper $isFlex>{infoBlock}</RowWrapper>}
		{!hideTable && (
			<RowWrapper mr={1.3} mt={1}>
				{showLoader ? <ContentLoader /> : children}
			</RowWrapper>
		)}
		{restContent}
	</Container>
);

export default Wrapper;
