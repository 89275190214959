import React, { useEffect, useMemo, useState } from "react";

import { Box, Grid, Modal } from "@material-ui/core";

import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";

import Button from "@remar/shared/dist/components/Button";
import CourseCompletionSummary from "@remar/shared/dist/components/CourseCompletion/CourseCompletionSummary";
import { useCourseCompletionDetails } from "@remar/shared/dist/components/CourseCompletion/useCourseCompletionDetails";
import RemarReadyBadgeProgress from "@remar/shared/dist/components/RemarReadyBadgeProgress";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { UserBadgeTypeEnum } from "@remar/shared/dist/models";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	fetchCountries,
	getFullState,
	getUserData,
	selectInactiveSubscription,
	selectIsTrial,
	selectUser,
	setSelectSubModal,
	setUpgradeModal
} from "store/features/Auth/authSlice";

import {
	changePassword as changePasswordSlice,
	editMyAccountDetails,
	fetchCourseCompletion,
	getSubscriptionInfo,
	getUserInfo,
	selectFullState,
	selectMyAccountIsLoading,
	selectSubscriptionInfo,
	selectUserInfo,
	setChangeDisplaySubscriptionDateModal
} from "store/features/MyAccount/myAccountSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";
import { UserChangePasswordDto } from "store/services/users/dto/users.changePassword.dto";

import { routes } from "core/constants";

import ChangePassword from "./ChangePassword";

import EditProfile from "./EditProfile";
import EditProfilePicture from "./EditProfilePicture";
import LastCatExamCard from "./LastCATExamCard";
import MyAccountCard from "./MyAccountCard";
import ResumeAndRenewSubscriptionButton from "./ResumeAndRenewSubscriptionButton";
import SubscriptionCard from "./SubscriptionCard";

import { useStyles } from "./styles";

import { useLoadTrialCourses } from "../../hooks/useLoadTrialCourses";

const MyAccount = () => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(selectUserInfo);
	const { badgeInfo, canAccessCourse, currentlyAllowedFullCourses } = useAppSelector(selectUser);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const isLoading = useAppSelector(selectMyAccountIsLoading);
	const { countries, lastCATTest, studyGuide } = useAppSelector(getFullState);
	const [editProfileModal, setEditProfileModal] = useState(false);
	const [changePwdModal, setChangePwdModal] = useState(false);
	const [editProfilePictureModal, setEditProfilePictureModal] = useState(false);
	const analytics = useAnalyticsEventTracker("User");
	const trial = useAppSelector(selectIsTrial);
	const { bannerData } = useAppSelector(getFullNotificationsState);
	const { chapters } = useLoadTrialCourses();
	const { courseCompletion, isLoadingCourseCompletion } = useAppSelector(selectFullState);
	const { nextLesson } = useCourseCompletionDetails(
		courseCompletion ? { ...courseCompletion, course: { chapters } } : null
	);

	useEffect(() => {
		dispatch(getUserInfo());
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchCourseCompletion());
	}, [dispatch]);

	useEffect(() => {
		if (!countries?.length && !isLoading) {
			dispatch(fetchCountries(0));
		}
	}, [countries?.length, isLoading, dispatch]);

	const passwordChangeSuccessSideEffect = () => {
		analytics({ eventName: "password-change" });
	};
	const editProfileSuccessSideEffect = () => {
		analytics({ eventName: "profile-edit" });
	};

	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);
	const editProfileInitialValues = {
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
		phoneNumber: userInfo?.phoneNumber
	};

	const badge = useMemo(
		() => userInfo?.badges?.find(({ typeId }) => typeId === UserBadgeTypeEnum.ReMarReadyBadge),
		[userInfo?.badges]
	);

	const showBadge = useMemo(() => {
		if (!badgeInfo) {
			return false;
		}
		const { catPassed, isCourseComplete, timeSpentTraining, timeSpentRequired } = badgeInfo;
		return catPassed && isCourseComplete && timeSpentTraining >= timeSpentRequired;
	}, [badgeInfo]);

	if (isLoading || isLoadingCourseCompletion) {
		return <ContentLoader />;
	}

	return (
		<Wrapper
			heading="My Account"
			actions={
				<Box display="flex" justifyContent="space-between" gridGap="16px">
					{!trial && !subscriptionInfo?.subscription.isCancelled && (
						<Button
							variant="filled"
							color={"primary"}
							onClick={() => {
								dispatch(setSelectSubModal(true));
							}}
						>
							Manage Subscription
						</Button>
					)}
					{trial && !isRenewEnabled ? (
						<Button
							variant="filled"
							color={"primary"}
							onClick={() => {
								if (bannerData?.coupon) {
									dispatch(applyValidatedCoupon(bannerData?.coupon));
								}
								dispatch(setUpgradeModal(true));
							}}
						>
							{!!bannerData?.coupon && (
								<Box display={"flex"} mr={1}>
									<DiscountIcon />
								</Box>
							)}
							Upgrade subscription
						</Button>
					) : (
						(subscriptionInfo?.subscription.isCancelled || isRenewEnabled) && (
							<ResumeAndRenewSubscriptionButton
								id={subscriptionInfo?.subscription?.id as number}
								sideEffect={() => {
									dispatch(getUserData());
									dispatch(getSubscriptionInfo());
								}}
							/>
						)
					)}
					<Button variant={"filled"} color={"primary"} onClick={() => setEditProfileModal(true)}>
						Edit Profile
					</Button>
				</Box>
			}
		>
			<Grid container spacing={2} className={classes.root}>
				<Grid item md={12} lg={9} container spacing={2}>
					<Grid item sm={12}>
						<MyAccountCard
							setEditProfilePictureModal={setEditProfilePictureModal}
							profilePictureUrl={userInfo?.profileImageUrl}
							firstName={userInfo?.firstName}
							lastName={userInfo?.lastName}
							email={userInfo?.email}
							schoolName={userInfo?.schoolName}
							phoneNumber={userInfo?.phoneNumber}
							address={userInfo?.shippingDetails}
							showBadge={showBadge}
							counties={countries}
						/>
					</Grid>
					{canAccessCourse && !!currentlyAllowedFullCourses?.length && (
						<Grid item sm={12} container spacing={1}>
							<Grid item md={12} lg={4}>
								<RemarReadyBadgeProgress {...badgeInfo} receivedOn={badge?.createdAt} />
							</Grid>
							{courseCompletion && (
								<Grid item md={12} lg={8}>
									<CourseCompletionSummary
										courseCompletion={courseCompletion}
										courseName={subscriptionInfo?.subscription?.subscriptionName as string}
										nextLesson={nextLesson}
										seeDetails={() => history.push(`${routes.myAccount.getPath()}/courseCompletion`)}
										onContinueNextLesson={() =>
											history.push(
												`${routes.lesson.getPath()}/${courseCompletion?.nextLessonSections[0].lesson?.id}/${
													courseCompletion?.nextLessonSections[0].sectionId
												}`
											)
										}
									/>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
				<Grid item sm={12} lg={3}>
					<SubscriptionCard
						id={subscriptionInfo?.subscription.id}
						isCancelled={subscriptionInfo?.subscription.isCancelled}
						isPaused={subscriptionInfo?.subscription.isPaused}
						subscriptionName={subscriptionInfo?.subscription?.subscriptionName}
						expiresOn={subscriptionInfo?.subscription?.expiresOn}
						billingDate={subscriptionInfo?.upcomingInvoice?.billingDate}
						price={!subscriptionInfo?.subscription?.type?.isTrial && subscriptionInfo?.upcomingInvoice?.price}
						subscriptionStartDate={subscriptionInfo?.subscription?.data?.subscriptionStartDate}
						changeSubscription={() => dispatch(setChangeDisplaySubscriptionDateModal(true))}
					/>
					{lastCATTest && (
						<Box mt={2}>
							<LastCatExamCard lastCATTest={lastCATTest} studyGuide={studyGuide} />
						</Box>
					)}
				</Grid>
			</Grid>

			<Modal
				open={editProfileModal}
				onClose={() => {
					setEditProfileModal(false);
				}}
			>
				<EditProfile
					handleSubmit={(values: UserUpdateDataDto) =>
						dispatch(
							editMyAccountDetails({
								data: values,
								sideEffect: () => setEditProfileModal(false),
								successMsg: "User Updated Successfully",
								successSideEffect: editProfileSuccessSideEffect
							})
						)
					}
					openPwdModal={setChangePwdModal}
					initialFormValues={editProfileInitialValues}
					onClose={() => setEditProfileModal(false)}
				/>
			</Modal>
			<Modal
				open={changePwdModal}
				onClose={() => {
					setChangePwdModal(false);
				}}
			>
				<ChangePassword
					handleSubmit={(values: UserChangePasswordDto) => {
						const { ...rest } = values;
						delete rest.confirmPassword;
						dispatch(
							changePasswordSlice({
								...rest,
								sideEffect: () => setChangePwdModal(false),
								successSideEffect: passwordChangeSuccessSideEffect
							})
						);
					}}
					onClose={() => setChangePwdModal(false)}
				/>
			</Modal>
			<Modal
				open={editProfilePictureModal}
				onClose={() => {
					setEditProfilePictureModal(false);
				}}
			>
				<EditProfilePicture onClose={() => setEditProfilePictureModal(false)} />
			</Modal>
		</Wrapper>
	);
};

export default MyAccount;
