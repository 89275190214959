import { Box, Chip, TextField as MuiTextField, Typography, makeStyles } from "@material-ui/core";

import { alpha } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	discussionBoard: {
		width: "100%",
		marginTop: "20px !important",
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		marginBlock: "16px",
		borderRadius: "6px",
		padding: "10px",
		"&:before": {
			height: 0
		}
	},
	commentPost: {
		padding: "8px 16px 5px 16px",

		[theme.breakpoints.down("sm")]: {
			padding: "8px 16px 5px 16px"
		},

		"& a": {
			textDecoration: "underline"
		}
	},
	commentThumbUp: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${props["totalLike"] === 0 ? "#586179" : props["isLiked"] && props["totalLike"] > 0 ? "#4a99f9" : "#898f9e"}`
	},
	commentThumbDown: {
		width: "0.6em",
		height: "0.6em",
		marginLeft: "4px",
		cursor: "pointer",
		fill: props =>
			`${
				props["totalDislike"] === 0
					? "#586179"
					: props["isDisliked"] && props["totalDislike"] > 0
					? "#4a99f9"
					: "#898f9e"
			}`
	},
	ratingDisabled: {
		fill: theme.palette.colors.basic[700],
		cursor: "default"
	},
	selectCommentFilter: {
		"& > div": {
			fontSize: `${theme.typography.pxToRem(14)}`,
			opacity: "0.87",
			fontWeight: "normal",
			color: theme.palette.text.primary
		}
	},
	iconCommentExclamation: {
		margin: "0 12px 0 0"
	}
}));

export const ReplyContainer = styled(Box)`
	padding: 2px 55px 5px;

	${props => props.theme.breakpoints.down("sm")} {
		padding: 2px 0 5px 55px;
	}
`;

export const PostCommentContainer = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const PostCommentBox = styled(Box)`
	display: flex;
	width: 82%;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const TextField = styled(MuiTextField)<{ isEditing: boolean }>`
	textarea {
		${({ isEditing }) =>
			isEditing
				? css`
						width: calc(100% - 105px);
				  `
				: css`
						width: calc(100% - 57px);
				  `}
		margin: 0;
	}
`;

export const CommentSend = styled(Box)<{ isEditing: boolean }>`
	margin-left: -50px;
	z-index: 1;
	border-radius: 4px;
	color: #4a99f9;
	font-weight: bold;

	${({ isEditing }) =>
		isEditing
			? css`
					max-width: 110px;
			  `
			: css`
					max-width: 50px;
			  `}
	${props => props.theme.breakpoints.down("sm")} {
		right: 6%;
	}

	> span {
		cursor: pointer;
	}

	${({ disabled, theme }) =>
		disabled
			? css`
					color: ${theme.palette.colors.basic[400]};
					> span {
						cursor: default;
					}
			  `
			: ""}
`;

export const CommentName = styled(Typography)`
	display: flex;
	gap: 4px;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	letter-spacing: normal;
	color: ${props => props.theme.palette.text.primary};
	cursor: pointer;
`;

export const CommentText = styled(Typography)`
	margin-top: 2px;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: normal;
	line-height: 1.23;
	color: ${props => props.theme.palette.text.primary};
	width: 85%;
	word-break: break-word;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const TypeCommentBox = styled(Box)`
	width: 80%;
	${props => props.theme.breakpoints.down("sm")} {
		width: calc(100% - 56px);
	}
`;

export const CommentReply = styled.span`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	cursor: ${props => (props.showReplyPostComment ? "auto" : "pointer")};
	${({ disabled }) =>
		disabled
			? css`
					color: ${props => props.theme.palette.colors.basic[700]};
					cursor: default;
			  `
			: ""}
`;

export const CommentEdit = styled.span`
	opacity: 0.6;
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => props.theme.palette.text.primary};
	margin-left: 10px;
	cursor: pointer;
`;
export const CommentDelete = styled.span`
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: hsl(350, 100%, 63%); // todo: add into colors list
	margin-left: 10px;
	cursor: pointer;
`;

export const CommentTime = styled(Box)`
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const CommentThumbCount = styled.span`
	font-size: 0.9em;
	font-weight: 600;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const CommentShowMore = styled.span`
	font-weight: 500;
	color: #4a99f9;
	cursor: pointer;
`;

export const CommentContainer = styled(Box)`
	display: flex;
	justify-content: flex-start;
	gap: 12px;
	padding: 0;
	margin-bottom: 20px;
`;

export const DeletedCommentContainer = styled(CommentContainer)`
	margin: 20px 0 20px 50px;
`;

export const ViewReplies = styled(Box)`
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	padding: 0 0 16px 50px;
	color: ${props => props.theme.palette.text.primary};
	cursor: pointer;
`;

export const HelperText = styled.div`
	word-break: break-word;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 400;
	line-height: 1.85;
	color: ${props => props.theme.palette.text.primary};
`;

export const SortSelectWrapper = styled(Box)`
	width: 140px;
`;

export const CommentSortingBox = styled(Box)`
	display: flex;
	justify-content: flex-start;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 20px;

	${props => props.theme.breakpoints.down("sm")} {
		justify-content: space-between;
	}
`;

export const CommentInfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 16px;
	flex-wrap: wrap;

	${props => props.theme.breakpoints.down("sm")} {
		gap: 8px;
	}
`;

export const CommentTotal = styled(Box)`
	display: flex;
	opacity: 0.87;
	margin-top: 10px;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: normal;
	color: ${props => props.theme.palette.text.primary};

	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
`;

export const CommentViewMore = styled(Box)`
	font-size: 1em;
	font-weight: bold;
	color: hsl(213, 94%, 63%);
	padding: 24px 0 16px;

	> span {
		cursor: pointer;
	}
`;

export const NoComments = styled(Box)`
	display: flex;
	justify-content: center;
	opacity: 0.87;
	color: ${props => props.theme.palette.text.primary};
	margin-bottom: 1%;
`;

export const StyledChip = styled(Chip)`
	span {
		color: ${props => props.theme.palette.common.white};
	}
`;
