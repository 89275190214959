import React from "react";

import { Box, Container, Typography } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";

import { RemarLogoSvg } from "assets/icons";

import BaseLayout from "./BaseLayout";

const MaintenancePage = () => {
	const params = useSearchParams();
	const message = params.get("message");
	const redirectUrl = params.get("redirectUrl");
	const nextRoute = redirectUrl ? redirectUrl : "/";

	return (
		<BaseLayout>
			<Container maxWidth="md">
				<Box
					textAlign="center"
					minHeight="90vh"
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box gridGap={16} display="flex" flexDirection="column" alignItems="center">
						<RemarLogoSvg />
						<br />
						<Typography variant="h1">{message ?? "We're making V2 better, service updates in progress"}</Typography>
						<Typography variant="h6">Please try again later in 1 hour.</Typography>
						<br />
						<Button
							color="primary"
							variant="filled"
							onClick={() => {
								window.location.href = nextRoute;
							}}
						>
							Try Again
						</Button>
					</Box>
				</Box>
			</Container>
		</BaseLayout>
	);
};

export default MaintenancePage;
