import { Box, Grid } from "@material-ui/core";

import { ReactComponent as GiftIllustration } from "@remar/shared/dist/assets/images/content-illustrations-gift-blue.svg";
import styled, { css } from "styled-components";

import { ReactComponent as ByeIllustration } from "assets/images/content-illustrations-bye-blue.svg";
import { ReactComponent as SadIllustration } from "assets/images/content-illustrations-sad-blue.svg";
import { ReactComponent as StudentIllustration } from "assets/images/content-illustrations-student.svg";

const gridCommonStyles = css`
	padding: 20px 35px;
	padding-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export const CancellationWrapper = styled(Grid)`
	min-height: 600px;
	.title {
		font-family: var(--poppins);
		font-weight: 700;
		font-size: 26px;
		margin-top: 25px;
	}
	.description {
		font-family: var(--openSans);
		font-weight: 400;
		font-size: 15px;
		color: ${props => props.theme.palette.text.secondary};
	}
	.left-Grid {
		background: ${({ theme }) => theme.palette.background.default};

		${gridCommonStyles}

		p {
			text-align: center;
			margin: 10px 0;
		}
	}
	.right-Grid {
		background: ${({ theme }) => theme.palette.background.paper};
		${gridCommonStyles}
	}
`;

export const SadIllustrationImage = styled(SadIllustration)`
	width: 200px;
	height: 200px;
	.path_fill {
		fill: ${({ theme }) => theme.palette.primary.main};
	}
	.path_cloud {
		fill: ${({ theme }) => theme.palette.background.default};
	}
`;

export const ByeIllustrationImage = styled(ByeIllustration)`
	width: 200px;
	height: 200px;
	.path_fill {
		fill: ${({ theme }) => theme.palette.primary.main};
	}
`;
export const GiftIllustrationImage = styled(GiftIllustration)`
	width: 120px;
	height: 120px;
	margin-bottom: 20px;
	margin-top: 40px;
	.path_fill {
		fill: ${({ theme }) => theme.palette.primary.main};
	}
`;
export const StudentIllustrationImage = styled(StudentIllustration)`
	width: 200px;
	height: 200px;
	.path_fill {
		fill: ${({ theme }) => theme.palette.primary.main};
	}
`;

export const RecommendedBlock = styled(Box)`
	background: ${({ theme }) => theme.palette.background.default};
	position: relative;
	padding: 10px 20px;
	margin: 20px 0;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 2px;
		height: 100%;
		background: ${({ theme }) => theme.palette.info.contrastText};
	}
	p {
		font-family: var(--openSans);
	}
	.recommendedTitle {
		font-size: 15px;
		font-weight: 600;
		margin: 5px 0;
		margin-bottom: 10px;
	}
	.recommendedDescription {
		font-size: 13px;
		font-weight: 400;
		color: ${props => props.theme.palette.text.secondary};
	}
`;
