import { passwordMatch } from "@remar/shared/dist/schemas";

import * as Yup from "yup";

export const TrialEditProfileSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
		.nullable()
});

export const EditProfileSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.required("Required")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});

export const ChangePwdSchema = Yup.object().shape({
	currentPassword: passwordMatch().required("Required"),
	newPassword: passwordMatch()
		.required("Required")
		.test(
			"new-and-old-password-should-not-match",
			"New password shouldn’t match with the current password",
			function (value) {
				return this.parent.currentPassword !== value;
			}
		),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
		.required("Required")
});
