import React, { FC, memo, useEffect, useState } from "react";

import { Box, CircularProgress, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { MaintenanceModeTypes } from "@remar/shared/dist/constants";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { IAccessList, shouldRestrictAccess } from "@remar/shared/dist/utils/auth";

import { Route, RouteProps, useHistory } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { getFullState, getUserData, setUserLoading } from "store/features/Auth/authSlice";

import { getSubscriptionInfo, getUserInfo } from "store/features/MyAccount/myAccountSlice";

import { routes } from "core/constants";
import MaintenanceBanner from "modules/MaintenanceBanner";

import { GLOBAL_CONSTANTS } from "../../constants";

const Loading = () => (
	<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
		<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
	</Box>
);
const ValidateRouteRestrictions = ({ component: Component, restricted, ...rest }) => {
	const [isValidating, setIsValidating] = useState(true);
	const theme = useTheme<Theme>();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const path = history.location.pathname;
	const search = history.location.search;
	const course = routes.course.getPath();
	const tests = routes.tests.getPath();
	const signIn = routes.signIn.getPath();
	const signUp = routes.signUp.getPath();
	const instSignUp = `${routes["institution-open-signup"].getPath()}/:locationId`;
	const emailInvitation = routes.email_invitation.getPath();
	const {
		userInfo,
		subscriptionInfo,
		isLoading: isMyAccountLoading
	} = useAppSelector((store: RootState) => store.myAccount);
	const { isLoggedIn, canAccessCourse, canAccessQuiz, canAppendRedirectQueryParam } = useAppSelector(getFullState);

	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const params = useSearchParams();

	const isMaintenanceModeSevere = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.SEVERE;
	const isMaintenanceModeOptional = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.OPTIONAL;
	const isAuthLogin = params.get("authLogin") == "true";

	const disableOnMaintenanceMode = (!isAuthLogin && isMaintenanceModeOptional) || isMaintenanceModeSevere;

	const permissionsList: IAccessList[] = [
		{
			route: signIn,
			restrict: true,
			redirect: "/"
		},
		{
			route: signUp,
			restrict: true,
			redirect: "/"
		},
		{
			route: `${emailInvitation}/:invitationId`,
			restrict: true,
			redirect: "/"
		},
		{
			route: course,
			restrict: !canAccessCourse && canAccessQuiz,
			redirect: tests
		},
		{
			route: tests,
			restrict: !canAccessQuiz,
			redirect: course
		}
	];

	const redirectionList: IAccessList[] = [
		{
			route: routes.forgotPassword.getPath(),
			restrict: isLoggedIn,
			redirect: "/"
		},
		{
			route: routes.create_password.getPath(),
			restrict: isLoggedIn,
			redirect: "/"
		},
		{
			route: instSignUp,
			restrict: isLoggedIn,
			redirect: "/"
		},
		{
			route: `${routes.email_invitation.getPath()}/:invitationId`,
			restrict: isLoggedIn,
			redirect: "/"
		}
	];

	useEffect(() => {
		if (isLoggedIn && !userInfo && !isMyAccountLoading) {
			dispatch(getUserInfo());
		}
	}, [userInfo, isMyAccountLoading, isLoggedIn, dispatch]);

	useEffect(() => {
		if (isLoggedIn && !subscriptionInfo && !isMyAccountLoading) {
			dispatch(getSubscriptionInfo());
		}
	}, [isLoggedIn, subscriptionInfo, dispatch]);

	useEffect(() => {
		const validatingPermissions = new Promise<void>(resolve => {
			const isLoginOrSignUp = path.includes(signIn) || path.includes(signUp);
			const signupDisabled = disableOnMaintenanceMode && path.includes(signUp);

			if (restricted && !isLoggedIn && (!isLoginOrSignUp || signupDisabled)) {
				resolve();
				return history.push(signIn + (canAppendRedirectQueryParam ? `?redirect=${path + search}` : ""));
			}
			if (isLoggedIn) {
				const { restrict, redirect } = shouldRestrictAccess(path + search, permissionsList);
				if (restrict) {
					resolve();
					return history.replace(redirect);
				}
			}

			const { restrict, redirect } = shouldRestrictAccess(path + search, redirectionList);
			if (restrict) {
				resolve();
				return history.replace(redirect);
			}

			resolve();
		});
		validatingPermissions.finally(() => setIsValidating(false));
		return () => {
			setIsValidating(true);
		};
	}, [isLoggedIn, path]);

	if (isValidating) {
		return <Loading />;
	}

	return (
		<>
			{(isMaintenanceModeSevere || isMaintenanceModeOptional) && <MaintenanceBanner isMobile={isMobile} />}
			<Route {...rest} component={Component} />
		</>
	);
};

interface IAuthRoute extends RouteProps {
	restricted?: boolean;
}
const AuthRoute: FC<IAuthRoute> = ({ component: Component, restricted = true, ...rest }) => {
	const { isLoggedIn, user, userLoading } = useAppSelector((state: RootState) => state.auth);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isLoggedIn && !user) {
			dispatch(getUserData());
		}
		if (!isLoggedIn) {
			dispatch(setUserLoading(false));
		}
	}, [isLoggedIn, user]);

	if (userLoading) {
		return <Loading />;
	} else {
		return <ValidateRouteRestrictions component={Component} restricted={restricted} {...rest} />;
	}
};

export default memo(AuthRoute);
