import React, { useEffect, useMemo } from "react";

import { Box, TextField as TextInput, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { NoteAdd, Search } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Button from "@remar/shared/dist/components/Button";
import { TextField } from "@remar/shared/dist/components/TextField";
import { School } from "@remar/shared/dist/models";
import { format, startOfToday } from "date-fns";
import { Field, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "store";
import { selectIsTrial } from "store/features/Auth/authSlice";
import { createSchool, selectFullState } from "store/features/Course/courseSlice";
import * as Yup from "yup";

const StudentFormSchema = Yup.object<FormValues>().shape({
	school: Yup.string().min(3).required("Required"),
	timesTakenNCLEX: Yup.number().moreThan(-1, "Number of times exam taken should be positive"),
	examDate: Yup.date().min(startOfToday(), "Exam date can't be in the past")
});

type FormValues = {
	school: string;
	timesTakenNCLEX?: number;
	examDate?: Date;
};
const initialFormValues: FormValues = {
	school: "",
	timesTakenNCLEX: undefined,
	examDate: undefined
};

type PickSchoolProps = {
	classes: Record<string, string>;
	handleSchoolSubmit: (values: FormValues) => void;
	isLoading: boolean;
};
const PickSchool = ({ classes, handleSchoolSubmit, isLoading }: PickSchoolProps) => {
	const theme = useTheme<Theme>();
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const { schoolList: schools, isSchoolListLoading } = useAppSelector(selectFullState);
	const isTrial = useAppSelector(selectIsTrial);
	const approvedSchools = useMemo(() => schools?.filter(s => s.approved), [schools]);
	const [schoolList, setSchoolList] = React.useState<Array<School>>([]);

	useEffect(() => {
		setSchoolList(approvedSchools || []);
	}, [approvedSchools]);

	const filter = createFilterOptions<unknown>({
		trim: true
	});

	const accountFormDetails = (setFieldValue, values, errors, setFieldError) => {
		const autoCompleteOnChange = newValue => {
			if (typeof newValue === "string") {
				setFieldValue("school", newValue);
			} else if (newValue && newValue.inputValue) {
				// Create a new value from the user input
				const { inputValue } = newValue;
				if (!checkDuplicates(schoolList, { inputValue })) {
					dispatch(createSchool({ name: inputValue }));
				}
				setFieldValue("school", newValue.inputValue);
			} else {
				setFieldValue("school", newValue?.name);
			}
		};

		const checkDuplicates = (options, params) => {
			return options.some(option => params.inputValue.toLowerCase().trim() === option.name.toLowerCase().trim());
		};
		const autoCompleteFilterOptions = (options, params) => {
			const filtered = filter(options, params);
			// Suggest the creation of a new value
			if (params.inputValue !== "") {
				//Check if the option already exists
				if (!!checkDuplicates(options, params)) {
					return filtered;
				}

				filtered.push({
					inputValue: params.inputValue,
					name: `Add "${params.inputValue}"`
				});
			}
			return filtered;
		};

		const autoCompleteGetOptionLabel = option => {
			// Value selected with enter, right from the input
			if (typeof option === "string") {
				return option;
			}
			// Add "xxx" option created dynamically
			if (option.inputValue) {
				return option.inputValue;
			}
			// Regular option
			return option.name;
		};

		const autoCompleteRenderInput = params => (
			<TextInput
				{...params}
				placeholder="Enter school here"
				name="school"
				variant="filled"
				InputProps={{
					...params.InputProps,
					disableUnderline: true,
					style: { paddingBottom: "10px", paddingTop: "10px" },
					endAdornment: <Search style={{ fill: "#898f9e" }} />
				}}
				onBlur={() => {
					if (values.school.length < 1) setFieldError("school", "Required");
				}}
			/>
		);

		const handleKeyDown = e => {
			if (e.key === "Enter") {
				// Prevent the default form submission behavior
				e.preventDefault();
				// Submit the value (you can replace this with your submit logic)
				const inputValue = e.target.value;
				autoCompleteOnChange(inputValue);
				if (!checkDuplicates(schoolList, { inputValue })) {
					dispatch(createSchool({ name: inputValue }));
				}
			}
		};

		return (
			<Box padding={isMobile ? 2 : 5}>
				<Box className={classes.formHeader}>
					<Box display="flex" justifyContent="center">
						{/*todo: this icon should be changed to handshake icon after upgrading material icons  */}
						<NoteAdd style={{ width: "60px", height: "60px", fill: "#898f9e" }} />
					</Box>
					<Typography variant="h2" className={classes.title}>
						Welcome to ReMar V2!
					</Typography>
					<Typography variant="h3" className={classes.subTitle}>
						We would love if you answered questions below to provide you with better experience
					</Typography>
				</Box>
				<Box padding={isMobile ? 2 : 0}>
					<Box className={classes.inputContainer}>
						<Box className={classes.labelField}>
							<Typography style={{ fontWeight: 600 }}>School*</Typography>
						</Box>
						<Box style={{ flex: 4, display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
							<Box className={classes.inputField}>
								<Autocomplete
									value={values.school}
									onChange={(_, newValue) => autoCompleteOnChange(newValue)}
									filterOptions={(options, params) => autoCompleteFilterOptions(options, params)}
									disableClearable
									clearOnBlur
									onKeyDown={handleKeyDown}
									autoComplete
									handleHomeEndKeys
									id="school"
									options={schoolList}
									loading={isSchoolListLoading}
									getOptionLabel={option => autoCompleteGetOptionLabel(option)}
									renderOption={option => option.name}
									style={{ width: 300 }}
									freeSolo
									renderInput={params => autoCompleteRenderInput(params)}
								/>
							</Box>
							{errors && errors.school && <p className={classes.error}>Required</p>}
						</Box>
					</Box>
					<Box className={classes.inputContainer}>
						<Box className={classes.labelField}>
							<Typography style={{ fontWeight: 600, whiteSpace: "nowrap" }}>Times taken NCLEX</Typography>
						</Box>
						<Box className={classes.inputField}>
							<Field
								component={p => <TextField {...p} hiddenLabel />}
								name="timesTakenNCLEX"
								type="number"
								placeholder="Enter Times Here"
								fullWidth
							/>
						</Box>
					</Box>
					{isTrial && (
						<Box className={classes.inputContainer}>
							<Box className={classes.labelField}>
								<Typography style={{ fontWeight: 600, whiteSpace: "nowrap" }}>Date of your exam</Typography>
							</Box>
							<Box className={classes.inputField}>
								<Field
									component={p => <TextField {...p} hiddenLabel />}
									name="examDate"
									type="date"
									inputProps={{ min: format(new Date(), "yyyy-MM-dd") }}
									placeholder=""
									fullWidth
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		);
	};

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={StudentFormSchema}
			onSubmit={values => handleSchoolSubmit(values)}
		>
			{({ setFieldValue, isValid, values, errors, setFieldError }) => (
				<>
					<Form className={classes.form}>{accountFormDetails(setFieldValue, values, errors, setFieldError)}</Form>
					<Box className={classes.formFooter}>
						<Button
							color="primary"
							disabled={!isValid || isLoading || values?.school?.length < 1}
							loading={isLoading}
							variant="filled"
							fullWidth={isMobile}
							onClick={() => handleSchoolSubmit(values)}
						>
							Submit
						</Button>
					</Box>
				</>
			)}
		</Formik>
	);
};

export default PickSchool;
