/* eslint-disable react/display-name */
import React, { useEffect } from "react";

import { Box, CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";

import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { UserPaymentType } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getPaymentHistory } from "store/features/Billing/BillingSlice";

import {
	ColumnHeader,
	InvoiceDownloadIcon,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "./styles";

const tableColumns = [
	{
		alignment: "left",
		label: <ColumnHeader>Number</ColumnHeader>,
		Cell: ({ rowIndex }) => (
			<StyledCellWrapper>
				<StyledCellText>{rowIndex + 1}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "id"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Billing Date</ColumnHeader>,
		Cell: ({ rowData: { billingDate } }) => (
			<StyledCellWrapper>
				<StyledCellText>{formatDate(billingDate)}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "billing date"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Billing Items</ColumnHeader>,
		Cell: ({
			rowData: {
				type: { name }
			}
		}) => (
			<StyledCellWrapper>
				<StyledCellText>{name.toUpperCase()}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "course"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Amount</ColumnHeader>,
		Cell: ({ rowData: { amount, userPaymentTypeId } }) => (
			<StyledCellWrapper>
				<StyledCellText>
					{" "}
					{userPaymentTypeId == UserPaymentType.Refunded
						? `$(${amount})`
						: amount.toLocaleString("en-US", { currency: "USD", style: "currency" })}
				</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "amount"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Shipping Details</ColumnHeader>,
		Cell: ({ rowData: { shippingDetails } }) => (
			<StyledCellWrapper>
				<StyledCellText>{shippingDetails}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "shippingDetails"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Invoice</ColumnHeader>,
		Cell: ({ rowData: { invoiceUrl } }) => (
			<StyledCellWrapper>
				<InvoiceDownloadIcon>
					<DownloadIconSvg
						onClick={() => {
							downloadExternalFile(invoiceUrl);
						}}
					/>
				</InvoiceDownloadIcon>
			</StyledCellWrapper>
		),
		dataKey: "menu"
	}
];

const PastPayments = () => {
	const dispatch = useAppDispatch();
	const {
		paymentHistory: { isLoading, payments, totalItems, page, perPage }
	} = useAppSelector(getFullState);
	const theme = useTheme<IExtendedTheme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	useEffect(() => {
		dispatch(getPaymentHistory({}));
	}, [dispatch]);

	return (
		<>
			{isLoading ? (
				<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
					<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
				</Box>
			) : (
				<>
					<THeaderWrapper>
						<THeaderTitle>Past Payments</THeaderTitle>
					</THeaderWrapper>
					<MaterialTable columns={tableColumns} data={payments || []} height={700} showMobileView={isMobile} />
					{perPage < totalItems && (
						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) => dispatch(getPaymentHistory({ page }))}
							rowsPerPage={perPage}
						/>
					)}
				</>
			)}
		</>
	);
};

export default PastPayments;
