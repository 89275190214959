import { BaseModel } from "./base.model";

import { BaseListModel } from "./baseList.model";
import { Coupon } from "./coupon.model";

interface INotificationAudienceType extends BaseModel {
	label: string;
}
interface INotificationType extends BaseModel {
	label: string;
}

export enum NotificationDeliveryMethodEnum {
	Socket = "socket",
	Banner = "Banner",
	Push = "Push",
	Announcement = "Announcement"
}
export interface NotificationsModel extends BaseModel {
	body: string;
	mobileBody?: string;
	countDown: boolean;
	coupon: Coupon;
	couponId: number;
	data: NotificationDataDto;
	duration: NotificationDuration;
	isActive: boolean;
	isDismissible: boolean;
	notificationAudienceType: INotificationAudienceType;
	notificationAudienceTypeId: NotificationAudienceTypeIdEnum;
	notificationType: INotificationType;
	notificationTypeId: NotificationTypeId;
	sendNow: boolean;
	status: "active" | "deactivated";
	title: string;
	mobileTitle?: string;
}

export interface BannerNotification extends NotificationsModel {}

export enum NotificationAudienceTypeIdEnum {
	AllUsers = 1,
	PaidUsers = 2,
	TrialUsers = 3
}

export interface NotificationDuration {
	days?: number;
	hours?: number;
	minutes?: number;
}

export enum NotificationTypeId {
	AdminGeneratedPush = 1,
	AdminGeneratedBanner = 2,
	SystemNotification,
	AdminGeneratedAnnouncement = 4
}

export enum ButtonActionEnum {
	ApplyCoupon = "apply-coupon",
	Dismiss = "dismiss",
	OpenLink = "open-link"
}

interface NotificationButtonDataDto {
	text: string;
	action: ButtonActionEnum;
	url?: string;
}

interface NotificationDataDto {
	primaryButton?: NotificationButtonDataDto;
	secondaryButton?: NotificationButtonDataDto;
}

export interface ISocketNotificationItem extends BaseModel {
	notificationId: number;
	userId: number;
	readAt: string;
	expiresAt: string;
	isArchived: boolean;
	notification: Partial<NotificationsModel>;
	data?: Record<string, unknown>;
	action?: () => void;
}

export interface ISocketNotifications extends BaseListModel {
	items: ISocketNotificationItem[];
}
