import React, { CSSProperties } from "react";

import { Box, Typography, makeStyles, useTheme } from "@material-ui/core";

import IconDown from "../../assets/icons/down_metric.svg";
import IconUp from "../../assets/icons/up_metric.svg";
import { IExtendedTheme } from "../../theme/default";
import { randomColor } from "../../utils/randomColor";

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	questionBox: {
		display: "flex",
		justifyContent: "start",
		width: "100%",
		height: "98px",
		padding: "24px 38px 22px 24px",
		opacity: 0.8,
		borderRadius: "4px",
		position: "relative",
		background: props => `${props["bgColor"]}` || theme.palette.InfoCard.backgroundColor,

		[theme.breakpoints.down("sm")]: {
			maxWidth: "100%"
		}
	},
	title: {
		fontSize: "15px",
		fontFamily: "var(--openSans)",
		fontWeight: "normal",
		lineHeight: 1.33,
		letterSpacing: "normal",
		color: theme.palette.InfoCard.titleColor,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	text: {
		fontFamily: "var(--poppins)",
		fontSize: "26px",
		fontWeight: 700,
		lineHeight: 1.23,
		letterSpacing: "normal",
		color: theme.palette.InfoCard.textColor,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	rangeMetric: {
		position: "absolute",
		right: 10,
		bottom: 5,
		display: "flex",
		justifyContent: "center"
	},
	metricUp: {
		color: theme.palette.colors.success[400],
		marginRight: "5px"
	},
	metricDown: {
		color: theme.palette.colors.danger[400],
		marginRight: "5px"
	}
}));

export interface InfoBlockProps {
	text: string | number;
	title: string;
	customBgColor?: string;
	iconBgColor?: string;
	IconSvg?;
	containerCustomStyle?: CSSProperties;
	titleCustomStyle?: CSSProperties;
	textCustomStyle?: CSSProperties;
	textContainerCustomStyle?: CSSProperties;
	percentageChange?: number;
	streth?: boolean;
}

/**
 * Info card component
 * @param customBgColor - custom card background color (optional)
 * @param iconBgColor - fill color for left icon (optional)
 * @param IconSvg - left icon (optional)
 * @param title - top text of the card
 * @param text - description text
 * @param containerCustomStyle - custom style for card wrapper component (optional)
 * @param titleCustomStyle - custom style for title text (optional)
 * @param textCustomStyle - custom style for description text (optional)
 * @param textContainerCustomStyle - (optional)
 * @param streth - streth card to 100% width (optional)
 * @param percentageChange - show percentage change over time (optional)
 */
const InfoBlock = ({
	customBgColor = "",
	iconBgColor,
	IconSvg,
	title,
	text,
	containerCustomStyle,
	titleCustomStyle,
	textCustomStyle,
	textContainerCustomStyle,
	streth = false,
	percentageChange = 0
}: InfoBlockProps) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles({ bgColor: customBgColor ? customBgColor : randomColor() });

	return (
		<Box className={classes.questionBox} style={containerCustomStyle} maxWidth={streth ? "100%" : "260px"}>
			{IconSvg && (
				<Box mr={2}>
					<IconSvg fill={iconBgColor || theme.palette.InfoCard.iconColor} width={48} height={48} />
				</Box>
			)}
			<Box style={textContainerCustomStyle}>
				<Box className={classes.title} style={titleCustomStyle}>
					{title}
				</Box>
				<Box className={classes.text} style={textCustomStyle}>
					{text || "0"}
				</Box>
			</Box>
			{!!percentageChange && percentageChange !== 0 && (
				<Box className={classes.rangeMetric}>
					<Typography className={classes.metricDown}>{percentageChange}</Typography>
					{percentageChange > 0 ? <img src={IconUp} alt="up" /> : <img src={IconDown} alt="down" />}
				</Box>
			)}
		</Box>
	);
};

export default InfoBlock;
