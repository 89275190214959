import videojs from "video.js";

import backwardSkip from "../../assets/icons/icon-backward-skip.svg";

import forwardSkip from "../../assets/icons/icon-forward-skip.svg";
import { videoOptions } from "../../config";

import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";

const SKIP_INTERVAL = 10;
// export const delay = (ms: number): Promise<unknown> => {
// 	return new Promise(resolve => setTimeout(resolve, ms));
// };
//
// export const generateFakeId = (): string => {
// 	let text = "";
// 	const possible = "abcdefghijklmnopqrstuvwxyz0123456789";
//
// 	for (let i = 0; i < 5; i++) {
// 		//eslint-disable-line
// 		text += possible.charAt(Math.floor(Math.random() * possible.length));
// 	}
//
// 	return text;
// };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// export function* generateSequence(start, end) {
// 	for (let i = start; i <= end; i++) yield i;
// }
//
// export function randomInteger(min: number, max: number): number {
// 	return Math.floor(Math.random() * (max - min)) + min;
// }

// const toBase64 = (file: File): Promise<string> =>
// 	new Promise((resolve, reject) => {
// 		const reader = new FileReader();
// 		reader.readAsDataURL(file);
// 		reader.onload = () => resolve(reader.result as string);
// 		reader.onerror = error => reject(error);
// 	});
//
// async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
// 	const res: Response = await fetch(dataUrl);
// 	const blob: Blob = await res.blob();
// 	return new File([blob], fileName, { type: "image/png" });
// }

const downloadURI = (url: string, filename: string): void => {
	fetch(url)
		.then(response => response.blob())
		.then(blob => {
			const csvBlob = new Blob([blob], { type: "text/csv" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(csvBlob);
			link.download = filename;
			link.click();
		})
		.catch(console.error);
};

const downloadAndReadTextFromFile = (url: string): Promise<string | void> => {
	return fetch(url)
		.then(r => r.text())
		.then(text => text);
};

const downloadExternalFile = (url: string): void => {
	const link = document.createElement("a");
	link.target = "_blank";
	link.href = url;
	link.click();
};

export function getSelectOptions(
	arr: Record<string, unknown>[],
	val1: string,
	val2: string
): { value: unknown; text: unknown }[] {
	return arr?.map(item => ({ value: item[val1], text: item[val2] }));
}

export function getVideoPlayer(
	ref: string | HTMLVideoElement,
	url: string,
	additionalOptions?: Partial<typeof videojs.options>
): typeof videojs.players {
	const videoPlayer = videojs(ref, {
		...videoOptions,
		...(additionalOptions || {})
	});

	videoPlayer.src([
		{
			src: url,
			type: "application/x-mpegURL"
		}
	]);

	videoPlayer.qualityLevels();
	if (typeof videoPlayer.hlsQualitySelector === "function") {
		videoPlayer.hlsQualitySelector({
			displayCurrentQuality: true
		});
	}

	const backwardBtnCollection = document.getElementsByClassName("vjs-backward-button") as HTMLCollectionOf<HTMLElement>;
	if (backwardBtnCollection.length === 0) {
		videoPlayer.getChild("ControlBar")?.addChild(
			"button",
			{
				controlText: "backward",
				className: "vjs-backward-button",
				clickHandler: () => videoPlayer.currentTime(videoPlayer.currentTime() - SKIP_INTERVAL)
			},
			1
		);
		backwardBtnCollection[0].innerHTML = `<img src="${backwardSkip}">`;
		backwardBtnCollection[0].style.cursor = "pointer";
	}
	const forwardBtnCollection = document.getElementsByClassName("vjs-forward-button") as HTMLCollectionOf<HTMLElement>;
	if (forwardBtnCollection.length === 0) {
		videoPlayer.getChild("ControlBar")?.addChild(
			"button",
			{
				controlText: "forward",
				className: "vjs-forward-button",
				clickHandler: () => videoPlayer.currentTime(videoPlayer.currentTime() + SKIP_INTERVAL)
			},
			2
		);
		forwardBtnCollection[0].innerHTML = `<img src="${forwardSkip}">`;
		forwardBtnCollection[0].style.cursor = "pointer";
	}

	return videoPlayer;
}

export const getSanitizedHtmlText = (text?: string): string =>
	text?.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ") || "";

export { downloadURI, downloadExternalFile, downloadAndReadTextFromFile };
