import { createTheme } from "@material-ui/core/styles";

import { IExtendedTheme } from "./default";
import { cyanBlueOptions as darkCyanBlueOptions } from "./options/dark_cyanBlue";
import { defaultOptions as darkDefault } from "./options/dark_default";
import { greenOptions as darkGreenOptions } from "./options/dark_green";
import { orangeOptions as darkOrangeOptions } from "./options/dark_orange";
import { purpleOptions as darkPurpleOptions } from "./options/dark_purple";
import { redOptions as darkRedOptions } from "./options/dark_red";
import { yellowOptions as darkYellowOptions } from "./options/dark_yellow";
import { cyanBlueOptions as lightCyanBlueOptions } from "./options/light_cyanBlue";
import { defaultOptions as lightDefault } from "./options/light_default";
import { greenOptions as lightGreenOptions } from "./options/light_green";
import { orangeOptions as lightOrangeOptions } from "./options/light_orange";
import { purpleOptions as lightPurpleOptions } from "./options/light_purple";
import { redOptions as lightRedOptions } from "./options/light_red";
import { yellowOptions as lightYellowOptions } from "./options/light_yellow";

export const darkTheme: IExtendedTheme = createTheme(darkDefault) as IExtendedTheme;
export const dark_cyanBlueTheme: IExtendedTheme = createTheme(darkCyanBlueOptions) as IExtendedTheme;
export const dark_purpleTheme: IExtendedTheme = createTheme(darkPurpleOptions) as IExtendedTheme;
export const dark_greenTheme: IExtendedTheme = createTheme(darkGreenOptions) as IExtendedTheme;
export const dark_yellowTheme: IExtendedTheme = createTheme(darkYellowOptions) as IExtendedTheme;
export const dark_orangeTheme: IExtendedTheme = createTheme(darkOrangeOptions) as IExtendedTheme;
export const dark_redTheme: IExtendedTheme = createTheme(darkRedOptions) as IExtendedTheme;

export const lightTheme: IExtendedTheme = createTheme(lightDefault) as IExtendedTheme;
export const light_cyanBlueTheme: IExtendedTheme = createTheme(lightCyanBlueOptions) as IExtendedTheme;
export const light_purpleTheme: IExtendedTheme = createTheme(lightPurpleOptions) as IExtendedTheme;
export const light_greenTheme: IExtendedTheme = createTheme(lightGreenOptions) as IExtendedTheme;
export const light_yellowTheme: IExtendedTheme = createTheme(lightYellowOptions) as IExtendedTheme;
export const light_orangeTheme: IExtendedTheme = createTheme(lightOrangeOptions) as IExtendedTheme;
export const light_redTheme: IExtendedTheme = createTheme(lightRedOptions) as IExtendedTheme;
