import { useMemo } from "react";

import { io } from "socket.io-client";

import { wsServerUrl } from "./config";

import { getJwt } from "../api/jwt";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useWebSocket = (token?: string | null) => {
	return useMemo(() => {
		const socket = io(wsServerUrl || "", {
			reconnectionDelay: 60000,
			path: "/socket",
			query: { token: token || getJwt(false) || "" },
			autoConnect: false,
			transports: ["websocket"]
		});

		socket.on("connect", () => console.info("useWebSocket socket CONNECTED with ID:", socket.id));
		socket.on("disconnect", () => console.info(`useWebSocket socket with ID: ${socket.id} DISCONNECTED`));

		return socket;
	}, [token]);
};

export default useWebSocket;
