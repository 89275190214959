import { ThemeOptions } from "@material-ui/core/styles";

import * as colors from "../../colors";

import defaultColorPalette from "../palettes/dark_defaultPalette";

export const defaultOptions = {
	typography: {
		allVariants: {
			color: colors.basic[100],
			fontFamily: "var(--openSans)",
			fontSize: 15,
			lineHeight: 1.333
		},
		h1: {
			fontFamily: "var(--poppins)",
			fontSize: 36,
			fontWeight: 700
		},
		h2: {
			fontFamily: "var(--poppins)",
			fontSize: 32,
			fontWeight: 700,
			lineHeight: 1.25
		},
		h3: {
			fontFamily: "var(--poppins)",
			fontSize: 30,
			fontWeight: 700,
			lineHeight: 1.333
		},
		h4: {
			fontFamily: "var(--poppins)",
			fontSize: 26,
			fontWeight: 700,
			lineHeight: 1.231
		},
		h5: {
			fontFamily: "var(--poppins)",
			fontSize: 22,
			fontWeight: 700,
			lineHeight: 1.455
		},
		h6: {
			fontFamily: "var(--poppins)",
			fontSize: 18,
			fontWeight: 700,
			lineHeight: 1.333
		},
		p1: {
			fontFamily: "var(--openSans)",
			fontSize: 15,
			fontWeight: 400,
			lineHeight: 1.333
		},
		p2: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			fontWeight: 400,
			lineHeight: 1.231
		},
		subtitle1: {
			fontFamily: "var(--openSans)",
			fontSize: 15,
			fontWeight: 600,
			lineHeight: 1.6
		},
		subtitle2: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			fontWeight: 600,
			lineHeight: 1.846
		},
		caption1: {
			fontFamily: "var(--openSans)",
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 1.333
		},
		caption2: {
			fontFamily: "var(--openSans)",
			fontSize: 12,
			fontWeight: 600,
			lineHeight: 1.333
		},
		small1: {
			fontFamily: "var(--openSans)",
			fontSize: 10,
			fontWeight: 600,
			lineHeight: 1.333
		},
		small2: {
			fontFamily: "var(--openSans)",
			fontSize: 10,
			fontWeight: 600,
			lineHeight: 1.333
		},
		label: {
			fontFamily: "var(--openSans)",
			fontSize: 12,
			fontWeight: 700,
			lineHeight: 1.333,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "var(--poppins)",
			fontSize: 10,
			fontWeight: 700,
			lineHeight: 1.2
		},
		buttonUpperCased: {
			fontFamily: "var(--poppins)",
			fontSize: 10,
			fontWeight: 700,
			lineHeight: 1.2,
			textTransform: "uppercase"
		},
		highlight: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			fontWeight: 400,
			lineHeight: 1.538
		},
		highlight1: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			fontWeight: 400,
			lineHeight: 1.538
		},
		highlight2: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			fontWeight: 400,
			lineHeight: 1.846
		}
	},
	overrides: {
		MuiList: {
			root: {
				backgroundColor: colors.basic[750]
			}
		},
		MuiStepper: {
			root: {
				background: "transparent"
			}
		},
		MuiStepLabel: {
			root: {
				color: colors.basic[100],
				fontWeight: 600,
				"& .MuiStepLabel-label.MuiStepLabel-active": {
					fontWeight: 600,
					color: colors.basic[100]
				},
				"& .MuiStepLabel-label.MuiStepLabel-completed": {
					fontWeight: 600,
					color: colors.basic[100]
				}
			},
			completed: {
				color: colors.basic[100],
				opacity: 0.3,
				fontWeight: 600,
				fontSize: "15px"
			},
			active: {
				color: colors.basic[100],
				opacity: 0.9,
				fontWeight: 600,
				fontSize: "15px"
			},
			label: {
				color: colors.basic[100],
				opacity: 0.4,
				fontWeight: 600,
				fontSize: "15px"
			}
		},
		MuiSelect: {
			root: {
				backgroundColor: colors.basic[900],
				borderRadius: "4px"
			},
			filled: {
				color: colors.basic[100]
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.basic[800],
				borderRadius: "4px",
				"&:hover input:-webkit-autofill": {
					"-webkit-box-shadow": `0 0 0 5000px ${colors.basic[750]} inset`
				}
			},
			input: {
				"&:-webkit-autofill": {
					WebkitBoxShadow: `0 0 0 1000px ${colors.basic[750]} inset`,
					borderTopRightRadius: 0,
					borderTopLeftRadius: 0
				},
				color: colors.basic[100]
			}
		},
		MuiCard: {
			root: {
				background: colors.basic[900],
				padding: "30px",
				width: "100%",
				borderRadius: "4px"
			}
		},
		MuiButton: {
			root: {
				fontFamily: "var(--poppins)",
				textTransform: "none",
				padding: "10px 20px",
				fontSize: "14px"
			},
			text: {
				color: "hsl(0,0%,69%)" //todo: add into the colors set
			},
			containedPrimary: {
				fontWeight: 600
			},
			containedSecondary: {
				fontWeight: 600,
				"&:hover": {
					backgroundColor: colors.basic[900]
				}
			}
		},
		MuiCheckbox: {
			root: {
				color: colors.basic[600],
				"&$checked": {
					color: colors.primary[500]
				}
			}
		},
		MuiRadio: {
			root: {
				color: colors.basic[600],
				"&$checked": {
					color: colors.primary[500]
				}
			}
		},
		MuiOutlinedInput: {
			inputAdornedEnd: {
				color: colors.basic[1200]
			},
			notchedOutline: {
				borderColor: colors.basic[600]
			}
		},
		MuiTableCell: {
			root: {
				borderBottomColor: colors.basic[700]
			}
		}
	},
	palette: {
		...defaultColorPalette
	}
} as ThemeOptions;
