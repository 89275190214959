import React, { memo, useMemo } from "react";

import { AvatarProps as MuiAvatarProps } from "@material-ui/core";

import { Avatar as CustomAvatar } from "./styles";

type AvatarProps = MuiAvatarProps & {
	fullName?: string;
	profileImageUrl?: string;
};
/**
 * Avatar Component
 *
 * This component generates a user avatar based on provided properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.fullName - The full name of the user. If provided, the initial letters from the name will be used as the avatar's fallback text.
 * @param {string} props.profileImageUrl - The URL of the user's profile image. If not provided, a default image will be used.
 * @param {...Object} rest - Additional properties to pass to the CustomAvatar component.
 * @param {variant} rest.variant - The variant of the avatar. Can be "circle" or "rounded".
 * @param {onclick} rest.onClick  - The function to call when the avatar is clicked.
 * @returns {JSX.Element} A CustomAvatar component with the user's initials or profile image.
 *
@example
 * @example
 * // Basic usage with full name
 * <Avatar fullName="John Doe" />
 *
 * @example
 * // Usage with profile image URL
 * <Avatar profileImageUrl="https://example.com/johndoe.jpg" />
 *
 * @example
 * // Combined usage with full name and additional props
 * <Avatar fullName="Jane Smith" profileImageUrl="https://example.com/janesmith.jpg" style={{ borderRadius: '50%' }} />
 * @example
 * // Usage with additional MUI Avatar props
 * <Avatar fullName="Alice Johnson" profileImageUrl="https://example.com/alicejohnson.jpg" variant="rounded" onClick={() => doAction()} />
 */
const Avatar = ({ fullName, profileImageUrl, ...rest }: AvatarProps) => {
	const title = useMemo(
		() =>
			fullName
				?.split(" ")
				.filter(x => x)
				.map(i => i[0].toUpperCase())
				.join("") || "A",
		[fullName]
	);

	return (
		<CustomAvatar alt={fullName} width={32} height={32} src={profileImageUrl ?? ""} {...rest}>
			{title}
		</CustomAvatar>
	);
};

export default memo(Avatar);
