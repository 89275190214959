import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const RightContainer = styled.div`
	justify-content: ${({ isMobile }) => (isMobile ? "center" : "left")};
	display: flex;
	width: ${({ isMobile }) => (isMobile ? "auto" : "49%")};
`;

export const LeftContainer = styled.div`
	display: flex;
	justify-content: ${({ isMobile }) => (isMobile ? "center" : "right")};
	width: ${({ isMobile }) => (isMobile ? "auto" : "49%")};
`;

export const FooterContainer = styled(Box)`
	padding: 12px 0 12px 30px;
	width: 100%;
	position: relative;
	display: flex;
	${({ isMobile, theme, showSidebar }) =>
		isMobile
			? css`
					background: ${theme.palette.background.paper};
					height: 64px;
					padding: 12px;
					grid-gap: 6px;
					flex-direction: column-reverse;
					margin-top: ${showSidebar ? "auto" : 0};
			  `
			: showSidebar
			? css`
					margin-top: auto;
					background: ${theme.palette.background.paper};
			  `
			: css`
					background-color: hsl(220deg 15.79% 14.9% / 60%);
					position: absolute;
					bottom: 0;
			  `}

	border-top: ${({ showSidebar }) => (showSidebar ? "none" : "0.5px solid #393e4b")};

	.MuiInputBase-root {
		color: ${props => props.theme.palette.text.primary};
	}
`;

export const Text = styled.span<{ showSidebar: boolean }>`
	margin: 8px 8px 8px 20px;
	opacity: 0.87;
	font-size: 13px;
	line-height: 1.23;
	letter-spacing: normal;
	color: ${({ showSidebar, theme }) => (showSidebar ? theme.palette.text.primary : theme.palette.common.white)};
	cursor: ${({ noCursor }) => (noCursor ? "default" : "pointer")};
`;
