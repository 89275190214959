import React from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";

import { AddContainer, AddPackageIcon, BookWrapper } from "../styles";

const Book = ({ name, description, imageUrl, data, addBook }) => (
	<BookWrapper>
		<Card style={{ padding: "20px" }}>
			<Box>
				<img width={"100%"} height={"280px"} src={imageUrl} alt="" />
			</Box>
			<Box>
				<Typography className={"title"}>{name}</Typography>
				<Box height={80}>
					<Typography className={"description"}>{description}</Typography>
				</Box>
			</Box>
			<Box mt={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
				<Typography className={"price"}>{formatUSD(data.price || 0)}</Typography>
				<AddContainer onClick={addBook}>
					<AddPackageIcon width="15px" height="15px" />
				</AddContainer>
			</Box>
		</Card>
	</BookWrapper>
);

export default Book;
