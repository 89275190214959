import React, { LegacyRef } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
	Typography,
	makeStyles
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import {
	CommentSortingBox,
	CommentTotal,
	CommentViewMore,
	NoComments,
	SortSelectWrapper,
	TypeCommentBox,
	useStyles
} from "./style";

import { Comment, PostComment } from "./index";
import LockSvg from "../../assets/icons/icon-lock.svg";
import NoCommentsSvg from "../../assets/icons/icon-no-comments.svg";

import { DiscussionBoardProps } from "../../models";
import { Avatar } from "../Avatar";

const ContentLoader = () => (
	<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
		<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
	</Box>
);

const EmptyState = () => (
	<>
		<Box display="flex" justifyContent="center" mb={2} mt={2}>
			<img alt="no comments placeholder" src={NoCommentsSvg} />
		</Box>
		<NoComments>Be the first to share your thoughts or questions</NoComments>
	</>
);

const useAccordionStyles = makeStyles(() => ({
	opacity: {
		opacity: "1 !important"
	}
}));

type Props = Pick<DiscussionBoardProps, "comments" | "totalItems" | "order" | "more"> & {
	refs: { [k: string]: LegacyRef<HTMLDivElement> };
	fullName?: string;
	profileImageUrl?: string;
	lessonId: string;
	isExpanded: boolean;
	currentUserId: number;
	isAdminMode?: boolean;
	isLocked: boolean;
	isLoading: boolean;
	onDelete: (commentId: number) => void;
	pinComment: (commentId: number, isPinned: boolean) => void;
	onVoteDelete: (
		commentId: number,
		voteId: number | undefined,
		isLike: boolean | undefined,
		isTopLevel: boolean
	) => void;
	onVote: (commentId: number | undefined, isLike: boolean) => void;
	onMockDelete: (commentId: number | undefined, parentId: number | undefined, text: string) => void;
	onCreate: (text: string, lessonId: number, parentId?: number) => void;
	onEdit: (text: string, lessonId: number, parentId?: number, commentId?: number) => void;
	handleViewMore: () => void;
	handleChange: (e) => void;
	handleAccordion: (_, isExpanded) => void;
	isReadOnly?: boolean;
};
const DiscussionBoard = ({
	refs,
	fullName,
	profileImageUrl,
	lessonId,
	currentUserId,
	comments,
	isLoading,
	totalItems,
	more,
	onVote,
	onVoteDelete,
	onDelete,
	onMockDelete,
	onEdit,
	onCreate,
	order,
	handleViewMore,
	handleChange,
	handleAccordion,
	isExpanded,
	isLocked,
	isAdminMode,
	pinComment,
	isReadOnly = false
}: Props) => {
	const classes = useStyles();
	const accordionClasses = useAccordionStyles();

	const renderComments = () => (
		<Box padding="0 16px">
			<CommentSortingBox>
				<CommentTotal>
					{totalItems} comment{totalItems === 1 ? "" : "s"}
				</CommentTotal>
				<SortSelectWrapper>
					<FormControl hiddenLabel size="small" variant="filled">
						<Select
							value={order}
							onChange={handleChange}
							disableUnderline
							className={classes.selectCommentFilter}
							disabled={isReadOnly}
						>
							<MenuItem value="MostRelevant">Most Relevant</MenuItem>
							<MenuItem value="MostRecent">Most Recent</MenuItem>
						</Select>
					</FormControl>
				</SortSelectWrapper>
			</CommentSortingBox>
			{comments.length > 0 &&
				comments.map(comment => (
					<div ref={refs[comment.id]} key={comment.id}>
						<Comment
							user={comment.user}
							isAdminMode={isAdminMode}
							comment={comment}
							lessonId={+lessonId}
							isReply={true}
							loggedInUserId={currentUserId}
							onVote={onVote}
							pinComment={pinComment}
							onDelete={onDelete}
							onVoteDelete={onVoteDelete}
							onCreate={onCreate}
							onMockDelete={onMockDelete}
							onEdit={onEdit}
						/>
					</div>
				))}
			{more && (
				<CommentViewMore onClick={handleViewMore}>
					<span>View More Comments</span>
				</CommentViewMore>
			)}
		</Box>
	);

	return (
		<Accordion className={classes.discussionBoard} expanded={isExpanded} disabled={isLocked} onChange={handleAccordion}>
			<AccordionSummary
				expandIcon={
					isLocked ? (
						<img alt="lock image" src={LockSvg} />
					) : (
						<ChevronLeft style={{ width: "30px", height: "30px", fill: "#898f9e", transform: "rotate(-90deg)" }} />
					)
				}
				classes={{ disabled: accordionClasses.opacity }}
			>
				<Typography>Lesson Discussion</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.commentPost}>
				<Box display="flex" width="100%" alignItems="center" gridGap={12}>
					<Avatar variant="rounded" fullName={fullName} profileImageUrl={profileImageUrl} />
					<TypeCommentBox display="flex">
						<PostComment
							lessonId={+lessonId}
							onEdit={onEdit}
							onCreate={onCreate}
							onMockDelete={onMockDelete}
							isReadOnly={isReadOnly}
						/>
					</TypeCommentBox>
				</Box>
			</AccordionDetails>
			{comments.length === 0 && !isLoading ? <EmptyState /> : isLoading ? <ContentLoader /> : renderComments()}
		</Accordion>
	);
};

export default DiscussionBoard;
