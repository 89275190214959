interface IConvertedTime {
	remainingDays: number;
	remainingHours: number;
	remainingMinutes: number;
	remainingSeconds: number;
	remainingMilliSeconds: number;
}
const convertSecToRemainingTime = (seconds: number): IConvertedTime => {
	const remainingDays = Math.floor(seconds / (24 * 60 * 60));
	const remainingHoursInSeconds = seconds % (24 * 60 * 60);
	const remainingHours = Math.floor(remainingHoursInSeconds / (60 * 60));
	const remainderMinutesInSeconds = remainingHoursInSeconds % (60 * 60);
	const remainingMinutes = Math.floor(remainderMinutesInSeconds / 60);
	const remainingSeconds = remainderMinutesInSeconds % 60;
	const flooredSeconds = Math.floor(remainingSeconds);
	const milliSeconds = remainingSeconds - flooredSeconds;
	const remainingMilliSeconds = Math.round(milliSeconds * 1000);

	return { remainingDays, remainingHours, remainingMinutes, remainingSeconds: flooredSeconds, remainingMilliSeconds };
};

export const convertRemainingTimeToSec = (h = 0, m = 0, s = 0, ms = 0): number => {
	const hourToSec = h * 60 * 60;
	const minToSec = m * 60;
	const msToSec = ms / 1000;
	return hourToSec + minToSec + s + msToSec;
};

export default convertSecToRemainingTime;
