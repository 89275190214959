import { useMemo } from "react";

import { getJwt } from "../../api/jwt";
import useWebSocket from "../../ws/useWebSocket";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useNotificationSocketService = () => {
	const socket = useWebSocket(getJwt(false));

	return useMemo(
		() => ({
			subscribeNotifications: <T>(event: string, callback: (args: T) => void) => {
				socket.on(event, callback);
				socket.connect();
			},
			terminateSocketConnection: (args?: Array<string>) => {
				args?.forEach(arg => {
					socket.off(arg);
				});

				if (socket.connected) {
					socket.close();
				}
			}
		}),
		[socket]
	);
};

export default useNotificationSocketService;
