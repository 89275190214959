import React, { useEffect, useMemo } from "react";

import { BellNotificationsBlock } from "@remar/shared/dist/components/Notifications";
import { NotificationDeliveryMethodEnum } from "@remar/shared/dist/models";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getUserNotifications } from "store/features/notifications/notifications.slice";

const BellNotifications = () => {
	const dispatch = useAppDispatch();
	const { bellNotifications: bellNotificationsState } = useAppSelector(getFullState);

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.deliveryMethod": NotificationDeliveryMethodEnum.Push
				}
			})
		);
	}, [dispatch]);

	const bellNotifications = useMemo(() => bellNotificationsState?.items || [], [bellNotificationsState]);

	return <BellNotificationsBlock bellNotifications={bellNotifications} />;
};

export default BellNotifications;
