import { Box } from "@material-ui/core";
import styled from "styled-components";

export const PaginationWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 24px;
	background-color: ${({ theme, $bgColor }) => ($bgColor ? $bgColor : theme.palette.background.paper)};

	li {
		&:first-child,
		&:last-child {
			button {
				background-color: transparent;
			}
		}

		button {
			&:hover {
				background-color: hsl(222, 64%, 42%);
			}

			&:active {
				background-color: hsl(222, 64%, 42%);
			}

			color: ${({ theme }) => theme.palette.text.primary};
		}

		div {
			color: ${({ theme }) => theme.palette.text.primary};
		}
	}
`;
