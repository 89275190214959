import { Box, TextField, Typography, alpha } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	.breadcrum-container {
		margin-top: 6px;
		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}
		div:last-child {
			color: ${props => props.theme.palette.colors.basic[100]};
		}
	}
`;

// todo: remove duplication
export const THeaderWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	padding: ${({ padding }) => (padding ? padding : "20px")};
	border-radius: 4px;
	width: 100%;

	${({ theme }) => theme.breakpoints.down("sm")} {
		height: auto;
		flex-direction: column;
	}
`;

export const THeaderTitle = styled.p`
	font-size: 15px;
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.text.primary};

	${({ theme }) => theme.breakpoints.down("sm")} {
		text-align: center;
	}
`;

export const ColumnHeader = styled.p`
	font-size: 12px;
	font-weight: 500;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const StyledCellWrapper = styled<HTMLDivElement & { disabled?: boolean; vertical: boolean }>(Box)`
	display: flex;
	flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
	align-items: center;
	width: 100%;
	opacity: ${({ disabled }) => (disabled ? 0.38 : 1)};
	justify-content: ${({ center }) => (center ? "center" : "flex-start")};
	margin-left: 0;
	gap: 15px;

	.highlight {
		background-color: ${props => props.theme.palette.colors.primary[400]};
		padding: 3px;
		color: ${props => props.theme.palette.colors.basic[100]};
	}

	${({ opacity }) =>
		opacity &&
		css`
			opacity: ${opacity};
		`}

	& svg {
		height: 16px;
	}
`;

export const StyledCellText = styled(Typography)`
	word-break: break-all;
	padding: 0;
	align-self: center;
	overflow: hidden;
	white-space: ${({ $wrapText }) => ($wrapText ? "wrap" : "nowrap")};
	text-overflow: ellipsis;
	color: ${({ color, theme }) => color || theme.palette.text.primary};
	font-size: 13px;
	${({ margin }) => margin && `margin: ${margin};`};
	${({ $hover }) =>
		$hover &&
		`	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}`}
`;

export const StyledCellMultipleText = styled.div`
	word-break: break-all;
	padding: 0;
	align-self: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${props => props.theme.palette.text.primary};
	font-size: 13px;
`;

export const SearchBar = styled(TextField)`
	border-radius: 4px;
	width: ${({ width }) => (width ? width : "350px")};

	${({ theme }) => theme.breakpoints.down("sm")} {
		align-self: flex-end;
		//margin-block: 24px;
		width: ${({ width }) => (width ? width : "100%")};
	}
	${({ $customStyles }) => $customStyles && $customStyles};
`;

export const InvoiceDownloadIcon = styled.div`
	> svg {
		height: 100% !important;
		cursor: pointer;
	}
`;

export const StyledNameChip = styled(Typography)`
	color: ${({ $color, theme }) => $color || theme.palette.colors.primary[1900]};
	font-weight: 400;
	font-family: var(--openSans);
	border: 1px solid ${({ $color, theme }) => $color || theme.palette.colors.primary[1900]};
	border-radius: 20px;
	padding: 3px 10px;
	font-size: 12px;
	text-align: center;
`;
